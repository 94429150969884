<template>
  <footer class="main-footer p-2 text-right">
    <slot>
    </slot>
  </footer>
</template>
<script>
  export default {
    components: {
    },
    data() {
      return {
      }
    },
    created() {
    },
    mounted() {
      document.getElementsByTagName("body")[0].classList.add("layout-footer-fixed")
    },
    destroyed() {
      document.getElementsByTagName("body")[0].classList.remove("layout-footer-fixed")
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
  }
</script>
<style></style>
