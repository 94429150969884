import * as types from '../mutation-types'

const state = {
  main: []
  //main: {
  //  name: 'Alexander Pierce',
  //  position: 'Web Developer',
  //  state: {
  //    color: '#3c763d',
  //    name: 'Online'
  //  },
  //  createdAt: new Date()
  //}
}

const mutations = {
  [types.FETCH_USER](state, user) {
    state.main = user
  }
}

export default {
  state,
  mutations
}
