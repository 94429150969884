<template>
  <div class="Calendar">
    <CollapedBox collapseIcon expandCard>
      <template slot="header">
        日历
      </template>
      <div class="Calendar1">
        <div class="month">
          <ul>
            <!--点击会触发pickpre函数，重新刷新当前日期 @click(vue v-on:click缩写) -->
            <li class="arrow" @click="pickPre(currentYear,currentMonth)">❮</li>
            <li class="year-month" @click="pickYear(currentYear,currentMonth)">
              <span class="choose-year">{{ currentYear }}</span>
              <span class="choose-month">{{ currentMonth }}月</span>
            </li>
            <li class="arrow" @click="pickNext(currentYear,currentMonth)">❯</li>
          </ul>
        </div>
        <!-- 星期 -->
        <ul class="weekdays">
          <li>一</li>
          <li>二</li>
          <li>三</li>
          <li>四</li>
          <li>五</li>
          <li style="color: red">六</li>
          <li style="color: red">日</li>
        </ul>
        <!-- 日期 -->
        <ul class="days">
          <!-- 核心 v-for循环 每一次循环用<li>标签创建一天 -->
          <li v-for="dayobject in days" @click="clickDay(dayobject)" :class="{selected:isSelectedDay(selectedDays,dayobject)}">
            <!--本月-->
            <div>
              <!--如果不是本月  改变类名加灰色-->
              <span v-if="dayobject.day.getMonth()+1 != currentMonth" class="other-month">{{ dayobject.day.getDate() }}</span>

              <!--如果是本月  还需要判断是不是这一天-->
              <span v-else>
                <!--今天  同年同月同日-->
                <span v-if="isThisDay(dayobject.day,new Date())" class="active">{{ dayobject.day.getDate() }}</span>
                <span v-else>{{ dayobject.day.getDate() }}</span>
              </span>
            </div>
            <div>
              <slot :day="dayobject" name="daymsg"></slot>
            </div>
          </li>
        </ul>
      </div>
    </CollapedBox>
  </div>
</template>

<script>
  import CollapedBox from './CollapedBox'
  export default {
    name: 'Calendar',
    props: {
      selectedDays: {
        type: Array,
        required: true
      },
      onChangeSelected: Function,
      onCurrentChange: Function,
    },
    data: function () {
      return {
        boxid: null,
        currentDay: 1,
        currentMonth: 1,
        currentYear: 1970,
        currentWeek: 1,
        days: [],
      };
    },
    methods: {
      initData: function (cur) {
        var leftcount = 0; //存放剩余数量
        var date;
        if (cur) {
          date = new Date(cur);
        } else {
          var now = new Date();
          var d = new Date(this.formatDate(now.getFullYear(), now.getMonth(), 1));
          d.setDate(35);
          date = new Date(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        }
        this.currentDay = date.getDate();
        this.currentYear = date.getFullYear();
        this.currentMonth = date.getMonth() + 1;
        this.currentWeek = date.getDay(); // 1...6,0
        if (this.currentWeek == 0) {
          this.currentWeek = 7;
        }
        var str = this.formatDate(this.currentYear, this.currentMonth, this.currentDay);
        this.days.length = 0;
        // 今天是周日，放在第一行第7个位置，前面6个
        //初始化本周
        for (var i = this.currentWeek - 1; i >= 0; i--) {
          var d = new Date(str);
          d.setDate(d.getDate() - i);
          var dayobject = {}; //用一个对象包装Date对象  以便为以后预定功能添加属性
          dayobject.day = d;

          this.days.push(dayobject);//将日期放入data 中的days数组 供页面渲染使用
        }
        //其他周
        for (var i = 1; i <= 35 - this.currentWeek; i++) {
          var d = new Date(str);
          d.setDate(d.getDate() + i);
          var dayobject = {};
          dayobject.day = d;
          this.days.push(dayobject);
        }
      },
      pickPre: function (year, month) {
        // setDate(0); 上月最后一天
        // setDate(-1); 上月倒数第二天
        // setDate(dx) 参数dx为 上月最后一天的前后dx天
        var d = new Date(this.formatDate(year, month, 1));
        d.setDate(0);
        this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        if (this.onCurrentChange)
          this.onCurrentChange({
            currentDay: this.currentDay,
            currentMonth: this.currentMonth,
            currentYear: this.currentYear,
            currentWeek: this.currentWeek,
          })
      },
      pickNext: function (year, month) {
        var d = new Date(this.formatDate(year, month, 1));
        d.setDate(35);
        this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        if (this.onCurrentChange)
          this.onCurrentChange({
            currentDay: this.currentDay,
            currentMonth: this.currentMonth,
            currentYear: this.currentYear,
            currentWeek: this.currentWeek,
          })
      },
      pickYear: function (year, month) {
        //alert(year + "," + month);
      },
      // 返回 类似 2016-01-02 格式的字符串
      formatDate: function (year, month, day) {
        var y = year;
        var m = month;
        if (m < 10) m = "0" + m;
        var d = day;
        if (d < 10) d = "0" + d;
        return y + "-" + m + "-" + d
      },
      clickDay(item) {
        var _this = this;
        if (this.onChangeSelected) {
          let list = [...this.selectedDays]
          var has = false
          for (var i in list) {
            var t = _this.isThisDay(list[i]['day'], item['day'])
            if (t) {
              list.splice(i, 1);
              has = true
              break;
            }
          }
          if (!has) {
            list.push(item);
          }
          this.onChangeSelected(list);
        }
      },
      isSelectedDay(slist, item) {
        var _this = this;
        var t = slist.filter(i => _this.isThisDay(i['day'], item['day'])).length > 0;
        if (t) {
          return true;
        } else {
          return false;
        }
      },
      isThisDay(day1, day2) {
        var zday1 = new Date(day1);
        var zday2 = new Date(day2);
        return zday1.getFullYear() == zday2.getFullYear() && zday1.getMonth() == zday2.getMonth() && zday1.getDate() == zday2.getDate();
      }
    },
    created() {
      this.boxid = Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
      this.initData(null);
    },
    watch: {

    },
    components: {
      CollapedBox
    }

  }
</script>

<style scoped>
  .Calendar * {
    box-sizing: border-box;
  }

  .Calendar ul {
    list-style-type: none;
  }

  .Calendar body {
    font-family: Verdana, sans-serif;
    background: #E8F0F3;
  }

  .Calendar #calendar {
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.1), 0 1px 5px 0 rgba(0,0,0,0.12);
  }

  .Calendar .month {
    width: 100%;
    background: #00B8EC;
  }

    .Calendar .month ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }

  .Calendar .year-month {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

    .Calendar .year-month:hover {
      background: rgba(150, 2, 12, 0.1);
    }

  .Calendar .choose-year {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Calendar .choose-month {
    text-align: center;
    font-size: 1.2rem;
  }

  .Calendar .arrow {
    padding: 20px;
    cursor: pointer;
  }

    .Calendar .arrow:hover {
      background: rgba(100, 2, 12, 0.1);
    }

  .Calendar .month ul li {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .Calendar .weekdays {
    margin: 0;
    padding: 10px 0;
    background-color: #00B8EC;
    display: flex;
    flex-wrap: wrap;
    color: #FFFFFF;
    justify-content: space-around;
  }

    .Calendar .weekdays li {
      display: inline-block;
      width: 13.6%;
      text-align: center;
    }

  .Calendar .days {
    padding: 0;
    background: #FFFFFF;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

    .Calendar .days li {
      list-style-type: none;
      display: inline-block;
      width: 14.2%;
      height: 50px;
      text-align: center;
      padding-bottom: 10px;
      padding-top: 10px;
      font-size: 0.8rem;
      color: #000;
      cursor: pointer;
    }

      .Calendar .days li .active {
        padding: 6px 10px;
        border-radius: 50%;
        background: #00B8EC;
        color: #fff;
      }

      .Calendar .days li .other-month {
        padding: 5px;
        color: gainsboro;
      }

      .Calendar .days li:hover {
        background: #e1e1e1;
      }

      .Calendar .days li.selected {
        background-color: #007bff;
      }

        .Calendar .days li.selected .active {
        }

        .Calendar .days li.selected .other-month {
        }

        .Calendar .days li.selected :hover {
        }
</style>
