import axios from 'axios'

export default {
    getUnread(request = {}) {
        return axios.get('/Api/My/Notices/Unread', request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    getUnreadCount(request = {}) {
        return axios.get('/Api/My/Notices/UnreadCount', request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    }
}
