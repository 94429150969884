var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"UploadSingleBox",on:{"dragenter":_vm.onDrag,"dragover":_vm.onDrag,"drop":_vm.onDrop}},[_c('div',{class:{'hidden':_vm.fileList.length>0}},[_c('Upload',{ref:"Upload",attrs:{"file-list":_vm.fileList,"action":_vm.action,"param":_vm.param,"multiple":false,"autoUpload":true,"maxSize":_vm.maxSize,"maxWidth":_vm.maxWidth,"maxHeight":_vm.maxHeight,"compress":_vm.compress,"isPano":_vm.isPano,"allowExtensions":_vm.allowExtensions,"partSize":_vm.partSizeMB*1024*1024,"surlheight":_vm.surlheight,"surlwidth":_vm.surlwidth,"maxUpper":_vm.maxUpper,"inputAccetp":_vm.inputAccetp,"on-change":_vm.onChange,"on-before":_vm.onBefore,"on-progress":_vm.uploadProgress,"on-success":_vm.uploadFinish,"on-failed":_vm.uploadFailed,"on-finished":_vm.onFinished,"onCheckingCount":_vm.onCheckingCount}})],1),_c('div',{staticClass:"data-show"},[_vm._l((_vm.fileList),function(ref,index){
var surl = ref.surl;
var percent = ref.percent;
var name = ref.name;
var size = ref.size;
var status = ref.status;
var title = ref.title;
var id = ref.id;
var resPano = ref.resPano;
return _c('div',{staticClass:"card card-light"},[_c('div',{staticClass:"card-header"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim:value",value:(_vm.fileList[index].title),expression:"fileList[index].title",modifiers:{"trim:value":true}}],staticClass:"card-header-input",attrs:{"placeholder":name,"type":"text"},domProps:{"value":(_vm.fileList[index].title)},on:{"blur":function($event){return _vm.sendtitle(_vm.fileList[index])},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fileList[index], "title", $event.target.value)}}}),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('i',{staticClass:"fas fa-times"})])])]),_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"info-box"},[(_vm.previewIcon)?_c('span',{staticClass:"info-box-icon bg-gray"},[_c('i',{class:_vm.previewIcon,attrs:{"aria-hidden":"true"}})]):_c('span',{staticClass:"info-box-icon",style:(_vm.previewImg(surl))}),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v(_vm._s(_vm.statusToString(status))+" ")]),_c('span',{staticClass:"info-box-number"},[_vm._v(_vm._s(_vm.formatBytes(size,0))),(status=='updateing')?_c('span',[_vm._v(","+_vm._s(percent?percent:0)+"%")]):_vm._e()])])]),_c('div',{staticClass:"progress progress-xxs"},[_c('div',{staticClass:"progress-bar progress-bar-striped",class:{ 'bg-danger':status=='Failed'},style:({width:percent+'%'}),attrs:{"role":"progressbar","aria-valuenow":"60","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(percent?percent:0)+"% Complete")])])])])])}),(_vm.checkingFileCount>0)?_c('div',{staticClass:"card card-light"},[_vm._m(0),_vm._m(1)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_vm._v(" 正在读取文件信息 ")]),_c('div',{staticClass:"card-tools"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"info-box"},[_c('span',{staticClass:"info-box-icon bg-gray"},[_c('i',{staticClass:"fas fa-sync fa-spin"})]),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("加载中…")]),_c('span',{staticClass:"info-box-number"})])])])}]

export { render, staticRenderFns }