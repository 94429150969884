<template>
  <div>
    <div class="mapInfoWin">
        <a :href="`/T/${data.key}`" target="_blank">
          <img style="width:200px;height:100px;" :src="data.firstImage.url.replace('/0/0/0/0/', '/200/0/0/0/')">
        </a>
        <div class="mapuserinfo">
          <a class="text-truncate" :href="`/T/${data.key}`" target="_blank">{{data.title}}</a>
          <div>作者：<a :href="`/U/${data.user.userName}`" target="_blank">{{data.user.nickName}}</a></div>
        </div>
    </div>
    <div class="text-center">
      <button @click="$emit('导航',data)">导航到这里</button>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'MapPopupInfoTour',
    data: {
      data: {},
      marker: null,
    },
    created() {
    },
    methods: {
    },
    computed: {
    }
  }
</script>

<style lang="css" scoped>
  .mapInfoWin {
    width: 200px;
    position: relative;
  }

  .mapuserinfo {
    position: absolute;
    top: 4px;
    left: 2px;
    right: 2px;
    text-shadow: 0 0 5px #000, 0 0 4px #000, 0 0 3px #000, 0 0 2px #000;
    color: #fff;
  }

  a {
    color: #fff
  }
</style>
