import moment from 'moment'

export default function formatTime(time) {
  var mTime = moment(time)
  if (mTime.toDate() < moment().subtract(6, 'days').toDate()) {
    return mTime.format("YYYY-MM-DD HH:mm:ss")
  }
  else if (mTime.toDate() < moment().subtract(1, 'days').toDate()) {
    return mTime.format("dddd HH:mm:ss")
  }
  else if (mTime.toDate() < moment().subtract(4, 'hours').toDate()) {
    return mTime.format("HH:mm:ss")
  }
  else {
    return mTime.fromNow()
  }
}
