<template>
  <span class="ObjTree">
    {{typeof object}}
    <template v-if="typeof object=='array'||typeof object=='object'">
      <a href="javascript:" @click="open=!open">[{{open?'-':'+'}}]</a>
      <ul v-if="open">
        <li v-for="(i,index) in object">
          {{index}}
          <ObjTree :object="i" v-if="i"></ObjTree>
          <span v-else>null</span>
        </li>
      </ul>
    </template>
    <span v-else>
      {{object}}
    </span>
  </span>

</template>

<script>
  export default {
    name: 'ObjTree',
    props: ['object'],
    data: function () {
      return {
        open: true,
      };
    },
    methods: {

    },
    created() {
    },
    watch: {
    }

  }
</script>

<style>
</style>
