import * as types from '../mutation-types'

const state = {
    count: 0,
    main: []
}

const mutations = {
    [types.FETCH_NOTICE](state, notices) {
        state.main = notices
    },
    [types.FETCH_NOTICE_COUNT](state, count) {
        state.count = count
    }
}

export default {
  state,
  mutations
}
