import Vue from 'vue'
export default function deepSet(target, name, obj) {
  var oClass = getClass(obj);

  if (oClass == "Object") Vue.set(target, name, {}); //判断传入的如果是对象，继续遍历
  else if (oClass == "Array") Vue.set(target, name, []); //判断传入的如果是数组，继续遍历
  else {
    Vue.set(target, name, obj)
    return
  }; //如果是基本数据类型就直接返回

  for (var i in obj) {
    var copy = obj[i];

    if (getClass(copy) == "Object") deepSet(target[name], i, copy); //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
    else if (getClass(copy) == "Array") deepSet(target[name], i, copy); //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
    else Vue.set(target[name], i, copy); //基本数据类型则赋值给属性
  }
  return;
}
function getClass(o) { //判断数据类型
  return Object.prototype.toString.call(o).slice(8, -1);
}
