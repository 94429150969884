<template>
  <Modal ref="Edit">
    <template slot="header">
      <h4 class="modal-title">{{ item.guid!=null ? '修改图标':  '新建图标' }}</h4>
    </template>
    <div v-if="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <template v-else>
      <form class="form-horizontal">
        <div class="card-body p-0">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <label for="" class="input-group-text">标题</label>
              </div>
              <input type="text" class="form-control" placeholder="标题" v-model.trim="item.title">
            </div>
          </div>
          <div class="form-group">
            <label for="">上传图标</label>
            <div class="">
              <div v-if="item.image">
                <div class="card-tools float-right">
                  <button type="button" class="btn btn-box-tool" @click="item.image=null" title="删除"><i class="fas fa-trash"></i></button>
                </div>

                <template v-if="item.type=='Image'">
                  <a @click="show">
                    <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;" :style="{'background-image':'url(' + item.image.url + ')'}"></div>
                  </a>
                  <viewer :images="[item.image.url]"
                          @inited="inited"
                          class="viewer d-none"
                          ref="viewer">
                    <template scope="scope">
                      <img v-lazy="item.image.url" :alt="item.image.name">
                    </template>
                  </viewer>
                </template>
                <template v-if="item.type=='Video'">
                  <a @click="show">
                    <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;" :style="{'background-image':'url(' + item.image.url.replace('/0/0/0/0/', '/800/0/0/0/') + ')'}"></div>
                  </a>
                  <viewer :images="[item.image.url.replace('/0/0/0/0/', '/800/0/0/0/')]"
                          @inited="inited"
                          class="viewer d-none"
                          ref="viewer">
                    <template scope="scope">
                      <img v-lazy="item.image.url.replace('/0/0/0/0/', '/800/0/0/0/')" :alt="item.image.name">
                    </template>
                  </viewer>
                </template>
              </div>
              <div v-else>
                <!--<select v-model="fileType">
                  <option value="image">图片</option>
                  <option value="video">视频</option>
                </select>-->
                <div v-if="fileType=='image'">
                  <UploadSingleBox :action="uploadIconUrl||'/Api/My/HotspotIcons/Upload'"
                                   :multiple="true"
                                   :maxSize="1"
                                   :maxWidth="6000"
                                   :maxHeight="6000"
                                   :compress="true"
                                   :isPano="false"
                                   :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                                   :partSizeMB="10"
                                   :surlheight="100"
                                   :surlwidth="200"
                                   :maxUpper="3"
                                   :uploadFinished="imageUploadFinished">
                    <template v-slot:default="{file,index,deleteitem}">
                      <div class="card-tools mr-0">
                        {{file.name}}
                        <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
                      </div>
                      <a :href="file.surl" target="_blank">
                        <img :src="file.surl" />
                      </a>
                      <div class="progress">
                        <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
                      </div>
                    </template>
                  </UploadSingleBox>
                </div>
                <div v-if="fileType=='video'">
                  <UploadSingleBox :action="uploadIconUrl||'/Api/My/HotspotIcons/Upload'"
                                   :multiple="true"
                                   :maxSize="20"
                                   :allowExtensions="/(.mp4)$/i"
                                   :partSizeMB="10"
                                   :maxUpper="3"
                                   :uploadFinished="imageUploadFinished">
                    <template v-slot:default="{file,index,deleteitem}">
                      <div class="card-tools mr-0">
                        {{file.name}}
                        <button type="button" class="btn btn-box-tool" @click="deleteitem(index)"><i class="fa fa-times"></i></button>
                      </div>
                      <div class="progress">
                        <div class="progress-bar" :style="{width:file.percent+'%'}"></div>
                      </div>
                    </template>
                  </UploadSingleBox>
                </div>
              </div>
            </div>
          </div>
          <template v-if="item.type=='Image'">
            <div class="form-group">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="isAnimation" v-model="item.isAnimation">
                <label class="form-check-label" for="isAnimation">
                  是否是动画
                </label>
              </div>
            </div>
            <template v-if="item.isAnimation">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <label for="" class="input-group-text">图标宽度</label>
                  </div>
                  <input class="form-control" type="number" placeholder="图标宽度" v-model.trim="item.width" step="1" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" v-on:click="item.width=numberMath(item.width,-1)"><i class="fa fa-minus"></i></span>
                    <span class="input-group-text" v-on:click="item.width=numberMath(item.width,1)"><i class="fa fa-plus"></i></span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <label for="" class="input-group-text">图标高度</label>
                  </div>
                  <input class="form-control" type="number" placeholder="图标帧率" v-model.trim="item.height" step="1" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" v-on:click="item.height=numberMath(item.height,-1)"><i class="fa fa-minus"></i></span>
                    <span class="input-group-text" v-on:click="item.height=numberMath(item.height,1)"><i class="fa fa-plus"></i></span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <label for="" class="input-group-text">动画帧率</label>
                  </div>
                  <input class="form-control" type="number" placeholder="动画帧率" v-model.trim="item.fps" step="1" min="1" max="60" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" v-on:click="item.fps=numberMath(item.fps,-1,60,1)"><i class="fa fa-minus"></i></span>
                    <span class="input-group-text" v-on:click="item.fps=numberMath(item.fps,1,60,1)"><i class="fa fa-plus"></i></span>
                  </div>
                </div>
              </div>
            </template>
            <div class="form-group">
              <label>文本偏移量</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="" class="input-group-text">X轴</label>
                </div>
                <input class="form-control" type="number" placeholder="文本X轴偏移量" v-model.trim="item.textOffsetX" step="1" />
                <div class="input-group-prepend">
                  <span class="input-group-text" v-on:click="item.textOffsetX=numberMath(item.textOffsetX,-1)"><i class="fa fa-minus"></i></span>
                  <span class="input-group-text" v-on:click="item.textOffsetX=numberMath(item.textOffsetX,1)"><i class="fa fa-plus"></i></span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="" class="input-group-text">Y轴</label>
                </div>
                <input class="form-control" type="number" placeholder="文本Y轴偏移量" v-model.trim="item.textOffsetY" step="1" />
                <div class="input-group-prepend">
                  <span class="input-group-text" v-on:click="item.textOffsetY=numberMath(item.textOffsetY,-1)"><i class="fa fa-minus"></i></span>
                  <span class="input-group-text" v-on:click="item.textOffsetY=numberMath(item.textOffsetY,1)"><i class="fa fa-plus"></i></span>
                </div>
              </div>
              <span class="text-danger"></span>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class=" text-center" style="margin:15px">
                  <HotspotIconView :icon="item" bgcolor="#ccc" :icontextpreview="true"></HotspotIconView>
                </div>
                <div class="text-center">
                  <div style="display:inline-block;margin:0 10px;">
                    <label class="">图标顶点方向</label>
                    <div class="">
                      <!--<select class="form-control" placeholder="edge" v-model="item.edge">
                <option v-for="d in directions" :value="d">
                    {{d}}
                </option>
            </select>-->
                      <template v-for="(edge,index) in directions">
                        <label class="checklaber" :class="{active:item.edge==edge}">
                          <input class="mycheck" type="radio" name="edge" v-model="item.edge" :value="edge" />
                        </label>
                        <br v-if="(index+1)%3==0" />
                      </template>


                    </div>
                  </div>
                  <div style="display:inline-block;margin:0 10px;">
                    <div class="">
                      <label class="">文本顶点方向</label>
                      <div class="">
                        <!--<select class="form-control" placeholder="textEdge" v-model="item.textEdge">
                  <option v-for="d in directions" :value="d">
                      {{d}}
                  </option>
              </select>-->
                        <template v-for="(edge,index) in directions">
                          <label class="checklaber" :class="{active:item.textEdge==edge}">
                            <input class="mycheck" type="radio" name="edge" v-model="item.textEdge" :value="edge" />
                          </label>
                          <br v-if="(index+1)%3==0" />
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="item.type=='Video'">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="" class="input-group-text">抠像颜色</label>
                </div>
                <input class="form-control" type="color" placeholder="抠像颜色" v-model="item.chromakeyColor" @change="colorChange" @input="colorChange" />
                <div class="input-group-prepend">
                  <span class="input-group-text" @click="getColor()"><i class="fa fa-eye-dropper"></i></span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="" class="input-group-text">抠像阈值</label>
                </div>
                <input class="form-control" type="number" placeholder="抠像阈值" v-model.trim="item.threshold" @change="colorChange()" step="0.01" min="0" max="1" />
                <div class="input-group-prepend">
                  <span class="input-group-text" v-on:click="item.threshold=numberMath(item.threshold,-0.01,1,0,3);colorChange();"><i class="fa fa-minus"></i></span>
                  <span class="input-group-text" v-on:click="item.threshold=numberMath(item.threshold,0.01,1,0,3);colorChange();"><i class="fa fa-plus"></i></span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="" class="input-group-text">抠像平滑</label>
                </div>
                <input class="form-control" type="number" placeholder="抠像平滑" v-model.trim="item.smoothing" @change="colorChange()" step="0.01" min="0" max="1" />
                <div class="input-group-prepend">
                  <span class="input-group-text" v-on:click="item.smoothing=numberMath(item.smoothing,-0.01,1,0,3);colorChange();"><i class="fa fa-minus"></i></span>
                  <span class="input-group-text" v-on:click="item.smoothing=numberMath(item.smoothing,0.01,1,0,3);colorChange();"><i class="fa fa-plus"></i></span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="" class="input-group-text">循环播放</label>
                </div>
                <input class="form-control" type="checkbox" placeholder="循环播放" v-model.trim="item.loop" @change="setIcon(item)" />
                <!--<div class="input-group-prepend">
                  <span class="input-group-text" v-on:click="item.fps=numberMath(item.fps,-1,60,1)"><i class="fa fa-minus"></i></span>
                  <span class="input-group-text" v-on:click="item.fps=numberMath(item.fps,1,60,1)"><i class="fa fa-plus"></i></span>
                </div>-->
              </div>
            </div>
          </template>
        </div>
      </form>
      <ImageColorPicker v-if="getColorImg" :url="getColorImg" @change="colorPickerChange">

      </ImageColorPicker>
      <template v-else>
        <template v-if="item.type=='Video'">
          <button @click="setIcon(item)">更新预览</button>
          <div style="height:400px">
            <Krpano ref="Pano" height="100%" :krpanoSettings="krpanoSettings" :on-krpano-ready="onKrpanoReady" :sceneVars="sceneVars" :onLoadComplete="onXmlComplete" @setingPano="setingPano"></Krpano>
          </div>
        </template>
      </template>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="save()" :disabled="updateing">保存</button>
      </template>
    </template>
  </Modal>
</template>
<script>
  import UploadSingleBox from './UploadSingleBox'
  import HotspotIconView from './HotspotIconView'
  import Modal from './Modal'
  import Krpano from './Krpano'
  import ImageColorPicker from './ImageColorPicker'
  export default {
    components: {
      UploadSingleBox,
      HotspotIconView,
      Modal,
      Krpano,
      ImageColorPicker
    },
    props: {
      iconUrl: String,
      uploadIconUrl: String,
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data() {
      return {
        krpano: null,
        krpanoInterface: null,
        krpanoSettings: {
          vars: {
            'preview.type': 'grid(CUBE,64,64,512,0xCCCCCC,0xFFFFFF,0x999999);',
            'view.fov': 70
          }
        },
        sceneVars: {

        },
        getColorImg: null,
        fileType: 'image',
        item: this.newItem(),
        directions: [
          'lefttop', 'top', 'righttop', 'left', 'center', 'right', 'leftbottom', 'bottom', 'rightbottom'
        ],
        updateing: false,
        loading: false,
      }
    },
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
      setingPano() {

      },
      onXmlComplete() {
      },
      onKrpanoReady(krpano) {
        this.krpano = krpano
        //  this.krpano.set('events.onclick', `js(${this.$refs.Pano.getFuncName(this.onclick)}())`)
        //  this.krpano.set('events.onsingleclick', `js(${this.$refs.Pano.getFuncName(this.onsingleclick)}())`)
        //  this.krpano.set('events.ondoubleclick', `js(${this.$refs.Pano.getFuncName(this.ondoubleclick)}())`)
        //  this.krpano.set('events.onmousedown', `js(${this.$refs.Pano.getFuncName(this.onMouseDown)}())`)
        //  this.krpano.set('events.onviewchange', `js(${this.$refs.Pano.getFuncName(this.onviewchange)}())`)
        //  window.krpano = krpano
        //  this.setContextmenu()
        //  this.setWebvr()
        setTimeout(() => {
          console.log(123)
          this.setIcon(this.item)
        })
      },
      setIcon(item) {
        this.removeHotspot('hotspot_icon')
        this.setHotspot(this.newHotspot({ icon: item }))
      },
      krpano_makeScreenshot(width = 1000, height = 500) {
        return new Promise((resolve, reject) => {
          if (this.krpano) {
            //console.log(this.krpano.call(`set(Screenshot, krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg'))`))
            //console.log(this.krpano.call(`screenshotcanvas = krpano.webGL.makeScreenshot(0, 0, true, "canvas", 0, null, sizeinfo, makeScreenshotCache);`))
            //console.log(this.krpano.get('screenshotcanvas'))
            //var img = this.krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg')
            //console.log(img)
            if (this.krpanoInterface) {
              var img = this.krpanoInterface.webGL.makeScreenshot(width, height, true, "canvas")
              console.log(img)
              resolve(img.toDataURL())
            } else {
              this.krpano.call('addplugin(pluginInterface)')
              window.krpanopluginready = () => {
                this.krpanoInterface = this.krpano.get('plugin[pluginInterface].krpanointerface')
                console.log(this.krpanoInterface)
                //this.krpano.call('removeplugin(pluginInterface,true)')

                var img = this.krpanoInterface.webGL.makeScreenshot(width, height, true, "canvas")
                console.log(img)
                resolve(img.toDataURL())
              }
              this.krpano.set('plugin[pluginInterface].onloaded', `js(krpanopluginready());`)
              this.krpano.set('plugin[pluginInterface].url', '%SWFPATH%/plugins/pluginInterface.js')
            }
          } else {
            reject('noKrpano')
          }
        })
      },
      getColor() {
        this.krpano.set(`hotspot[hotspot_icon].chromakey`, ``)
        this.krpano_makeScreenshot().then((img) => {
          this.getColorImg = img
          this.colorChange()
        })
      },
      newHotspot(setting) {
        return {
          addTime: '',
          ath: 0,
          atv: 0,
          description: "",
          distorted: true,
          file: null,
          gpsLat: 0,
          gpsLng: 0,
          icon: {
            width: 0,
            height: 0,
            fps: 0,
            isAnimation: false,
            edge: 'top',
            imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            //enableText: true,
            textOffsetX: 0,
            textOffsetY: 0,
            textEdge: 'bottom'
          },
          linkFOV: 120,
          linkHLookAt: 0,
          linkItemGUID: null,
          linkType: 'Pano',
          linkVLookAt: 0,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          showTitle: true,
          sortID: 2,
          title: "",
          type: 'Image',
          url: null,
          zoom: true,
          ...setting
        }
      },
      setHotspot(item) {
        //if (!this.getHotspotByGuid(item.guid)) {
        //  this.hotspots.push(item)
        //}

        var eventName = this.$refs.Pano.getFuncName(this.hotspotEvent)
        if (item.icon) {
          var hotspotSettings
          if (item.icon.type == 'Video') {
            hotspotSettings = {
              name: `hotspot_icon`,
              ath: item.ath,
              atv: item.atv,
              url: '%VIEWER%/plugins/videoplayer.js',
              videourl: item.icon.imageUrl,
              loop: item.icon.loop,
              distorted: item.distorted,
              scale: item.scale,
              scalechildren: false,
              zoom: item.zoom,
              rotate: item.rotate,
              edge: item.icon.edge,
              //alpha: 0.9,
              alpha: item.alpha || 1,
              onclick: "togglepause();",
              //  onclick: `js(${eventName}('Click',${item.guid}))`,
              //  ondown: `js(${eventName}('Down',${item.guid}))`,
            }
            if (item.icon.chromakeyColor) {
              hotspotSettings.chromakey = `${item.icon.chromakeyColor.replace('#', '0x')}|${item.icon.threshold}|${item.icon.smoothing}`
            }
          } else {
            hotspotSettings = {
              name: `hotspot_icon`,
              ath: item.ath,
              atv: item.atv,
              url: item.icon.imageUrl,
              distorted: item.distorted,
              scale: item.scale,
              scalechildren: false,
              zoom: item.zoom,
              rotate: item.rotate,
              edge: item.icon.edge,
              //alpha: 0.9,
              alpha: item.alpha || 1,
              onloaded: item.icon.isAnimation ? `js( ${this.$refs.Pano.getFuncName(this.AnimationCropdelay, `AnimationCrop_${item.guid}`)}(${item.icon.width},${item.icon.height},${item.icon.fps},'icon'))` : `js(${this.$refs.Pano.getFuncName(this.delayShow, `delayShow_${item.guid}`)}('icon'))`,
              onclick: `js(${eventName}('Click',${item.guid}))`,
              ondown: `js(${eventName}('Down',${item.guid}))`,
            }
          }
          this.addHotspot(hotspotSettings)
          if (item.showTitle && item.title != null && item.title != '') {
            const layerSettings = {
              name: `hotspotTxt_icon}`,
              type: 'text',
              html: item.title,
              vcenter: 'true',
              padding: '0',
              wordwrap: 'false',
              mergedalpha: 'true',
              distorted: item.distorted,
              css: 'font-size:12px; color:#FFF;text-shadow:2px 2px 2px #000, -2px -2px 2px #000, 2px -2px 2px #000, -2px 2px 2px #000;',
              bg: 'false',
              parent: `hotspot[hotspot_${item.guid}]`,
              zoom: item.zoom,
              rotate: -item.rotate,
              align: this.calcEdge(item.icon.edge, 0),
              onclick: `js(${eventName}('Click',${item.guid}))`,
              ondown: `js(${eventName}('Down',${item.guid}))`,
            }
            let p = [item.icon.textOffsetX, item.icon.textOffsetY, (parseFloat(item.rotate)) * Math.PI / 180]
            p = this.changeAngle(p)
            if (item.distorted) {
              layerSettings.ox = p[0]
              layerSettings.oy = p[1]
            } else {
              layerSettings.ox = p[0] * item.scale
              layerSettings.oy = p[1] * item.scale
            }
            layerSettings.edge = this.calcEdge(item.icon.textEdge, parseFloat(item.rotate))
            this.addLayer(layerSettings)
          }

        } else {
          const hotspotSettings = {
            name: `hotspot_icon`,
            type: 'text',
            html: item.title,
            css: 'font-size:12px; color:#FFF;text-shadow:2px 2px 2px #000, -2px -2px 2px #000, 2px -2px 2px #000, -2px 2px 2px #000;',
            //bg: 'false',
            bgalpha: '0.7',
            bgcolor: '0x000000',
            bgroundedge: 10,
            padding: '4 20',
            ath: item.ath,
            atv: item.atv,
            distorted: item.distorted,
            scale: item.scale,
            zoom: item.zoom,
            rotate: item.rotate,
            edge: item.edge,
            //alpha: 0.9,
            onclick: `js(${eventName}('Click',${item.guid}))`,
            ondown: `js(${eventName}('Down',${item.guid}))`,
          }
          this.addHotspot(hotspotSettings)
        }
      },
      addHotspot(hotspotSetting, points) {
        console.log('addHotspot')
        let name = ''
        if (hotspotSetting.name) {
          name = hotspotSetting.name
          delete hotspotSetting.name
        } else {
          name = 'hotspot_' + Math.random()
        }
        this.krpano.call('addhotspot(' + name + ')')
        for (const i in hotspotSetting) {
          this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i])
        }
        for (const i in points) {
          for (var j in points[i]) {
            this.krpano.set(`hotspot[${name}].point[${i}].${j}`, points[i][j])
          }
        }
      },
      removeHotspot(name) {
        this.krpano.call('removehotspot(' + name + ',true)')
      },
      AnimationCropdelay(framewidth, frameheight, framerate, guid) {
        this.krpano.set(`hotspot[hotspot_icon].alpha`, 0)
        setTimeout(this.AnimationCrop, 500, framewidth, frameheight, framerate, guid)
      },
      delayShow(guid) {
        var alpha = this.krpano.get(`hotspot[hotspot_icon].alpha`)
        this.krpano.set(`hotspot[hotspot_icon].alpha`, 0)
        setTimeout((guid) => {
          this.krpano.call(`tween(hotspot[hotspot_icon].alpha, ${alpha},1.5)`)
        }, 500, guid)
      },
      colorPickerChange(color) {
        this.item.chromakeyColor = color
        this.colorChange()
        this.getColorImg = null
      },
      colorChange() {
        var item = this.item
        this.krpano.set(`hotspot[hotspot_icon].chromakey`, `${item.chromakeyColor.replace('#', '0x')}|${item.threshold}|${item.smoothing}`)
      },
      newItem() {
        return {
          id: null,
          image: null,
          title: '',
          edge: 'center',
          isAnimation: false,
          type: "Image",
          width: 0,
          height: 0,
          fps: 30,
          enableText: false,
          textEdge: 'center',
          textOffsetX: 0,
          textOffsetY: 0,
          chromakeyColor: null,
          threshold: 0.05,
          smoothing: 0,
          loop: true,
        }
      },
      imageUploadFinished(file) {
        this.item.image = file;
        if (file.isVideo) {
          this.item.type = "Video"
        } else {
          this.item.type = "Image"
        }
        this.item.width = file.width;
        this.item.height = file.height;
        if (((file.width / file.height) % 1 === 0 || (file.height / file.width) % 1 === 0) && file.width != file.height) {
          this.item.isAnimation = true
          if (file.width > file.height) {
            this.item.width = file.height;
            this.item.height = file.height;
          } else {
            this.item.width = file.width;
            this.item.height = file.width;
          }
        }
        if (this.item.title == '' || this.item.title == null) {
          this.item.title = file.name
        }
        this.item.imageUrl = file.url;
        //console.log('businessLicense', file)
      },
      numberMath(val, step, max, min, fix) {
        val = Number(val)
        step = Number(step)
        val += step
        if (!isNaN(Number(max)) && val > max) {
          val = max;
        }
        if (!isNaN(Number(min)) && val < min) {
          val = min;
        }
        if (fix) {
          val = Number(val.toFixed(fix))
        }
        return Number(val)
      },
      inited(viewer) {
        this.$viewer = viewer
      },
      show() {
        this.$viewer.show()
      },
      edit(id) {
        console.log(id)
        this.$refs.Edit.show()
        if (id) {
          this.loadHotspotIcon(id)
        } else {
          this.reset()
        }
      },
      reset() {
        this.item = this.newItem()
      },
      loadHotspotIcon(id) {
        this.loading = true
        this.$axios.get((this.iconUrl || '/Api/My/HotspotIcons') + '/' + id)
          .then((response) => {
            //console.log(response)
            this.item = response.data
            this.loading = false

          })
          .catch((error) => {
            console.log(error)
          })
      },
      save() {
        this.updateing = true;
        if (this.item && this.item.guid != null) {
          this.$axios.put((this.iconUrl || '/Api/My/HotspotIcons') + '/' + this.item.guid, this.item)
            .then((response) => {
              this.reset()
              this.$refs.Edit.hide()
              this.$emit('saved')
              this.updateing = false;
            })
            .catch((error) => {
              console.log(error)
              this.updateing = false;
            })
        } else {
          this.$axios.post(this.iconUrl || '/Api/My/HotspotIcons', this.item)
            .then((response) => {
              this.reset()
              this.$refs.Edit.hide()
              this.$emit('created')
              this.updateing = false;
            })
            .catch((error) => {
              console.log(error)
              this.updateing = false;
            })
        }
      },
    },
    computed: {
    },
    watch: {
    },
  }
</script>
<style></style>
