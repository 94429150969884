<template lang="html">
    <div class="form-group">
        <label class="col-sm-2 control-label">分类</label>
        <div class="col-sm-10" style="position:relative;">
            <input type="text" class="form-control" :placeholder="placeholder" :value="text" @focus="showDrop=true" @blur="hideDrop" @input="$emit('change',$event.target.value)" />
            <div v-show="showDrop" style="position:absolute;top:100%;left:0;right:0;z-index:1;background-color:#fff;border:1px solid #ced4da;border-radius:0.25rem;padding: 0.375rem 0;max-height:120px;overflow-y:auto;margin: 0 7.5px;" ref="Drop" tabindex="1" @click="showDrop=false">
                <div v-for="c in list" v-if="c.indexOf(text)>=0" class="DropDownItem text-truncate" @click="$emit('change',c)">
                    {{c}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'text',
            event: 'change'
        },
        props: {
            list: {
                type: Array
            },
            class: {},
            text: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                showDrop: false,
                input: this.text,
            }
        },
        methods: {
            hideDrop(e) {
                if (e.relatedTarget == this.$refs.Drop) {
                    return
                } else {
                    this.showDrop = false;
                }
            },
        },
    }
</script>
<style lang="css" scoped>
    .DropDownItem {
        padding: 0 0.6rem;
        cursor: pointer;
    }

        .DropDownItem:hover {
            background-color: #dee2e6
        }
</style>
