<template>
  <div class="PanoMap">
    <template v-if="mapType=='aMap'">
      <PanoAMap ref="aMap" :currentItem="currentItem" :mapCenter="mapCenter" :zoom="zoom" :autoZoom="autoZoom" :url="url" :params="params">
        <template v-slot:buttons>
          <slot name="buttons"></slot>
        </template>
      </PanoAMap>
    </template>
    <template v-else-if="mapType=='bingMap'">
      <PanoBingMap ref="bingMap" :currentItem="currentItem" :mapCenter="mapCenter" :zoom="zoom" :autoZoom="autoZoom" :url="url" :params="params">
        <template v-slot:buttons>
          <slot name="buttons"></slot>
        </template>
      </PanoBingMap>
    </template>
    <template v-else-if="mapType=='leaflet'">
      <PanoLeafletMap ref="leaflet" :currentItem="currentItem" :currentLoc="currentLoc" :mapCenter="mapCenter" :zoom="zoom" :autoZoom="autoZoom" :url="url" :params="params" :layer="layer" @MapReady="bindchangeEvent">
        <template v-slot:buttons>
          <slot name="buttons"></slot>
        </template>
      </PanoLeafletMap>
    </template>
    <!--<div class="changer">
      <transition name="fade">
        <ul v-if="showmenu" class="mapMenu" style="margin:0;background-color:#fff;list-style:none">
          <li v-for="i in mapList" @click="mapType=i.name;showmenu=false" class="mapType" :class="{active:mapType==i.name}" v-html="i.html" :title="i.title">{{i.title}}</li>
        </ul>
      </transition>
      <button v-on:click="showmenu = !showmenu" style="line-height: 2rem;height: 2.5rem;">
        切换地图
      </button>
    </div>-->
  </div>
</template>
<script>
  import PanoAMap from './PanoAMap'
  import PanoBingMap from './PanoBingMap'
  import PanoLeafletMap from './PanoLeafletMap'
  export default {
    scrollToTop: true,
    components: {
      PanoAMap,
      PanoBingMap,
      PanoLeafletMap
    },
    props: {
      currentItem: {},
      currentLoc: {},
      mapCenter: {},
      zoom: {},
      autoZoom: {},
      url: {},
      params: {},
      layer: {},
    },
    data: () => {
      return {
        mapType: 'leaflet',
        showmenu: false,
        mapList: [
          {
            name: 'aMap',
            title: '高德地图',
            html: `<img src="https://webapi.amap.com/theme/v1.3/logo@1x.png">`
          },
          {
            name: 'bingMap',
            title: '必应地图',
            html: `<div style="
      display: inline-block;
      padding-left: 5px;
      width: 42px;
      height: 17px;
      background-size: contain;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMTAiIGhlaWdodD0iODUiPjxwYXRoIGQ9Ik04NC4xIDY3LjJWMTYuN2gxNC40YzQuNCAwIDcuOCAxLjEgMTAuNCAzLjIgMi42IDIuMSAzLjggNC45IDMuOCA4LjMgMCAyLjktLjggNS40LTIuMyA3LjUtMS41IDIuMS0zLjcgMy42LTYuNCA0LjV2LjFjMy40LjQgNi4xIDEuNyA4LjIgMy45IDIgMi4yIDMuMSA1IDMuMSA4LjUgMCA0LjMtMS41IDcuOC00LjYgMTAuNS0zLjEgMi43LTcgNC0xMS43IDRIODQuMXpNOTAgMjIuMXYxNi4zaDYuMWMzLjIgMCA1LjgtLjggNy42LTIuMyAxLjktMS42IDIuOC0zLjggMi44LTYuNiAwLTQuOS0zLjItNy40LTkuNy03LjRIOTB6bTAgMjEuNnYxOC4yaDhjMy41IDAgNi4yLS44IDguMS0yLjUgMS45LTEuNiAyLjktMy45IDIuOS02LjggMC02LTQuMS04LjktMTIuMi04LjlIOTB6bTM5LjUtMjQuNGMwLTEtLjQtMS45LTEuMS0yLjYtLjctLjctMS42LTEuMS0yLjctMS4xcy0yIC40LTIuNyAxLjFjLS43LjctMS4xIDEuNi0xLjEgMi42IDAgMS4xLjQgMiAxLjEgMi43LjcuNyAxLjYgMSAyLjcgMSAxIDAgMS45LS40IDIuNy0xLjEuNy0uNiAxLjEtMS41IDEuMS0yLjZtLTEgMTIuMWgtNS43djM1LjhoNS43VjMxLjR6bTM5LjMgMTMuOWMwLTQuNy0xLTguMy0zLjEtMTAuOS0yLjEtMi42LTUuMS0zLjgtOS0zLjgtNS4yIDAtOS4xIDIuMy0xMS44IDYuOGgtLjF2LTUuOUgxMzh2MzUuOGg1LjdWNDYuOGMwLTMuMyAxLTYgMi45LTguMiAxLjktMi4xIDQuMy0zLjIgNy4xLTMuMiA1LjUgMCA4LjMgMy44IDguMyAxMS40djIwLjRoNS43VjQ1LjN6bTQwLjUgMTljMCAxMy4xLTYuMyAxOS43LTE4LjkgMTkuNy00LjQgMC04LjMtLjgtMTEuNi0yLjVsMS41LTVjMy41IDEuOCA2LjMgMi42IDEwIDIuNiA4LjggMCAxMy4zLTQuNyAxMy4zLTE0di00aC0uMWMtMi43IDQuNi02LjggNi45LTEyLjMgNi45LTIuOSAwLTUuNS0uNy03LjctMi4yLTIuMy0xLjQtNC0zLjUtNS4yLTYuMi0xLjItMi43LTEuOS01LjgtMS45LTkuMyAwLTYuMSAxLjUtMTEgNC41LTE0LjUgMy0zLjYgNy01LjQgMTEuOS01LjQgNC44IDAgOC40IDEuOSAxMC43IDUuOGguMXYtNC45aDUuN3YzM3pNMjAyLjUgNTF2LTUuM2MwLTIuOS0xLTUuMy0zLTcuM3MtNC4zLTMtNy4xLTNjLTMuNiAwLTYuMyAxLjMtOC4zIDMuOS0yIDIuNi0zIDYuMi0zIDEwLjggMCAzLjkgMSA3LjEgMi45IDkuNSAxLjkgMi40IDQuNSAzLjYgNy43IDMuNnM1LjgtMS4xIDcuOC0zLjQgMy01LjIgMy04LjhNNDAuNSA1MS4zTDE2LjggNjQuOVY1LjhMLS4xLS4xdjc0LjdMMTYuOCA4NGw0Mi4xLTI0LjJWNDAuN0wyMS41IDI3LjZsNy4zIDE4LjJ6Ii8+PC9zdmc+);"></div>`
          },
          {
            name: 'leaflet',
            title: '谷歌地图',
            html: `<img style="max-height: 20px;" src="https://www.google.cn/images/branding/mapslogo/1x/googlelogo_62x24_with_2_stroke_color_66x26dp.png" class="watermark">`
          },
        ]
      }
    },
    computed: {

    },
    created() {
    },
    mounted() {
    },
    methods: {
      mapBackToPano() {
        return this.$refs[this.mapType].mapBackToPano()
      },
      getMapInfo() {
        return this.$refs[this.mapType].getMapInfo()
      },
      setMapInfo(info) {
        return this.$refs[this.mapType].setMapInfo(info)
      },
      bindchangeEvent() {
        switch (this.mapType) {
          case 'leaflet':
            this.$refs[this.mapType].addMapEvent('moveend', this.onMapViewChange)
            this.$refs[this.mapType].addMapEvent('zoomend', this.onMapViewChange)
            this.$refs[this.mapType].addMapEvent('baselayerchange', this.onMapViewChange)
            break
          default:
        }
      },
      onMapViewChange(e) {
        this.$emit('MapViewChange', e)
      },
    }
  }
</script>
<style lang="css" scoped>

  .PanoMap {
    width: 100%;
    height: 100%;
    position: relative;
  }

    .PanoMap .changer {
      left: 5px;
      bottom: 35px;
      position: absolute;
      z-index: 600;
    }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .mapType {
    padding: 3px;
    cursor: pointer;
    font-size: 0;
  }

    .mapType.active {
      background-color: #99bdef;
    }
</style>
