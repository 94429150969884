<template>
  <div class="pell" @paste="onPaste" />
</template>

<script>
  import { exec, init } from 'pell'
  import xss from 'xss'

  export default {
    name: 'PellEditor',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        //type: String,
        required: true
      },
      buttons: {
        type: Array,
        default: function () {
          //return [
          //  'bold',
          //  'italic',
          //  'underline',
          //  'strikethrough',
          //  'highlight',
          //  'heading1',
          //  'heading2',
          //  'paragraph',
          //  'quote',
          //  'olist',
          //  'ulist',
          //  'code',
          //  'line',
          //  'image',
          //  'link',
          //  'undo',
          //  'redo',
          //  'sub',
          //  'sup',
          //  'removeFormat',
          //  'clear',
          //]
          return ['bold', 'italic', 'underline', 'strikethrough', 'sub', 'sup', 'olist', 'ulist', 'removeFormat', 'clear',]
        }
      },
      options: {
        type: Object,
        default: () => {
          return {
            whiteList: {
              p: [],
              b: [],
              strong: [],
              i: [],
              u: [],
              strike: [],
              br: [],
              //h1: [],
              //h2: [],
              //blockquote: [],
              //hr: [],
              ol: [],
              ul: [],
              li: [],
              //code: [],
              //a: ['href'],
              //img: ['src'],
              sub: [],
              sup: [],
            },
            stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
            stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
          }
        }
      },
      defaultParagraphSeparator: {
        type: String,
        default: 'p'
      },
    },
    data() {
      return {
        editor: null,
        focus: false,
        exec: exec,
        actions: {
          bold: {
            icon: '<i class="fas fa-bold"></i>',
            name: 'bold',
            title: '加粗',
          },
          italic: {
            icon: '<i class="fas fa-italic"></i>',
            name: 'italic',
            title: '斜体',
          },
          underline: {
            icon: '<i class="fas fa-underline"></i>',
            name: 'underline',
            title: '下划线',
          },
          strikethrough: {
            icon: '<i class="fas fa-strikethrough"></i>',
            name: 'strikethrough',
            title: '删除线',
          },
          highlight: {
            name: 'backColor',
            icon: '<div style="background-color:yellow;">A</div>',
            title: '高亮',
            result: () => exec('backColor', 'yellow')
          },
          heading1: {
            name: 'heading1',
            title: '标题1',
          },
          heading2: {
            name: 'heading2',
            title: '标题2',
          },
          paragraph: {
            icon: '<i class="fas fa-paragraph"></i>',
            name: 'paragraph',
            title: '段落',
          },
          quote: {
            icon: '<i class="fas fa-quote-right"></i>',
            name: 'quote',
            title: '引用',
          },
          olist: {
            icon: '<i class="fas fa-list-ol"></i>',
            name: 'olist',
            title: '有序列表',
          },
          ulist: {
            icon: '<i class="fas fa-list-ul"></i>',
            name: 'ulist',
            title: '无序列表',
          },
          code: {
            name: 'code',
            title: '代码段',
          },
          line: {
            name: 'line',
            title: '分割线',
          },
          image: {
            name: 'image',
            title: '图片',
            result: () => {
              const url = window.prompt('Enter the image URL')
              if (url) exec('insertImage', this.ensureHTTP(url))
            }
          },
          link: {
            name: 'link',
            title: '链接',
            result: () => {
              const url = window.prompt('Enter the link URL')
              if (url) exec('createLink', this.ensureHTTP(url))
            }
          },
          undo: {
            icon: '<i class="fas fa-undo-alt"></i>',
            title: '撤销',
            result: function result() {
              return exec('undo');
            }
          },
          redo: {
            icon: '<i class="fas fa-redo-alt"></i>',
            title: '重做',
            result: function result() {
              return exec('redo');
            }
          },
          sub: {
            icon: '<i class="fas fa-subscript"></i>',
            title: '下标',
            result: function result() {
              return exec('subscript');
            }
          },
          sup: {
            icon: '<i class="fas fa-superscript"></i>',
            title: '上标',
            result: function result() {
              return exec('superscript');
            }
          },
          removeFormat: {
            icon: '<i class="fas fa-remove-format"></i>',
            title: '清除格式',
            result: function result() {
              return exec('removeFormat');
            }
          },
          clear: {
            icon: '<i class="fas fa-eraser"></i>',
            title: '清除内容',
            result: () => {
              this.$emit('change', '')
              return;
            }
          },
        },
      }
    },
    methods: {
      onPaste(e) {
        e.stopPropagation()
        e.preventDefault()
        var clipboardData = e.clipboardData || window.clipboardData
        if (clipboardData.getData('text/html')) {
          exec('insertHtml', xss(clipboardData.getData('text/html'), this.options))
        } else {
          exec('insertText', clipboardData.getData('text'))
        }
        return true
      },
      ensureHTTP: str => /^https?:\/\//.test(str) && str || `http://${str}`,
      getActions(bottons) {
        var actions = []
        for (var i in bottons) {
          actions.push(this.actions[bottons[i]])
        }
        return actions
      }
    },
    mounted() {
      this.editor = init({
        element: this.$el,
        onChange: html => {
          this.$emit('change', xss(html, this.options))
        },
        defaultParagraphSeparator: this.defaultParagraphSeparator,
        actions: this.getActions(this.buttons),
        classes: {
          actionbar: 'pell-actionbar',
          button: 'pell-button',
          content: 'pell-content',
          selected: 'pell-button-selected'
        }
      })
      this.editor.content.onfocus = () => {
        this.focus = true
      }
      this.editor.content.onblur = () => {
        this.focus = false
      }
      this.editor.content.innerHTML = xss(this.value, this.options)
    },
    watch: {
      value: function (val, oldval) {
        if (!this.focus) {
          this.editor.content.innerHTML = xss(val, this.options)
        }
      },
    }
  }
</script>

<style>
</style>
