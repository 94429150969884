<template>
  <div class="Scale" :style="zoomStyle">
    <slot />
  </div>
</template>
<script>
  export default {
    props: {
      zoom: {
        type: Number,
        default: 1
      },
      mobileOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isMobile: !this.IsPC()
      }
    },
    computed: {
      zoomStyle: function () {
        if (this.mobileOnly && !this.isMobile) {
          return {}
        }
        return {
          width: `${(1 / this.zoom) * 100}%`,
          'margin-right': `${-((1 / this.zoom) - 1) * 100}%`,
          height: `${(1 / this.zoom) * 100}%`,
          'margin-bottom': `${-((1 / this.zoom) - 1) * 100}%`,
          transform: `translate(${-(1 - this.zoom) * 50}%, ${-(1 - this.zoom) * 50}%) scale(${this.zoom})`
        }
      }
    },
    watch: {
    },
    async mounted() {
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      IsPC() {
        const userAgentInfo = navigator.userAgent
        const Agents = ['Android', 'iPhone',
          'SymbianOS', 'Windows Phone',
          'iPad', 'iPod']
        let flag = true
        for (let v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false
            break
          }
        }
        return flag
      }
    }
  }
</script>
<style>
  .Scale {
    pointer-events: none !important;
  }

    .Scale > * {
      pointer-events: auto;
    }
</style>
