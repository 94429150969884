import { render, staticRenderFns } from "./AreaCode.vue?vue&type=template&id=44aa1145&scoped=true&lang=html&"
import script from "./AreaCode.vue?vue&type=script&lang=js&"
export * from "./AreaCode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44aa1145",
  null
  
)

export default component.exports