export default function isObjectValueEqual(a, b) {
  // Object.getOwnPropertyNames()方法返回一个由指定对象的所有自身属性的属性名（包括不可枚举属性但不包括Symbol值作为名称的属性）组成的数组
  // 换句话来说 Object.getOwnPropertyNames()方法返回的是对象所有 key 组成的数组 list
  let aProps = Object.getOwnPropertyNames(a)
  let bProps = Object.getOwnPropertyNames(b)
  if (aProps.length != bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i]
    if (typeof a[propName] === 'object') {
      let judge = isObjectValueEqual(a[propName], b[propName])
      if (!judge) {
        return false
      }
    } else if (b[propName] === undefined) {
      return false
    } else {
      if (a[propName] === b[propName]) {
        continue
      } else if (a[propName] != null && b[propName] != null) {
        if (a[propName].toString() !== b[propName].toString()) {
          return false
        }
      } else if (a[propName] == null && b[propName] == null) {
        continue
      } else {
        return false
      }
    }
  }
  return true
}
