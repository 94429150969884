import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export default {
    install(Vue) {
        // use a new Vue instance as the interface for Vue components to receive/send SignalR events
        // this way every component can listen to events or send new events using this.$mainHub
        const mainHub = new Vue()
        Vue.prototype.$mainHub = mainHub

        // Provide methods to connect/disconnect from the SignalR hub
        let connection = null
        let startedPromise = null
        let manuallyClosed = false

        Vue.prototype.startSignalR = () => {
            connection = new HubConnectionBuilder()
                .withUrl(`${Vue.prototype.$axios.defaults.baseURL}/hubs/main`,
                    Vue.prototype.$oidc.accessToken ? { accessTokenFactory: () => Vue.prototype.$oidc.accessToken } : null
                )
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build()

            // Forward hub events through the event, so we can listen for them in the Vue components
            connection.on('ReceivedMessage', (message) => {
                mainHub.$emit('message-received', { message })
            })
            connection.on('HasNewNotice', () => {
                mainHub.$emit('has-new-notice', {})
            })
            connection.on('UnreadNoticeChange', (noticeCount, notices) => {
                mainHub.$emit('unread-notice-changed', { noticeCount, notices })
            })
            connection.on('UnreadMessageChange', (messageCount, messages) => {
                mainHub.$emit('unread-message-changed', { messageCount, messages })
            })

            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start() {
                startedPromise = connection.start()
                    .catch(err => {
                        console.error('Failed to connect with hub', err)
                        return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 10000))
                    })
                return startedPromise
            }
            connection.onclose(() => {
                if (!manuallyClosed) start()
            })

            // Start everything
            manuallyClosed = false
            start()
        }
        Vue.prototype.stopSignalR = () => {
            if (!startedPromise) return

            manuallyClosed = true
            return startedPromise
                .then(() => connection.stop())
                .then(() => { startedPromise = null })
        }

        // Provide methods for components to send messages back to server
        // Make sure no invocation happens until the connection is established
        mainHub.sendMessage = (message) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('sendMessage', message))
                .catch(console.error)
        }
    }
}
