<template>
  <div class="aMap">
    <div :id="'aMap_'+randid" class="map" />
  </div>
</template>
<script>
  import gpsCorrect from '../../libs/gpsCorrect.js'
  export default {
    props: {
      mapConfig: {
        type: Object,
        default: () => {
          return {
            //center: [117.000923, 36.675807],
            //zoom: 11,
          }
        }
      },
      ToolBars: {
        type: Boolean,
        default: true
      },
      onMapReady: Function,
    },
    data: function () {
      return {
        randid: 'amap' + Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36),
        aMap: null,
        funcNameList: [],
        markers: {},
        infoWindows: {},
        markerClusterer: null,
        AMap_Driving: null,
        AMapApiKey: process.env.AMapApiKey || window.global.AMAP_API_KEY,
      }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
      // 已载入高德地图API，则直接初始化地图
      if (window.AMap) {
        this.initMap()
        // 未载入高德地图API，则先载入API再初始化
      } else {
        this.$remoteLoad(`https://webapi.amap.com/maps?v=1.4.15&key=${this.AMapApiKey}&callback=${this.getFuncName(this.initMap)}`).then(() => {
          //setTimeout(this.initMap, 300)
        })
      }
    },
    destroyed() {
      for (var i in this.funcNameList) {
        window[this.funcNameList[i]] = null
      }
      this.aMap && this.aMap.destroy()
    },
    methods: {
      initMap() {
        console.log('aMap_' + this.randid, AMap)
        console.log(document.getElementById('aMap_' + this.randid, AMap))
        if (this.randid && document.getElementById('aMap_' + this.randid, AMap) == null) {
          this.randid = Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36)
          setTimeout(this.initMap, 500)
          return
        }
        const config = {
          ...this.mapConfig
        }
        const map = new AMap.Map('aMap_' + this.randid, config)
        if (this.ToolBars) {
          AMap.plugin('AMap.ToolBar', function () { //异步加载插件 地图工具条
            var toolbar = new AMap.ToolBar({
              offset: new AMap.Pixel(6, 173),
              position: 'RT',
              ruler: true,
              locate: false,
              liteStyle: true,
              direction: false,
            })
            map.addControl(toolbar)
          })
          //AMap.plugin('AMap.ControlBar', function () { //异步加载插件 控制条
          //    var controlBar = new AMap.ControlBar({
          //        position: { top: '10px', left: '-80px' },
          //    })
          //    map.addControl(controlBar)
          //})
          AMap.plugin('AMap.MapType', function () { //异步加载插件 地图类型切换
            var mapType = new AMap.MapType()
            map.addControl(mapType)
          })
          AMap.plugin('AMap.Scale', function () { //异步加载插件 地图比例尺
            var scale = new AMap.Scale({
              offset: new AMap.Pixel(10, 10),
              position: 'RB',
            })
            map.addControl(scale)
          })
        }
        this.aMap = map
        if (this.onMapReady) {
          this.onMapReady(map)
        }
        this.$emit('MapReady', map)
      },
      setCenterLngLat(lng, lat, type) {
        if (type) {
          if (type == 'gps') {
            var p = gpsCorrect.transform(lat, lng)
            var position = new AMap.LngLat(p.lng, p.lat)
            this.aMap.setCenter(position)
          } else {
            AMap.convertFrom(new AMap.LngLat(lng, lat), type, (status, result) => {
              if (result.info === 'ok') {
                this.aMap.setCenter(result.locations[0])
              }
            })
          }
        } else {
          // 传入经纬度，设置地图中心点
          var position = new AMap.LngLat(lng, lat) // 标准写法
          // 简写 var position = [116, 39];
          this.aMap.setCenter(position)
        }
      },
      getlocation(config) {
        const _this = this
        return new Promise((resolve, reject) => {
          this.aMap.plugin('AMap.Geolocation', function () {
            var geolocation = new AMap.Geolocation({
              // 是否使用高精度定位，默认：true
              enableHighAccuracy: true,
              // 设置定位超时时间，默认：无穷大
              timeout: 10000,
              // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
              buttonOffset: new AMap.Pixel(10, 120),
              //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
              //zoomToAccuracy: true,
              //  定位按钮的排放位置,  /* LT LB RT RB */
              buttonPosition: 'RT',
              showMarker: true, // 是否显示定位点
              markerOptions: { // 自定义定位点样式，同Marker的Options
                offset: new AMap.Pixel(-18, -36),
                content: '<img src="https://a.amap.com/jsapi_demos/static/resource/img/user.png" style="width:30px;height:30px"/>'
              },
              showCircle: true, // 是否显示定位精度圈
              circleOptions: { // 定位精度圈的样式
                strokeColor: '#0093FF',
                noSelect: true,
                strokeOpacity: 0.5,
                strokeWeight: 1,
                fillColor: '#02B0FF',
                fillOpacity: 0.25
              }
            })
            _this.aMap.addControl(geolocation)
            navigator.geolocation.getCurrentPosition((position) => {
              resolve({ lat: position.coords.latitude, lng: position.coords.longitude })
              //geolocation.getCurrentPosition()
            }, (err) => {
              geolocation.getCurrentPosition()
              AMap.event.addListener(geolocation, 'complete', onComplete)
              AMap.event.addListener(geolocation, 'error', onError)

              function onComplete(data) {
                // data是具体的定位信息
                var rs = gpsCorrect.restore(data.position.getLat(), data.position.getLng())
                resolve(rs)
              }
              function onError(data) {
                // 定位出错
                reject(data)
              }
            })
          })
        })
      },
      SelfDrivingTo(gpsto) {
        var _this = this
        this.aMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
          })
          geolocation.getCurrentPosition()
          AMap.event.addListener(geolocation, 'complete', (data) => {
            AMap.convertFrom(new AMap.LngLat(gpsto.lng, gpsto.lat), 'gps', (status, result) => {
              if (result.info === 'ok') {
                console.log(gpsto, result.locations[0])
                _this.DrivingTo(data.position, result.locations[0])
              }
            })
          })
          AMap.event.addListener(geolocation, 'error', (data) => {
          })
        })
      },
      DrivingTo(from, to) {
        if (AMap) {
          if (!this.AMap_Driving) {
            AMap.plugin('AMap.Driving', () => {
              this.AMap_Driving = new AMap.Driving({
                // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
                policy: AMap.DrivingPolicy.LEAST_TIME,
                // map 指定将路线规划方案绘制到对应的AMap.Map对象上
                map: this.aMap,
                // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
                //panel: 'panel'
              })
              this.runDriving(from, to)
            })
          } else {
            this.runDriving(from, to)
          }
        }
      },
      runDriving(from, to) {
        this.closeInfoWindow('infoWindow')
        this.AMap_Driving.clear()
        console.log(from, to)
        this.AMap_Driving.search(from, to, {}, (status, result) => {
          console.log(status, result)
        })
        this.tomap = () => {
          this.AMap_Driving.searchOnAMAP({
            origin: from, //起点坐标
            originName: '我的位置', //起点名称
            destination: to, //终点坐标
            destinationName: '全景位置' //终点名称
          })
        }
        console.log(123)
      },

      setZoom(zoom) {
        this.aMap.setZoom(zoom)
      },
      addMark(name, markconfig) {
        var markcname
        if (name) {
          markcname = name
        } else {
          markcname = this.randid + Math.random().toString()
        }
        this.newMarker(markconfig).then((marker) => {
          if (this.markers[markcname]) {
            this.aMap.remove(this.markers[markcname])
          }
          this.markers[markcname] = marker
          this.aMap.add(marker)
        })
      },
      removeMark(name) {
        if (this.markers[name]) {
          this.aMap.remove(this.markers[markcname])
        }
        delete this.markers[name]
      },
      newMarker(markconfig) {
        return new Promise((resolve, reject) => {
          var config = {
            ...markconfig
          }
          if (config.gpsLat && config.gpsLng) {
            AMap.convertFrom(new AMap.LngLat(config.gpsLng, config.gpsLat), 'gps', (status, result) => {
              if (result.info === 'ok') {
                config.position = result.locations[0]
                var marker = new AMap.Marker(config)
                for (var i in config.on) {
                  marker.on(i, config.on[i])
                }
                resolve(marker)
              }
            })
          } else {
            var marker = new AMap.Marker(config)
            for (var i in config.on) {
              marker.on(i, config.on[i])
            }
            resolve(marker)
          }
        })
      },
      addMarkerClusterer(markConfigs, config) {
        var markers = []
        var pl = []
        for (var i in markConfigs) {
          var item = markConfigs[i]
          pl.push(this.newMarker(item).then((marker) => {
            markers.push(marker)
          }))
        }
        Promise.race(pl).then(() => {
          AMap.plugin('AMap.MarkerClusterer', () => { //异步加载插件
            this.markerClusterer = new AMap.MarkerClusterer(this.aMap, markers, config || { gridSize: 80 })
            for (var i in config.on) {
              this.markerClusterer.on(i, config.on[i])
            }
          })
        })
      },
      addInfoWindow(name, InfoWindowconfig) {
        var Infoname
        if (name) {
          Infoname = name
        } else {
          Infoname = this.randid + Math.random().toString()
        }
        var config = { ...InfoWindowconfig }
        if (config.gpsLat && config.gpsLng) {
          AMap.convertFrom(new AMap.LngLat(config.gpsLng, config.gpsLat), 'gps', (status, result) => {
            if (result.info === 'ok') {
              config.position = result.locations[0]
              var infoWindow = new AMap.InfoWindow(config)
              infoWindow.open(this.aMap)
              this.infoWindows[Infoname] = infoWindow
              for (var i in config.on) {
                infoWindow.on(i, config.on[i])
              }
            }
          })
        } else {
          var infoWindow = new AMap.InfoWindow(config)
          infoWindow.open(this.aMap)
          this.infoWindows[Infoname] = infoWindow
        }
      },
      closeInfoWindow(name) {
        if (this.infoWindows[name]) {
          this.infoWindows[name].close()
        }
      },
      getFuncName(func, name) {
        var funcname = null
        if (name) {
          funcname = this.randid + Math.random().toString().substr(3, 8) + name
        } else {
          funcname = this.randid + Math.random().toString().substr(3, 8)
        }
        window[funcname] = func
        this.funcNameList.push(funcname)
        return funcname
      }
    },
  }
</script>
<style scoped>
  .aMap {
    height: 100%;
    width: 100%;
  }

    .aMap .map {
      height: 100%;
      width: 100%;
      line-height: normal;
    }
</style>
<style>

  .aMap .amap-copyright {
    display: none !important;
  }
</style>
