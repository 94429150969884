<template>
  <div class="CollapedBox card card-outline" :class="getCardClass()">
    <div class="card-header p-1" :class="getBgClass()">
      <div class="card-title ml-1">
        <slot name="header">
          title
        </slot>
      </div>
      <div class="card-tools mr-0">
        <button type="button" class="btn btn-tool" data-card-widget="card-refresh" v-if="refreshIcon">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="collapse" v-if="collapseIcon">
          <i class="fas fa-plus" v-if="!expandCard"></i>
          <i class="fas fa-minus" v-else></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="maximize" v-if="maximizeIcon">
          <i class="fas fa-expand"></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="remove" v-if="removeIcon">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-1" style="overflow:auto;height: 100%;">
      <slot></slot>
    </div>
    <div v-if="showfooter" class="card-footer" style="">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CollapedBox',
    props: {
      expandCard: { type: Boolean, default: false },
      collapseIcon: { type: Boolean, default: false },
      maximizeIcon: { type: Boolean, default: false },
      refreshIcon: { type: Boolean, default: false },
      removeIcon: { type: Boolean, default: false },
      showfooter: { type: Boolean, default: true },
      //onExpanded: Function,
      //onCollapsed: Function,
      //onMaximized: Function,
      //onMinimized: Function,
      //onRemoved: Function,
      cardColor: String
    },
    data: function () {
      return {
      };
    },
    methods: {
      getCardClass() {
        var c = []
        if (!this.expandCard) {
          c.push('collapsed-card')
        }
        //if (this.cardColor) {
        //    c.push('card-' + this.cardColor)
        //} else {
        //    c.push('card-primary')
        //}
        return c
      },
      getBgClass() {
        var c = []
        if (this.cardColor) {
          c.push('bg-' + this.cardColor)
        } else {
          c.push('bg-primary')
        }
        return c
      },
      toggleMaximize() {
        $(this.$el).children().CardWidget('toggleMaximize');
      },
      toggle() {
        $(this.$el).children().CardWidget('toggle');
      },
      remove() {
        $(this.$el).children().CardWidget('remove');
      },
      expand() {
        $(this.$el).children().CardWidget('expand');
      },
      collapse() {
        $(this.$el).children().CardWidget('collapse');
      },
      onExpanded(e) {
        this.$emit('expanded',e)
      },
      onCollapsed(e) {
        this.$emit('collapsed',e)
      },
      onMaximized(e) {
        this.$emit('maximized',e)
      },
      onMinimized(e) {
        this.$emit('minimized',e)
      },
      onRemoved(e) {
        this.$emit('removed',e)
      },
    },
    created() {
    },
    mounted() {
      if (this.onExpanded) {
        $(this.$el).children().on('expanded.lte.cardwidget', this.onExpanded)
      }
      if (this.onCollapsed) {
        $(this.$el).children().on('collapsed.lte.cardwidget', this.onCollapsed)
      }
      if (this.onMaximized) {
        $(this.$el).children().on('maximized.lte.cardwidget', this.onMaximized)
      }
      if (this.onMinimized) {
        $(this.$el).children().on('minimized.lte.cardwidget', this.onMinimized)
      }
      if (this.onRemoved) {
        $(this.$el).children().on('removed.lte.cardwidget', this.onRemoved)
      }
    },
    destroyed() {
      if (this.onExpanded) {
        $(this.$el).children().off('expanded.lte.cardwidget', this.onExpanded)
      }
      if (this.onCollapsed) {
        $(this.$el).children().off('collapsed.lte.cardwidget', this.onCollapsed)
      }
      if (this.onMaximized) {
        $(this.$el).children().off('maximized.lte.cardwidget', this.onMaximized)
      }
      if (this.onMinimized) {
        $(this.$el).children().off('minimized.lte.cardwidget', this.onMinimized)
      }
      if (this.onRemoved) {
        $(this.$el).children().off('removed.lte.cardwidget', this.onRemoved)
      }
    },
    //watch: {
    //  onExpanded(newvalue, oldvalue) {
    //    if (newvalue != oldvalue) {
    //      $(this.$el).children().off('expanded.lte.cardwidget', oldvalue)
    //      $(this.$el).children().on('expanded.lte.cardwidget', newvalue)
    //    }
    //  },
    //  onCollapsed(newvalue, oldvalue) {
    //    if (newvalue != oldvalue) {
    //      $(this.$el).children().off('collapsed.lte.cardwidget', oldvalue)
    //      $(this.$el).children().on('collapsed.lte.cardwidget', newvalue)
    //    }
    //  },
    //  onMaximized(newvalue, oldvalue) {
    //    if (newvalue != oldvalue) {
    //      $(this.$el).children().off('maximized.lte.cardwidget', oldvalue)
    //      $(this.$el).children().on('maximized.lte.cardwidget', newvalue)
    //    }
    //  },
    //  onMinimized(newvalue, oldvalue) {
    //    if (newvalue != oldvalue) {
    //      $(this.$el).children().off('minimized.lte.cardwidget', oldvalue)
    //      $(this.$el).children().on('minimized.lte.cardwidget', newvalue)
    //    }
    //  },
    //  onRemoved(newvalue, oldvalue) {
    //    if (newvalue != oldvalue) {
    //      $(this.$el).children().off('removed.lte.cardwidget', oldvalue)
    //      $(this.$el).children().on('removed.lte.cardwidget', newvalue)
    //    }
    //  },
    //}

  }
</script>

<style>
</style>
