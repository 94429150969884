<template>
  <div class="PanoBingMap">
    <client-only>
      <BingMap ref="map" :onMapReady="onMapReady" :mapConfig="mapConfig"></BingMap>
    </client-only>
    <div class="drive">
      <slot name="buttons"></slot>
      <button v-if="tomap" @click="tomap" style="line-height: 2rem;height: 2.5rem;">打开高德地图开始导航</button>
    </div>
  </div>
</template>
<script>
  import BingMap from './BingMap'
  import gpsCorrect from '../../libs/gpsCorrect.js'
  export default {
    scrollToTop: true,
    components: {
      BingMap,
    },
    props: {
      currentItem: Object,
      mapCenter: Object,
      zoom: [String, Number],
      autoZoom: Boolean,
      url: {
        type: String,
        default: '/Api/Panos/PanoLocations'
      },
      params: Object,
    },
    data: () => {
      return {
        mapConfig: {
          //zoom: 1,
        },
        mapdata: null,
        panoPoints: null,
        tomap: null,
        //AMap_Driving: null,
      }
    },
    computed: {

    },
    created() {
    },
    mounted() {
      this.$axios.get(this.url, {
        params: {
          lng: 0,
          lat: 0,
          radius: 100000,
          max: 50000,
          ...this.params,
        }
      }).then((res) => {
        this.panoPoints = res.data
        if (this.$refs.map.bingMap) {
          this.setMarks()
        }
      }).catch((err) => {
        console.error(err)
      })
    },
    methods: {
      mapBackToPano() {
        if (this.currentItem) {
          var p = this.transformLoc(this.currentItem.gpsLat, this.currentItem.gpsLng)
          this.$refs.map.setCenterLngLat(p.lng, p.lat)
          var zoom = this.$refs.map.bingMap.getZoomRange()[this.zoom]
          this.$refs.map.bingMap.setView({ zoom: zoom })
        }
      },
      autoZoomOut() {
        if (!this.panoPoints) {
          setTimeout(this.autoZoomOut, 200)
          return
        }
        //if (this.$refs.map.bingMap.getZoom() == this.$refs.map.bingMap.getZoomRange().min) {
        //  return
        //}
        var zoom = this.getPanoPointsZooms()
        console.log(zoom)
        this.$refs.map.bingMap.setView({ zoom: zoom })
      },
      getPanoPointsZooms() {
        console.log('getPanoPointsZooms')
        var scales = {
          20: 10 / 75,
          19: 25 / 94,
          18: 50 / 94,
          17: 100 / 94,
          16: 200 / 94,
          15: 250 / 59,
          14: 500 / 59,
          13: 1000 / 59,
          12: 2000 / 59,
          11: 5000 / 74,
          10: 10000 / 74,
          9: 25000 / 92,
          8: 50000 / 92,
          7: 100000 / 92,
          6: 200000 / 92,
          5: 250000 / 59,
          4: 500000 / 62,
          3: 1000000 / 69,
          2: 2500000 / 65,
          1: 5000000 / 65,
        }
        var locs = []
        for (var i in this.panoPoints) {
          var item = this.panoPoints[i]
          var p = this.transformLoc(item.lat, item.lng)
          //var p = { lat: item.lat, lng: item.lng }
          //console.log(item, p)
          locs.push(new Microsoft.Maps.Location(p.lat, p.lng))
        }
        var ps = this.$refs.map.bingMap.tryLocationToPixel(locs)
        var w = this.$refs.map.bingMap.getWidth()
        var h = this.$refs.map.bingMap.getHeight()
        var z = this.$refs.map.bingMap.getZoom()
        var max = 1
        var maxp
        for (var i in ps) {
          if (ps[i]) {
            var p = ps[i]
            for (var j = 20; j > 0; j--) {
              var s = scales[z] / scales[j]
              var x = (p.x) * s
              var y = (p.y) * s
              if (x >= -w / 2 && x <= w / 2 && y >= -h / 2 && y <= h / 2) {
                if (j > max) {
                  max = j
                  maxp = { p, x, y, point: this.panoPoints[i] }
                }
                break
              }
            }
          }
        }
        console.log(maxp)
        return max
      },
      isinContainer(lng, lat) {
        var p = this.$refs.map.bingMap.tryLocationToPixel([lng, lat])
        var size = this.$refs.map.bingMap.getSize()
        return (p.getX() >= 0 && p.getX() <= size.getWidth() && p.getY() >= 0 && p.getY() <= size.getHeight())
      },
      resetZoom() {
        console.log('resetZoom')
        var zoom
        if (typeof this.zoom == 'string') {
          zoom = this.$refs.map.bingMap.getZoomRange()[this.zoom]
        } else if (typeof this.zoom == 'number') {
          zoom = this.zoom
        }
        if (this.autoZoom) {
          this.autoZoomOut()
        } else {
          this.$refs.map.bingMap.setView({ zoom: zoom })
        }
      },
      getCurrentPosition() {
        this.$refs.map.getlocation().then((data) => {
          if (this.mapCenter) {
            var p = this.transformLoc(this.mapCenter.lat, this.mapCenter.lng)
            this.$refs.map.setCenterLngLat(p.lng, p.lat)
            this.resetZoom()
          } else {
            var p = this.transformLoc(data.lat, data.lng)
            this.$refs.map.setCenterLngLat(p.lng, p.lat)
            this.resetZoom()
          }
        })
      },
      transformLoc(lat, lng) {
        var p
        switch (this.$refs.map.bingMap.getMapTypeId()) {
          case Microsoft.Maps.MapTypeId.road:
            p = gpsCorrect.transform(lat, lng)
            break
          default:
            p = { lng: lng, lat: lat }
            break
        }
        return p
      },
      onMapReady() {
        this.loadThisMark()
        if (this.mapCenter) {
          var p = this.transformLoc(this.mapCenter.lat, this.mapCenter.lng)
          this.$refs.map.setCenterLngLat(p.lng, p.lat)
          this.resetZoom()
        } else {
          this.getCurrentPosition()
        }
        this.setMarks()
        Microsoft.Maps.Events.addHandler(this.$refs.map.bingMap, 'maptypechanged', this.maptypechanged)
        this.$refs.map.getlocation()
      },
      maptypechanged(e) {
        this.setMarks()
        this.loadThisMark()
      },
      loadThisMark() {
        if (this.currentItem) {
          var p = this.transformLoc(this.currentItem.gpsLat, this.currentItem.gpsLng)
          this.$refs.map.addMark('currentItem', {
            gpsLng: this.currentItem.gpsLng,
            gpsLat: this.currentItem.gpsLat,
            position: p,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#f34f4b"><path d="M113,18.83333c-36.40483,0 -65.91667,29.51183 -65.91667,65.91667c0,47.08333 65.91667,122.41667 65.91667,122.41667c0,0 65.91667,-75.33333 65.91667,-122.41667c0,-36.40483 -29.51183,-65.91667 -65.91667,-65.91667zM113,108.29167c-13.00442,0 -23.54167,-10.53725 -23.54167,-23.54167c0,-13.00442 10.53725,-23.54167 23.54167,-23.54167c13.00442,0 23.54167,10.53725 23.54167,23.54167c0,13.00442 -10.53725,23.54167 -23.54167,23.54167z"></path></g></g></svg>`,
            //icon: startIcon,
            on: {
              click: () => {
                //var p2 = gpsCorrect.transform(this.currentItem.gpsLat, this.currentItem.gpsLng)
                //this.$refs.map.addInfoWindow('infoWindow', {
                //  position: [p2.lng, p2.lat],
                //  content: this.panoInfowindow(this.currentItem, new AMap.LngLat(p2.lng, p2.lat)),
                //  closeWhenClickMap: true,
                //  anchor: 'top-center',
                //})
                this.$refs.map.addInfoWindow('infoWindow', {
                  position: p,
                  maxHeight: 400,
                  description: this.panoInfowindow(this.currentItem).innerHTML,
                  anchor: 'top-center',
                  //showPointer: false,
                  //offset: new Microsoft.Maps.Point(-110, -126)
                  //actions: [
                  //  { label: '导航到这里', eventHandler: function () { alert('Handler1') } },
                  //]
                })
              }
            },
          })
        }
      },
      setMarks() {
        console.log('setMarks')
        var markconfigs = []
        for (var i in this.panoPoints) {
          var item = { ...this.panoPoints[i] }
          if (this.currentItem && this.currentItem.key == item.key) {
            continue
          }
          var p = this.transformLoc(item.lat, item.lng)
          //console.log(p, item)
          item.position = p
          item.on = {
            click: (event) => {
              var g = event.target.geometry
              this.$refs.map.addInfoWindow('infoWindow', {
                position: { lng: g.x, lat: g.y },
                title: `加载中…`,
                anchor: 'top-center',
              })
              var panoUrl = process.env.EnableElasticSearch ? `/Api/ES/Panos/${event.target.extData}` : `/Api/Panos/${event.target.extData}`
              this.$axios.get(panoUrl).then((res) => {
                var p = this.transformLoc(res.data.gpsLat, res.data.gpsLng)
                console.log(p)
                this.$refs.map.addInfoWindow('infoWindow', {
                  position: p,
                  maxHeight: 400,
                  description: this.panoInfowindow(res.data).innerHTML,
                  anchor: 'top-center',
                  //showPointer: false,
                  //offset: new Microsoft.Maps.Point(-110, -126)
                  //actions: [
                  //  { label: '导航到这里', eventHandler: function () { alert('Handler1') } },
                  //]
                })
              })
            }
          }
          item.extData = this.panoPoints[i].key
          markconfigs.push(item)
        }
        this.$refs.map.addMarkerClusterer(markconfigs, {
          maxZoom: 20,
          on: {
            click: (event) => {
              console.log(event)
              if (this.$refs.map.bingMap.getZoom() == this.$refs.map.bingMap.getZoomRange().max) {
                var list = []
                var marks = event.target.containedPushpins
                for (var i in marks) {
                  list.push(marks[i].extData)
                }
                var g = event.target.geometry
                this.$refs.map.addInfoWindow('infoWindow', {
                  position: { lng: g.x, lat: g.y },
                  title: `加载中…`,
                  anchor: 'top-center',
                })
                this.$axios.post('/Api/Panos/GetByKeys', list).then((res) => {
                  this.$refs.map.addInfoWindow('infoWindow', {
                    position: { lng: g.x, lat: g.y },
                    maxHeight: 400,
                    description: this.panoListInfowindow(res.data).innerHTML,
                    //showPointer: false,
                    anchor: 'top-center',
                    //actions: [
                    //  { label: '导航到这里', eventHandler: function () { alert('Handler1') } },
                    //]
                  })
                })
              } else {
                var g = event.target.geometry
                var p = new Microsoft.Maps.Location(g.y, g.x)
                this.$refs.map.bingMap.setView({ zoom: this.$refs.map.bingMap.getZoom() + 1, center: p })
              }
            }
          }
        }, 'panos')
      },
      panoInfowindow(pano) {
        //return `<div class='mapInfoWin'><div><div><a href="/P/${pano.key}" target="_blank"><img style="width:200px;height:100px;" src="${pano.firstImage.url.replace('/0/0/0/0/', '/200/0/0/0/')},"></a></div><br><div><a href="/P/${pano.key}" target="_blank"><b>${pano.title}</b></a><br>作者：<a href="/U/${pano.user.userName}" target="_blank">${pano.user.nickName}</a><br></div></div><br><a class="btmtip">导航到这里</a></div>`\
        return this.panoListInfowindow([pano])
      },
      panoListInfowindow(list) {
        console.log(list)
        var str = ''
        for (var i in list) {
          var pano = list[i]
          str += `<div class="mapInfoWin"><div><div><a href="/P/${pano.key}" target="_blank"><img style="width:200px;height:100px;" src="${pano.firstImage.url.replace('/0/0/0/0/', '/200/0/0/0/')},"></a><div class="mapuserinfo"><a class="text-truncate" href="/P/${pano.key}" target="_blank">${pano.title}</a><div>作者：<a href="/U/${pano.user.userName}" target="_blank">${pano.user.nickName}</a></div></div></div></div></div>`
        }
        var e = document.createElement('div')
        e.innerHTML = `<div class="maplistbox">${str}</div><div class="text-center" style="margin-top:3px;"></div>`
        //var b = document.createElement('button')
        //b.onclick = (e) => {
        //  console.log(e)
        //  //this.$refs.map.getlocation().then((data) => {
        //  //  var from = gpsCorrect.transform(data.lat, data.lng)
        //  //  this.DrivingTo(from, p)
        //  //})
        //}
        //b.classList.add('btmtip')
        //b.innerText = '导航到这里'
        //e.getElementsByClassName('text-center')[0].appendChild(b)
        return e
      },
      DrivingTo(from, to) {
        console.log(from, to)
        //if (AMap) {
        //  if (!this.AMap_Driving) {
        //    AMap.plugin('AMap.Driving', () => {
        //      this.AMap_Driving = new AMap.Driving({
        //        // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
        //        policy: AMap.DrivingPolicy.LEAST_TIME,
        //        // map 指定将路线规划方案绘制到对应的AMap.Map对象上
        //        map: this.$refs.map.aMap,
        //        // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
        //        //panel: 'panel'
        //      })
        //      this.runDriving(from, to)
        //    })
        //  } else {
        //    this.runDriving(from, to)
        //  }
        //}
      },
      runDriving(from, to) {
        //  this.$refs.map.closeInfoWindow('infoWindow')
        //  this.AMap_Driving.clear()
        //  //var points = [
        //  //  from,
        //  //  to
        //  //]
        //  console.log(from)
        //  console.log(to)
        //  // 搜索完成后，将自动绘制路线到地图上
        //  this.AMap_Driving.search(new AMap.LngLat(from.lng, from.lat), new AMap.LngLat(to.lng, to.lat), {}, (status, result) => {
        //    console.log(status, result)
        //  })
        //  this.tomap = () => {
        //    this.AMap_Driving.searchOnAMAP({
        //      origin: new AMap.LngLat(from.lng, from.lat), //起点坐标
        //      originName: '我的位置', //起点名称
        //      destination: new AMap.LngLat(to.lng, to.lat), //终点坐标
        //      destinationName: '全景位置' //终点名称
        //    })
        //  }
      }
    }
  }
</script>
<style lang="css">
  .PanoBingMap {
    width: 100%;
    height: 100%;
    position: relative
  }

    .PanoBingMap .drive {
      position: absolute;
      bottom: 35px;
      right: 10px;
    }

    .PanoBingMap .maplistbox {
      /*width: 200px;*/
      max-height: 300px;
      overflow-y: auto;
    }

    .PanoBingMap .mapInfoWin {
      width: 200px;
      position: relative;
    }

    .PanoBingMap .text-truncate {
      display: block;
    }

    .PanoBingMap .mapuserinfo {
      position: absolute;
      bottom: 4px;
      left: 2px;
      right: 2px;
      text-shadow: 0 0 5px #000, 0 0 4px #000, 0 0 3px #000, 0 0 2px #000;
      color: #fff;
    }

      .PanoBingMap .mapuserinfo a {
        color: #fff
      }
</style>
