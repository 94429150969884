<template>
  <div class="" style="width:100%;display:flex;justify-content:space-between;align-items:baseline;flex-wrap:wrap;flex-direction:row">
    <div class="">
      <div class="form-group form-group-sm text-nowrap m-1">
        <div class="d-inline-block" style="margin-right:5px">
          <span>共 {{total}}{{total==10000&&enableElasticSearch?'+':''}} 条</span>
        </div>
        <div class="d-inline-block" v-if="showGo">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <select class="btn btn-default" v-model:value="limit" v-on:change="pageSizeChange()" style="width:65px;">
                <option v-for="i in prePageSize" v-bind:value="i">{{i}}</option>
              </select>
            </div>
            <input type="number" class="form-control pull-right" placeholder="页码" v-model="goPage" v-on:focus="goPage=null" style="width:60px;">
            <div class="input-group-append">
              <button type="button" class="btn btn-default" v-on:click="goto()" v-bind:disabled="goPage==null||goPage<1||goPage>pages||goPage==index">Go</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <nav class="text-right">
        <ul class="pagination pagination-sm d-inline-block text-nowrap m-1">
          <!-- prev -->
          <li class="page-item d-inline-block" :class="{'disabled' : index === 1}">
            <a class="page-link" @click="prev" href="javascript:;" title="上一页"><i class="fas fa-caret-left"></i></a>
          </li>

          <!-- first -->
          <li class="page-item d-inline-block" :class="{'disabled' : index === 1}" v-if="showFirst">
            <a class="page-link" @click="first" href="javascript:;" title="第 1 页">1</a>
          </li>

          <li class="page-item d-inline-block" v-if="showPrevMore">
            <span class="page-link">…</span>
          </li>

          <li class="page-item d-inline-block" :class="{'active' : index === pager}" v-for="pager in pagers">
            <a class="page-link" @click="go(pager)" href="javascript:;" v-bind:title="'第 ' + pager + ' 页'">{{pager}}</a>
          </li>

          <li class="page-item d-inline-block" v-if="showNextMore">
            <span class="page-link">…</span>
          </li>

          <!-- last -->
          <li class="page-item d-inline-block" :class="{'disabled' : index === pages}" v-if="showLast">
            <a class="page-link" @click="last" href="javascript:;" v-bind:title="'第 ' + pages + ' 页'">{{pages}}</a>
          </li>

          <!-- next -->
          <li class="page-item d-inline-block" :class="{'disabled' : index === pages}">
            <a class="page-link" @click="next" href="javascript:;" title="下一页"><i class="fas fa-caret-right"></i></a>
          </li>
        </ul>
      </nav>
    </div>
    <!-- /.pull-right -->
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'paging',
    //通过props来接受从父组件传递过来的值
    props: {
      prePageSize: {
        type: Array,
        default: () => { return [10, 20, 50, 100] }
      },
      //页面中的可见页码，其他的以...替代, 必须是奇数
      perPages: {
        type: Number,
        default: 5
      },

      //当前页码
      pageIndex: {
        type: Number,
        default: 1
      },

      //每页显示条数
      pageSize: {
        type: Number,
        default: 10
      },

      //总记录数
      total: {
        type: Number,
        default: 1
      },

      //总记录数
      showGo: {
        type: Boolean,
        default: true
      },

      onPagerChange: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        index: this.pageIndex, //当前页码
        limit: this.pageSize, //每页显示条数
        size: this.total || 1, //总记录数
        showPrevMore: false,
        showNextMore: false,
        showFirst: false,
        showLast: false,
        goPage: null,
        enableElasticSearch: window.global.ENABLE_ELASTICSEARCH
      }
    },
    methods: {
      prev() {
        if (this.index > 1) {
          this.go(this.index - 1)
        }
      },
      next() {
        if (this.index < this.pages) {
          this.go(this.index + 1)
        }
      },
      first() {
        if (this.index !== 1) {
          this.go(1)
        }
      },
      last() {
        if (this.index != this.pages) {
          this.go(this.pages)
        }
      },
      go(page) {
        if (this.index !== page) {
          this.index = page
          //父组件通过change方法来接受当前的页码
          this.onPagerChange(this.index, this.limit)
        }
      },
      goto() {
        this.go(this.goPage)
        this.goPage = null
      },
      pageSizeChange() {
        this.onPagerChange(this.index, this.limit)
      }
    },
    computed: {

      //计算总页码
      pages() {
        return Math.ceil(this.size / this.limit)
      },

      //计算页码，当count等变化时自动计算
      pagers() {
        const array = []
        const perPages = this.perPages
        const pageCount = this.pages
        let current = this.index
        const _offset = (perPages - 1) / 2


        const offset = {
          start: current - _offset,
          end: current + _offset
        }

        //-1, 3
        if (offset.start < 1) {
          offset.end = offset.end + (1 - offset.start)
          offset.start = 1
        }
        if (offset.end > pageCount) {
          offset.start = offset.start - (offset.end - pageCount)
          offset.end = pageCount
        }
        if (offset.start < 1) offset.start = 1

        this.showPrevMore = (offset.start > 2)
        this.showNextMore = (offset.end < pageCount - 1)
        this.showFirst = (offset.start > 1)
        this.showLast = (offset.end < pageCount)

        for (let i = offset.start; i <= offset.end; i++) {
          array.push(i)
        }

        return array
      }
    },
    watch: {
      pageIndex(val) {
        this.index = val || 1
      },
      pageSize(val) {
        this.limit = val || 10
      },
      total(val) {
        this.size = val || 1
      }
    }
  }
</script>
