<template>
  <div class="CollapedBox card card-primary card-outline" :class="{'collapsed-card':!expandCard}">
    <div class="card-header p-1">
      <div class="card-title">
        <span v-if="file">{{file.fileName}}</span>
      </div>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse" v-if="collapseIcon">
          <i class="fa fa-plus" v-if="!expandCard"></i>
          <i class="fa fa-minus" v-else></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="maximize" v-if="maximizeIcon">
          <i class="fa fa-expand"></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="remove" v-if="removeIcon">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-1" style="overflow:auto;">
      <template v-if="file">
        <div v-if="file.isImage">
          <img class="img-fluid" :src="file.url.replace('\/0\/0\/0\/0\/', '\/200\/0\/0\/0\/')" />
        </div>
        <div v-else-if="file.isVideo">
          <video :src="file.url" :poster="((file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')"></video>
        </div>
        <div v-else-if="file.isAudio">
          <audio :src="file.url"></audio>
        </div>
        <div v-else>

        </div>
      </template>
    </div>
    <div class="card-footer" style="">

    </div>
  </div>
</template>

<script>
  export default {
    name: 'FileBox',
    props: {
      file: { type: Object },
      expandCard: { type: Boolean, default: true },
      collapseIcon: { type: Boolean, default: false },
      maximizeIcon: { type: Boolean, default: false },
      removeIcon: { type: Boolean, default: false },
      onRemove: { type: Function },
    },
    data: function () {
      return {
      };
    },
    methods: {
      toggleMaximize() {
        $(this.$el).children().Widget('toggleMaximize');
      },
      toggle() {
        $(this.$el).children().Widget('toggle');
      },
      remove() {
        $(this.$el).children().Widget('remove');
      },
      expand() {
        $(this.$el).children().Widget('expand');
      },
      collapse() {
        $(this.$el).children().Widget('collapse');
      },
    },
    created() {
    },
    mounted() {
      var _this = this;
      $(_this.$el).on('removed.lte.cardwidget', function () {
        console.log('removed');
        if (_this.onRemove) {
          _this.onRemove()
        }
      })
    },
    watch: {

    }

  }
</script>

<style>
</style>
