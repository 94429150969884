<template>
  <div class="HotspotIconView" :style="{'background-color':bgcolor,'height':height+'px','width':width+'px'}">
    <div v-if="icon.type=='Video'" class="imgicon" :style="{'background-image':'url(' + icon.imageUrl.replace('/0/0/0/0/', '/200/0/0/0/') + ')','height':height+'px','width':width+'px'}">

    </div>
    <template v-else>
      <div v-if="icon.isAnimation">
        <Animation :animationSet="{imageUrl:icon.imageUrl,width:icon.width,height:icon.height,fps:icon.fps,Scale:Scale}"></Animation>
      </div>
      <div v-else>
        <div class="imgicon" :style="{'background-image':'url(' + icon.imageUrl + ')','height':height+'px','width':width+'px'}">

        </div>
      </div>
      <div v-if="icontextpreview" class="testtxt" :style="teststyle">测试文本</div>
    </template>
  </div>
</template>

<script>
  import Animation from './Animation'
  export default {
    name: 'HotspotIconView',
    components: {
      Animation
    },
    props: {
      icon: Object,
      bgcolor: { type: String, default: 'transparent' },
      width: {
        type: Number,
        default: 50
      },
      height: {
        type: Number,
        default: 50
      },
      icontextpreview: {
        type: Boolean,
        default: false,
      }
    },
    data: function () {
      return {
      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {

    },
    computed: {
      Scale: function () {
        var nw, nh
        if (this.icon.isAnimation) {
          nw = this.icon.width
          nh = this.icon.height
        } else {
          if (this.icon.image) {
            nw = this.icon.image.width
            nh = this.icon.image.height
          } else {
            nw = this.width
            nh = this.height
          }
        }
        var s
        if (nw > nh) {
          s = this.width / nw
        } else {
          s = this.height / nh

        }
        return s
      },
      teststyle: function () {
        if (this.icon.edge && this.icon.edge) {
          var w, h
          if (this.icon.isAnimation) {
            w = this.icon.width * this.Scale * 0.5
            h = this.icon.height * this.Scale * 0.5

          } else {
            if (this.icon.image) {
              w = this.icon.image.width * this.Scale * 0.5
              h = this.icon.image.height * this.Scale * 0.5

            } else {
              w = this.width / 2
              h = this.height / 2
            }
          }
          console.log(w, h, this.Scale)
          var w1 = this.width / 2 - w;
          var w2 = this.width / 2;
          var w3 = this.width / 2 + w;
          var h1 = this.height / 2 - h;
          var h2 = this.height / 2;
          var h3 = this.height / 2 + h;
          console.log(w1, w2, w3, h1, h2, h3)
          var edges = {
            'lefttop': [w1, h1],
            'top': [w2, h1],
            'righttop': [w3, h1],

            'left': [w1, h2],
            'center': [w2, h2],
            'right': [w3, h2],

            'leftbottom': [w1, h3],
            'bottom': [w2, h3],
            'rightbottom': [w3, h3],
          }
          var textw = 50, texth = 16
          var textedges = {
            'lefttop': [0, 0],
            'top': [-textw / 2, 0],
            'righttop': [-textw, 0],

            'left': [0, -texth / 2],
            'center': [-textw / 2, -texth / 2],
            'right': [-textw, -texth / 2],

            'leftbottom': [0, -texth],
            'bottom': [-textw / 2, -texth],
            'rightbottom': [-textw, -texth],
          }
          var left = edges[this.icon.edge][0] + textedges[this.icon.textEdge][0] + (this.icon.textOffsetX * this.Scale)
          var right = edges[this.icon.edge][1] + textedges[this.icon.textEdge][1] + (this.icon.textOffsetY * this.Scale)
          var style = { 'left': left + 'px', 'top': right + 'px' };
          console.log(style)
          return style;
        } else {
          return { 'left': 0, 'top': 0 };
        }

      }
    },
    watch: {

    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .HotspotIconView {
    display: inline-block;
    position: relative;
  }

    .HotspotIconView .imgicon {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    .HotspotIconView .testtxt {
      font-size: 12px;
      line-height: 12px;
      border: 1px solid #000;
      position: absolute;
      width: 50px;
      height: 16px;
    }
</style>
