<template>
  <!--<textarea ref="textarea" :style="localStyle" :placeholder="placeholder" @propertychange="change" @input="change" @focus="change" :value="value" @change="$emit('valuechange', $event.target.value)"></textarea>-->
  <div>
    <textarea ref="textarea" readonly="readonly" :placeholder="placeholder" :class="cssClass" :style="mixStyle()" :value="value"></textarea>
    <div v-if="hasMore()" style="margin-top:5px">
      <a v-if="!showMore" href="javascript:" style="font-size:12px;color:#777;" @click.stop="showMore=true;change();">展开</a>
      <a v-else href="javascript:" style="font-size:12px;color:#777;" @click.stop="showMore=false;change();">收起</a>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {},
      placeholder: {
        type: String,
        default: '请输入内容'
      },
      maxRow: {
        type: Number,
      },
      extra: {
        type: Number,
        default: 0
      },
      cssStyle: {},
      cssClass: {},
    },
    watch: {
      value(val) {
        console.log(val)
        this.change()
      },
      maxRow(val) {
        this.maxHeight = val * this.lineHeight
        this.change()
     }
    },
    data() {
      return {
        isFirefox: null,
        isOpera: null,
        minHeight: null,
        maxHeight: null,
        lineHeight: null,
        length: null,
        localStyle: {},
        showMore: false,
      }
    },
    mounted() {
      this.isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window
      this.isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera')

      //autoTextarea(this.$refs.textarea, this.extra, this.maxHeight)
      this.minHeight = parseFloat(this.getStyle('height'))
      this.lineHeight = parseFloat(this.getStyle('lineHeight'))
      this.maxHeight = this.maxRow * this.lineHeight
      this.change()
    },
    methods: {
      mixStyle() {
        return { ...this.cssStyle, ...this.localStyle }
      },
      hasMore() {
        return this.maxHeight && (this.$refs.textarea || {}).scrollHeight > this.maxHeight
      },
      change(e) {
        console.log(e)
        var scrollTop, height
        var padding = 0
        var style = this.$refs.textarea.style

        //if (this.length === this.value.length) return
        //this.length = this.value.length

        if (!this.isFirefox && !this.isOpera) {
          padding = parseInt(this.getStyle('paddingTop')) + parseInt(this.getStyle('paddingBottom'))
        }
        //scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        this.localStyle.height = this.minHeight + 'px'
        if (this.$refs.textarea.scrollHeight > this.minHeight) {
          if (this.maxHeight && this.$refs.textarea.scrollHeight > this.maxHeight && !this.showMore) {
            height = this.maxHeight - padding
            //this.localStyle.overflowY = 'auto'
          } else {
            height = this.$refs.textarea.scrollHeight - padding
            //this.localStyle.overflowY = 'hidden'
          }
          this.localStyle.height = height + this.extra + 'px'
          //scrollTop += parseInt(style.height) - this.$refs.textarea.currHeight
          //document.body.scrollTop = scrollTop
          //document.documentElement.scrollTop = scrollTop
          this.$refs.textarea.currHeight = parseInt(style.height)
        }
        this.$forceUpdate()
      },
      getStyle(name) {
        //if (this.$refs.textarea.currentStyle) {
        //    var val = this.$refs.textarea.currentStyle[name]
        //    if (name === 'height' && val.search(/px/i) !== 1) {
        //        var rect = this.$refs.textarea.getBoundingClientRect()
        //        return rect.bottom - rect.top -
        //            parseFloat(this.getStyle('paddingTop')) -
        //            parseFloat(this.getStyle('paddingBottom')) + 'px'
        //    }

        //    return val
        //} else {
        return getComputedStyle(this.$refs.textarea, null)[name]
        //}
      }
    }
  }
  var autoTextarea = function (elem, extra, maxHeight) {
    extra = extra || 0
    var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window
    var isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera')
    var addEvent = function (type, callback) {
      elem.addEventListener ? elem.addEventListener(type, callback, false) : elem.attachEvent('on' + type, callback)
    }
    var getStyle = elem.currentStyle ? function (name) {
      var val = elem.currentStyle[name]

      if (name === 'height' && val.search(/px/i) !== 1) {
        var rect = elem.getBoundingClientRect()
        return rect.bottom - rect.top -
          parseFloat(getStyle('paddingTop')) -
          parseFloat(getStyle('paddingBottom')) + 'px'
      }

      return val
    } : function (name) {
      return getComputedStyle(elem, null)[name]
    }
    var minHeight = parseFloat(getStyle('height'))

    elem.style.resize = 'none'

    var change = function () {
      var scrollTop, height
      var padding = 0
      var style = elem.style

      if (elem._length === elem.value.length) return
      elem._length = elem.value.length

      if (!isFirefox && !isOpera) {
        padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'))
      }
      scrollTop = document.body.scrollTop || document.documentElement.scrollTop

      elem.style.height = minHeight + 'px'
      if (elem.scrollHeight > minHeight) {
        if (maxHeight && elem.scrollHeight > maxHeight) {
          height = maxHeight - padding
          style.overflowY = 'auto'
        } else {
          height = elem.scrollHeight - padding
          style.overflowY = 'hidden'
        }
        style.height = height + extra + 'px'
        scrollTop += parseInt(style.height) - elem.currHeight
        //document.body.scrollTop = scrollTop
        //document.documentElement.scrollTop = scrollTop
        elem.currHeight = parseInt(style.height)
      }
    }

    addEvent('propertychange', change)
    addEvent('input', change)
    addEvent('focus', change)
    change()
  }

</script>
<style scoped>
  textarea {
    display: block;
    resize: none;
    min-height: unset;
    margin: 0 auto;
    overflow: hidden;
    /*width: 550px;*/
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    text-align: justify;
    padding: 0px;
    outline: 0 none;
    border: none;
    border-radius: unset;
  }
</style>
