<template>
  <div>
    <!--找不到该组件-->
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      position: {
        default() {
          return {}
        }
      },
      animation: {},
    },
    data() {
      return {
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
</style>
