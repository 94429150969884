<template>
  <div class="HotpotLib">
    <Modal ref="HotpotLib">
      <template slot="header">
        <h4 class="modal-title">选择热点图标</h4>
      </template>
      <div class="row mb-1">
        <div class="col">
          <button type="button" class="btn btn-default btn-sm" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        </div>
        <div class="col">
          <div class="input-group input-group-sm">
            <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
            <div class="input-group-append">
              <button type="button" class="btn btn-primary" v-on:click="search()">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ul class="nav nav-tabs nav-nowrap my-1">
        <li class="nav-item">
          <a href="javascript:" class="nav-link" :class="{'active':query.private==false}" @click="query.private=false;search()">
            公共
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:" class="nav-link" :class="{'active':query.private==true}" @click="query.private=true;search()">
            私有
          </a>
        </li>
      </ul>
      <div v-if="loading" style="flex:auto;display:flex;text-align:center;justify-content:center">
        <div>
          <i class="fas fa-spinner fa-pulse"></i>
          <p>
            载入中…
          </p>
        </div>
      </div>
      <div v-else-if="list.length==0" style="flex:auto;display:flex;text-align:center;justify-content:center">
        <div>
          没有找到匹配项目
        </div>
      </div>
      <ul v-else class="row list-unstyled">
        <li class="iconitem col-4" v-for="i in list">
          <div @click="selectMaterial(i)" style="cursor: pointer;">
            <div style="text-align:center">
              <HotspotIconView :icon="i" bgcolor="#ccc"></HotspotIconView>
            </div>
            <h6 class="text-truncate text-center">
              {{i.title}}
            </h6>
          </div>
        </li>
      </ul>

      <template slot="footer">
        <paging :page-index="pager.pageIndex"
                :total="pager.totalRecords"
                :page-size="pager.pageSize"
                :per-pages="3"
                :show-go="true"
                :prePageSize="pager.prePageSize"
                :onPagerChange="pageChange">
        </paging>
      </template>
    </Modal>

  </div>
</template>

<script>
  import HotspotIconView from './HotspotIconView'
  import Modal from './Modal'
  export default {
    name: 'HotpotLib',
    props: {
      libUrl: String,
      onSelected: Function,
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data: function () {
      return {
        list: [],
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          page: 1,
          pageSize: 12,
          private: false,
          //fields: '',
          key: null,
        },
        returnFunc: null,
        loading: false,
      }
    },
    created() {
    },
    methods: {
      getHotpots(request) {
        this.loading = true
        console.log(request)
        this.$axios.get(this.libUrl, request)
          .then((res) => {
            this.list = res.data.data;
            this.pager.pageIndex = res.data.pageIndex
            this.pager.pageSize = res.data.pageSize
            this.pager.totalPages = res.data.totalPages
            this.pager.totalRecords = res.data.totalRecords
            this.loading = false
          })
          .catch((res) => {
            console.log(res)
            this.loading = false
          })
      },
      search() {
        this.query.page = 1;
        this.loadData();
      },
      loadData() {
        this.getHotpots({
          params: {
            ...this.query,
            ...this.params,
          }
        })
      },
      pageChange(pageIndex, pageSize) {
        this.query.page = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      sortingChanged(ctx) {
        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.query.sort = ctx.sortBy;
        this.query.order = ctx.sortDesc ? "desc" : "asc";
        this.loadData();
      },
      showDialog(func) {
        this.$refs.HotpotLib.show()
        this.loadData()
        if (func) {
          this.returnFunc = func
        } else {
          this.returnFunc = this.onSelected
        }
      },
      selectMaterial(item) {
        console.log(this.returnFunc)
        if (this.returnFunc) {
          this.returnFunc(item)
        }
        this.$refs.HotpotLib.hide()
      },
      cancelSelect() {
        this.$refs.HotpotLib.hide()
      }
    },
    components: {
      HotspotIconView,
      Modal,
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .HotpotLib .iconitem {
    display: inline-block;
  }
</style>
