<template>
  <div class="card card-widget widget-user-2 LibAudioCard">
    <!-- Add the bg color to the header using any of the bg-* classes -->
    <div class="widget-user-header bg-gray">
      <!-- /.widget-user-image -->
      <h4 class="text-nowrap text-truncate">{{item.name}}</h4>
      <h5 class="">{{item.addTime}}</h5>
      <slot name="addon" />
      <h6 class="widget-info">
        <span class="d-inline-block" v-if="item.duration&&item.duration!='00:00:00'">
          <i class="fas fa-clock"></i>
          <span>{{item.duration.substr(0,11)}}</span>
        </span>
        <span class="d-inline-block">
          <i class="fas fa-hdd"></i>
          <span>{{formatBytes(item.size)}}</span>
        </span>
      </h6>
    </div>
    <div class="card-footer p-0">
      <slot>

      </slot>
    </div>
  </div>
</template>

<script>
  import Animation from './Animation'
  import formatBytes from "../libs/formatBytes.js"
  export default {
    name: 'LibAudioCard',
    props: {
      item: {
        type: Object,
        default: {},
      },
    },
    data: function () {
      return {

      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
      formatBytes
    },
    computed: {
    },
    watch: {

    },

    components: {
      Animation
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .LibAudioCard .widget-user-header {
    height: 115px;
  }

    .LibAudioCard .widget-user-header h4 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: 0.8rem;
    }

    .LibAudioCard .widget-user-header h5 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: .8rem;
    }

    .LibAudioCard .widget-user-header h6 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: .7rem;
    }

    .LibAudioCard .widget-user-header .widget-info span {
      padding: 0 0.2rem 0 0;
    }
</style>
