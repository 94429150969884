<template>
  <ul class="list-unstyled">
    <li v-for="(v,k) in value">
      <div class="input-group" v-if="k==itemKey">
        <input type="text" placeholder="键" aria-label="键" v-model="item.key" class="form-control">
        <input type="text" placeholder="值" aria-label="值" v-model="item.value" class="form-control">
        <div class="input-group-append">
          <button type="button" class="btn input-group-text" @click.prevent="save()" :disabled="!item.key" title="保存"><i class="fas fa-save"></i></button>
        </div>
      </div>
      <div v-else class="input-group">
        <input type="text" placeholder="键" aria-label="键" :value="k" @click="edit(k)" readonly class="form-control">
        <input type="text" placeholder="值" aria-label="值" :value="v" @click="edit(k)" readonly class="form-control">
        <div class="input-group-append">
          <button class="btn input-group-text" @click.prevent="del(k)" title="删除"><i class="fas fa-trash"></i></button>
        </div>
      </div>
    </li>
    <li>
      <div class="input-group">
        <input type="text" placeholder="键" aria-label="键" v-model="addItem.key" class="form-control">
        <input type="text" placeholder="值" aria-label="值" v-model="addItem.value" class="form-control">
        <div class="input-group-append">
          <button class="btn input-group-text" @click.prevent="add" :disabled="!addItem.key" title="保存"><i class="fas fa-plus-square"></i></button>
        </div>
      </div>
      <!--<div v-else class="text-right"><button class="btn btn-default" @click="add" title="添加"><i class="fas fa-plus-square"></i></button></div>-->
    </li>
  </ul>
</template>
<script>
  export default {
    name: 'KeyValueInput',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        //type: String,
        required: true
      },
    },
    data() {
      return {
        itemKey: null,
        item: null,
        addItem: this.newItem(),
      }
    },
    mounted() {
    },
    methods: {
      newItem() {
        return { key: null, value: null }
      },
      edit(k) {
        this.itemKey = k
        this.item = { key: k, value: this.value[k] }
      },
      cancel() {
        this.itemKey = null
        this.item = null
      },
      add() {
        var v = this.value || {}
        v[this.addItem.key] = this.addItem.value
        this.$emit('change', v)
        this.addItem = this.newItem()
      },
      save() {
        var v = this.value || {}
        delete v[this.itemKey]
        v[this.item.key] = this.item.value
        this.itemKey = null
        this.item = null
        this.$emit('change', v)
        return
      },
      del(k) {
        this.itemKey = null
        this.item = null
        var v = this.value || {}
        delete v[k]
        this.$emit('change', v)
        this.$forceUpdate()
      },
    }
  }
</script>
<style scoped>
</style>
