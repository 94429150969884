<template>
  <div class="FacilityTXT">
    <div slot="title" class="title">
      <slot name="title">
        配套设施
      </slot>
    </div>
    <div v-for="i in showAll?facilitys:showFacility" class="iconbox" :class="i.icon" @click="i.click?i.click():null">
      <span class="txt">
        {{ i.name }}
      </span>
    </div>
    <div v-if="showAll" class="iconbox icon-more" @click="showless()">
      <span class="txt">
        收起
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      maxIcons: {
        type: Number,
        default: 8
      },
      facilitys: {
        type: Array
      }

    },
    data() {
      return {
        showFacility: [],
        showAll: false
      }
    },
    watch: {
      facilitys: function (val, oldval) {
        const self = this
        const max = this.maxIcons
        console.log(this.maxIcons)
        if (val) {
          if (val.length <= max) {
            self.showFacility = [...val]
          } else {
            self.showFacility = [
              ...val.slice(0, max - 1),
              {
                name: '更多...',
                icon: 'icon-more',
                click: self.showmore
              }
            ]
          }
        }
      }
    },
    mounted() {
      const val = this.facilitys
      const self = this
      const max = this.maxIcons
      console.log(this.maxIcons)
      if (val) {
        if (val.length <= max) {
          self.showFacility = [...val]
        } else {
          self.showFacility = [
            ...val.slice(0, max - 1),
            {
              name: '更多...',
              icon: 'icon-more',
              click: self.showmore
            }
          ]
        }
      }
    },
    methods: {
      showless() {
        this.showAll = false
      },
      showmore() {
        this.showAll = true
      }
    }
  }
</script>
<style>
  .FacilityTXT {
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
  }

    .FacilityTXT .title {
      font-weight: 700;
      font-size: 1rem;
    }

    .FacilityTXT .iconbox {
      /*border: solid 1px #808080;
      margin: 5px;
      padding: 0 3px;
      color: black;*/
      text-align: center;
      display: inline-block;
      background: #A2A6A819;
      border-radius: 2px;
      font-size: 0.8rem;
      padding: 2px 5px;
      margin: 2px;
      color: #A2A6A8;
    }

      .FacilityTXT .iconbox.icon-more {
        color: red;
      }
</style>
