<template>
  <!--<textarea ref="textarea" :style="localStyle" :placeholder="placeholder" @propertychange="change" @input="change" @focus="change" :value="value" @change="$emit('valuechange', $event.target.value)"></textarea>-->
  <textarea ref="textarea" :placeholder="placeholder" :class="cssClass" :style="cssStyle" :value="value" @change="$emit('valuechange', $event.target.value)"></textarea>
</template>
<script>
  export default {
    props: {
      value: {},
      placeholder: {
        type: String,
        default: '请输入内容'
      },
      maxHeight: {
        type: Number,
      },
      extra: {
        type: Number,
        default: 0

      },
      cssStyle: {},
      cssClass: {},
    },
    model: {
      prop: 'value',
      event: 'valuechange'
    },
    watch: {
      //value() {
      //    this.change()
      //}
    },
    data() {
      return {
        isFirefox: !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
        isOpera: !!window.opera && !!window.opera.toString().indexOf('Opera'),
        minHeight: null,
        length: null,
        localStyle: {}
      }
    },
    mounted() {
      autoTextarea(this.$refs.textarea, this.extra, this.maxHeight)
      //this.minHeight = parseFloat(this.getStyle('height'))
      // this.change()
    },
    methods: {
      focus() {
        this.$refs.textarea.focus()
      },
      change(e) {
        console.log(e)
        var scrollTop, height
        var padding = 0
        var style = this.$refs.textarea.style

        if (this.length === this.value.length) return
        this.length = this.value.length

        if (!this.isFirefox && !this.isOpera) {
          padding = parseInt(this.getStyle('paddingTop')) + parseInt(this.getStyle('paddingBottom'))
        }
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        this.localStyle.height = this.minHeight + 'px'
        if (this.$refs.textarea.scrollHeight > this.minHeight) {
          if (this.maxHeight && this.$refs.textarea.scrollHeight > this.maxHeight) {
            height = this.maxHeight - padding
            this.localStyle.overflowY = 'auto'
          } else {
            height = this.$refs.textarea.scrollHeight - padding
            this.localStyle.overflowY = 'hidden'
          }
          this.localStyle.height = height + this.extra + 'px'
          scrollTop += parseInt(style.height) - this.$refs.textarea.currHeight
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          this.$refs.textarea.currHeight = parseInt(style.height)
        }
        this.$forceUpdate()
      },
      getStyle(name) {
        //if (this.$refs.textarea.currentStyle) {
        //    var val = this.$refs.textarea.currentStyle[name]
        //    if (name === 'height' && val.search(/px/i) !== 1) {
        //        var rect = this.$refs.textarea.getBoundingClientRect()
        //        return rect.bottom - rect.top -
        //            parseFloat(this.getStyle('paddingTop')) -
        //            parseFloat(this.getStyle('paddingBottom')) + 'px'
        //    }

        //    return val
        //} else {
        return getComputedStyle(this.$refs.textarea, null)[name]
        //}
      }
    }
  }
  var autoTextarea = function (elem, extra, maxHeight) {
    extra = extra || 0
    var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window
    var isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera')
    var addEvent = function (type, callback) {
      elem.addEventListener ? elem.addEventListener(type, callback, false) : elem.attachEvent('on' + type, callback)
    }
    var getStyle = elem.currentStyle ? function (name) {
      var val = elem.currentStyle[name]

      if (name === 'height' && val.search(/px/i) !== 1) {
        var rect = elem.getBoundingClientRect()
        return rect.bottom - rect.top -
          parseFloat(getStyle('paddingTop')) -
          parseFloat(getStyle('paddingBottom')) + 'px'
      }

      return val
    } : function (name) {
      return getComputedStyle(elem, null)[name]
    }
    var minHeight = parseFloat(getStyle('height'))

    elem.style.resize = 'none'

    var change = function () {
      var scrollTop, height
      var padding = 0
      var style = elem.style

      if (elem._length === elem.value.length) return
      elem._length = elem.value.length

      if (!isFirefox && !isOpera) {
        padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'))
      }
      scrollTop = document.body.scrollTop || document.documentElement.scrollTop

      elem.style.height = minHeight + 'px'
      if (elem.scrollHeight > minHeight) {
        if (maxHeight && elem.scrollHeight > maxHeight) {
          height = maxHeight - padding
          style.overflowY = 'auto'
        } else {
          height = elem.scrollHeight - padding
          style.overflowY = 'hidden'
        }
        style.height = height + extra + 'px'
        scrollTop += parseInt(style.height) - elem.currHeight
        //document.body.scrollTop = scrollTop
        //document.documentElement.scrollTop = scrollTop
        elem.currHeight = parseInt(style.height)
      }
    }

    addEvent('propertychange', change)
    addEvent('input', change)
    addEvent('focus', change)
    change()
  }

</script>
<style scoped>
  textarea {
    display: block;
    resize: none;
    min-height: unset;
    margin: 0 auto;
    overflow: hidden;
    /*width: 550px;*/
    font-size: 14px;
    height: 28px;
    line-height: 24px;
    padding: 2px;
    outline: 0 none;
    border-color: rgba(82, 168, 236, 0.8);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
  }
</style>
