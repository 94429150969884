<template lang="html">
  <!-- Modal -->
  <div class="numerButton">
    <button class="side left" style="flex:none" @click="sub"><i class="fas fa-minus"></i></button>
    <button class="center text-shadow-alt" style="flex:auto" @click="click" @dblclick="dblclick" @mousedown="down" @mousemove="move" @mouseup="up" @touchstart.stop.prevent="down" @touchmove.stop.prevent="move" @touchend.stop.prevent="up" @mousewheel.stop.prevent="wheel"><slot><span v-if="name">{{name}}:</span>{{Number(value).toFixed(FixNum)}}</slot></button>
    <button class="side right" style="flex:none" @click="add"><i class="fas fa-plus"></i></button>
  </div>
</template>

<script>
  export default {
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        required: true
      },
      name: {},
      defaultValue: {},
      max: {},
      min: {},
      step: {},
      wheelRatio: {
        default: 0.01
      },
      moveRatio: {
        default: 0.01
      },
      dbclickTime: {
        default: 200
      },
    },
    mounted() {
    },
    data() {
      return {
        moveing: false,
        pos: null,
        dbclickTimer: null,
      }
    },
    computed: {
      FixNum() { return this.getdecimalPlaces(this.step) }
    },
    methods: {
      getdecimalPlaces(n) {
        n = Number(n)
        var i = 0
        if (n) {
          while ((n * Math.pow(10, i)) % 1 != 0) {
            i++
          }
          return i
        }
        return false
      },
      click(e) {
        this.$emit('click', e)
      },
      dblclick(e) {
        var v = Number(this.defaultValue)
        if (v != NaN) {
          this.changeValue(v)
        }
      },
      add() {
        if (this.step) {
          this.changeValue(+this.value + +this.step)
        }
      },
      sub() {
        if (this.step) {
          this.changeValue(+this.value - this.step)
        }
      },
      wheel(e) {
        var d = e.deltaY * +this.wheelRatio
        //if (this.step) {
        //    d -= d % this.step
        //}
        this.changeValue(+this.value + d)
      },
      changeValue(v) {
        console.log(v, this.min)
        if (Number(this.min) != NaN && v < +this.min) {
          v = +this.min
        }
        if (Number(this.max) != NaN && v > +this.max) {
          v = +this.max
        }
        if (this.FixNum != false) {
          v = Number(v.toFixed(this.FixNum))
        }
        this.$emit('change', v)
      },
      down(e) {
        this.moveing = true;
        this.pos = e.screenX || e.touches[0].screenX
        window.addEventListener('mousemove', this.move)
        window.addEventListener('mouseup', this.up)
      },
      move(e) {
        if (this.moveing) {
          var p = e.screenX || e.touches[0].screenX
          var d = (p - this.pos) * this.moveRatio
          if (this.step) {
            d -= d % this.step
          }
          this.changeValue(+this.value + d)
          this.pos += d / this.moveRatio
        }
      },
      up(e) {
        this.moveing = false;
        this.checkDbClick()
        window.removeEventListener('mousemove', this.move)
        window.removeEventListener('mouseup', this.up)
      },
      checkDbClick() {
        console.log('checkDbClick', this.dbclickTimer)
        if (this.dbclickTimer) {
          this.dblclick()
          clearTimeout(this.dbclickTime)
          this.dbclickTimer = null
        } else {
          this.dbclickTimer = setTimeout(() => { this.dbclickTimer = null }, this.dbclickTime)
        }
      }
    }
  }
</script>

<style lang="css" scoped>
  button {
    border: none;
    background-color: transparent;
  }

    button:focus {
      outline: none;
    }

  .numerButton {
    display: flex;
    border: 1px solid #bbb;
    border-radius: 3px;
    overflow: hidden;
    background-color: #fff;
  }

    .numerButton:hover {
      background-color: #eee;
    }

  .right {
    border-left: 1px solid #bbb;
  }

  .left {
    border-right: 1px solid #bbb;
  }

  .side {
    flex: none;
    background-color: #fff;
  }

    .side:hover {
      background-color: #eee;
    }

  .center {
    flex: auto;
    text-align: center;
    cursor: col-resize;
    /*background-image: linear-gradient(to right, #808080,#fff,#808080);*/
    /*background-image: linear-gradient(to right, #000,#fff,#fff, #111,#fff,#fff, #222,#fff,#fff, #333,#fff,#fff, #444,#fff,#fff, #555,#fff,#fff, #666,#fff,#fff, #777,#fff,#fff, #888,#fff,#fff, #999,#fff,#fff, #aaa,#fff,#fff, #bbb,#fff,#fff, #ccc,#fff,#fff, #ddd,#fff,#fff, #eee,#fff,#fff, #fff,#fff,#fff, #fff,#fff,#fff, #eee,#fff,#fff, #ddd,#fff,#fff, #ccc,#fff,#fff, #bbb,#fff,#fff, #aaa,#fff,#fff, #999,#fff,#fff, #888,#fff,#fff, #777,#fff,#fff, #666,#fff,#fff, #555,#fff,#fff, #444,#fff,#fff, #333,#fff,#fff, #222,#fff,#fff, #111,#fff,#fff, #000,#fff,#fff,#000);*/
    background: linear-gradient(to right,#ffff,#fffb,#ffff),repeating-linear-gradient(to right,#000 0,#000 2px,#fff 0,#fff 4px);
    background-position: center;
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    /*background-size:200px;*/
  }

  @keyframes example {
    from {
      background-position-x: -5px;
    }

    to {
      background-position-x: 5px;
    }
  }
</style>
