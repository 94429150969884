<template>
  <div class="FileMultipleLib">
    <SimpleModal ref="FileLib" :onHide="clearList" :zIndex="1040">
      <div style="display:flex;flex-direction:column;position:relative;max-width:450px;height:calc(100vh - 22px);">
        <div style="flex:none;display:flex;justify-content:space-between;padding-bottom:5px">
          <div style="flex:auto;display:flex;align-items:center;flex-wrap:nowrap">
            <button class="btn btn-primary btn-sm mr-1" @click="showUploadDialog()" title="添加"><i class="fas fa-upload"></i></button>
            <button class="btn btn-default btn-sm mr-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
            <!--<div class="input-group input-group-sm " style="width: 110px;">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">类型</span>
                </div>
                <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                    <option :value="null">所有</option>
                    <option v-for="i in types" v-bind:value="i.value">{{i.name}}</option>
                </select>
            </div>
            <div class="input-group input-group-sm " style="width: 110px;">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">标签</span>
                </div>
                <select class="form-control" v-model:value="query.tag" v-on:change="search()" placeholder="标签">
                    <option :value="null">所有</option>
                    <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                </select>
            </div>-->
          </div>
          <div style="display:flex;cursor:pointer;" @click="$refs.FileLib.show=false">
            <div style="padding:5px">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
        <div style="flex:auto;display:flex;flex-direction:column;overflow-y: auto;max-height: 100%;overflow-x: hidden;">
          <div style="display:flex">
            <div class="input-group input-group-sm mb-1" style="flex:auto">
              <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
              <div class="input-group-append">
                <button type="button" class="btn btn-primary" v-on:click="search()">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
            <div style="flex:none">
              <div class="dropdown ml-1">
                <button ref="dropdown" class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  筛选
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <!--<form v-if="!withType" class="dropdown-item p-1">
                      <div class="input-group input-group-sm ">
                          <div class="input-group-prepend">
                              <span class="input-group-text" style="font-weight:700">类型</span>
                          </div>
                          <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                              <option :value="null">所有</option>
                              <option v-for="i in types" v-bind:value="i.value">{{i.name}}</option>
                          </select>
                      </div>
                  </form>-->
                  <form class="dropdown-item p-1">
                    <div class="input-group input-group-sm ">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="font-weight:700">标签</span>
                      </div>
                      <select class="form-control form-control-sm" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                        <option :value="null">所有</option>
                        <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                      </select>
                    </div>
                  </form>
                  <!--<form class="dropdown-item p-1">
                      <div class="input-group input-group-sm ">
                          <div class="input-group-prepend">
                              <span class="input-group-text" style="font-weight:700">起始</span>
                          </div>
                          <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                      </div>
                  </form>
                  <form class="dropdown-item p-1">
                      <div class="input-group input-group-sm ">
                          <div class="input-group-prepend">
                              <span class="input-group-text" style="font-weight:700">结束</span>
                          </div>
                          <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                      </div>
                  </form>-->
                </div>
              </div>
            </div>
          </div>
          <div>
            <ul class="nav nav-tabs" v-if="!withType">
              <li class="nav-item" v-for="i in types">
                <a class="nav-link px-3 py-1" :class="{'active':query.type==i.value}" href="#" @click="changeType(i.value)">{{i.name}}</a>
              </li>
            </ul>
          </div>
          <div v-if="loading" style="flex:auto;display:flex;text-align:center;justify-content:center">
            <div>
              <i class="fas fa-spinner fa-pulse"></i>
              <p>
                载入中…
              </p>
            </div>
          </div>
          <template v-else>
            <div v-if="list.length==0" style="flex:auto;display:flex;text-align:center;justify-content:center">
              <div>
                没有找到匹配项目
              </div>
            </div>
            <div style="flex:auto;">
              <div class="row">
                <div v-for="i in list" class="col-6" :class="{active:isSelected(i)}">
                  <!--<template v-if="i.isImage">
                      <LibImageCard :item="i" @click="select(i)">
                      </LibImageCard>
                  </template>
                  <template v-else-if="i.isVideo">
                      <LibVideoCard :item="i" @click="select(i)">
                      </LibVideoCard>
                  </template>
                  <template v-else-if="i.isAudio">
                      <LibAudioCard :item="i" @click="select(i)">
                      </LibAudioCard>
                  </template>
                  <template v-else>
                      <LibFileCard :item="i" @click="select(i)">
                      </LibFileCard>
                  </template>-->

                  <div class="small-box bg-gray" style="cursor:pointer;" v-bind:style="i.isImage||i.isVideo?resizeimg(i.url):{'height': '150px'}" @click="select(i)">
                    <div class="inner">
                      <h4 class="text-truncate">{{i.name}}</h4>
                      <h5>{{i.addTime}}</h5>
                    </div>
                    <div class="small-box-footer">
                      <div v-if="i.isImage||i.isVideo||i.isPano" class="mx-1 d-inline-blick"><i class="fa fa-window-maximize"></i> {{i.width}}x{{i.height}}</div>
                      <div v-if="(i.isAudio||i.isVideo)&&i.duration" class="mx-1 d-inline-blick"><i class="fa fa-window-maximize"></i> {{i.duration.substr(0,11)}}</div>
                      <div class="mx-1 d-inline-blick"><i class="fa fa-hdd"></i> {{formatBytes(i.size)}}</div>
                      <div class="boxselected"><i class="fa fa-check-square"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div style="flex:none;background-color:#fff;z-index:10;display:flex;align-items:flex-end">
          <div class="mr-1">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
          </div>
          <button v-if="multiple" class="btn btn-primary btn-sm mb-1" @click="submit" style="white-space:nowrap">
            确定
          </button>
        </div>
      </div>
    </SimpleModal>
    <Modal ref="uploadModal">
      <template slot="header">
        <!--<div class="nav-tabs-custom">
            <ul class="nav nav-pills">
                <li class="nav-item"><a class="nav-link active" href="#uploadPano" data-toggle="tab">全景</a></li>
                <li class="nav-item"><a class="nav-link" href="#uploadImg" data-toggle="tab">图片</a></li>
                <li class="nav-item"><a class="nav-link" href="#uploadMusic" data-toggle="tab">音频</a></li>
                <li class="nav-item"><a class="nav-link" href="#uploadVideo" data-toggle="tab">视频</a></li>
            </ul>
        </div>-->
        <template v-for="i in types">
          <h5 v-if="query.type==i.value">
            上传{{i.name}}
          </h5>
        </template>
      </template>

      <div class="tab-content">
        <!-- Font Awesome Icons -->
        <div class="tab-pane" :class="{'active':query.type=='Pano'}" id="uploadPano">
          <UploadBox :action="panosUploadUrl"
                     :param="uploadParams"
                     :multiple="true"
                     :maxSize="50"
                     :maxWidth="22000"
                     :maxHeight="11000"
                     :isPano="true"
                     :allowExtensions="/(.jpg|.jpeg)$/i"
                     :partSizeMB="10"
                     :surlheight="100"
                     :surlwidth="200"
                     :maxUpper="3"
                     :uploadFinished="search"
                     :changeName="changePanoName">
          </UploadBox>
        </div>
        <div class="tab-pane" :class="{'active':query.type=='Image'}" id="uploadImg">
          <UploadBox :action="filesUploadUrl"
                     :param="uploadParams"
                     :multiple="true"
                     :maxSize="2"
                     :maxWidth="2500"
                     :maxHeight="2500"
                     :compress="true"
                     :isPano="false"
                     :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                     :partSizeMB="10"
                     :surlheight="100"
                     :surlwidth="200"
                     :maxUpper="3"
                     :uploadFinished="search"
                     :changeName="changeName">

          </UploadBox>
        </div>
        <div class="tab-pane" :class="{'active':query.type=='Audio'}" id="uploadMusic">
          <UploadBox :action="filesUploadUrl"
                     :param="uploadParams"
                     :multiple="true"
                     :maxSize="5"
                     :allowExtensions="/(.aac|.mp3)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     previewIcon="fa fa-file-audio-o"
                     :changeName="changeName">
          </UploadBox>
        </div>
        <div class="tab-pane" :class="{'active':query.type=='Video'}" id="uploadVideo">
          <UploadBox :action="filesUploadUrl"
                     :param="uploadParams"
                     :multiple="true"
                     :maxSize="50"
                     :allowExtensions="/(.mp4)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     previewIcon="fa fa-file-video-o"
                     :changeName="changeName">
          </UploadBox>
        </div>
        <div class="tab-pane" :class="{'active':query.type=='Coordinate'}" id="uploadGeojson">
          <UploadBox :action="filesUploadUrl"
                     :param="uploadParams"
                     :multiple="true"
                     :maxSize="1"
                     :geoJson="true"
                     :allowExtensions="/(.kml|.gpx|.geojson)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     previewIcon="fas fa-route"
                     :changeName="changeName">
          </UploadBox>
        </div>
      </div>

      <template slot="footer">

      </template>
    </Modal>
  </div>
</template>

<script>
  import UploadBox from './UploadBox'
  import CheckBox from './CheckBox'
  import SimpleModal from './SimpleModal'
  import Modal from './Modal'
  import formatBytes from '../libs/formatBytes.js'
  export default {
    name: 'FileLib',
    components: {
      Modal,
      SimpleModal,
      UploadBox,
      CheckBox,
      //LibImageCard,
      //LibVideoCard,
      //LibAudioCard,
      //LibFileCard,
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      withType: {
        type: String,
        default: null
      },
      libUrl: String,
      panosUploadUrl: String,
      filesUploadUrl: String,
      tagsUrl: String,
      fileUrl: String,
      panoUrl: String,
      params: Object,
      uploadParams: {},
      multiple: {
        type: Boolean,
        default: false,
      },
      value: {},
      //onSelected: Function,
      //onSubmit: Function,
    },
    data() {
      return {
        list: [],
        tags: [],
        types: [
          { name: '图片', value: 'Image' },
          //{ name: '全景', value: 'Pano' },
          { name: '视频', value: 'Video' },
          { name: '音频', value: 'Audio' },
          { name: '航迹', value: 'Coordinate' },
        ],
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          ...this.newQuery(), ...this.params
        },
        fileList: [],
        selectedList: [],
        loading: false,
        returnFunc: null,
      }
    },
    created() {
      //this.loadTags()
      //this.loadData()
    },
    methods: {
      newQuery() {
        return {
          page: 1,
          pageSize: 12,
          type: 'Image',
          tags: null,
          //sort: "Id",
          //order: "desc",
          //fields: 'Title',
          //key: null,
        }
      },
      showDialog(func) {
        this.$refs.FileLib.show = true
        this.loadTags()
        this.loadData()
        this.$nextTick(() => { $(this.$refs.dropdown).dropdown('hide') })
        this.returnFunc = func
      },
      clearList() {
        this.selectedList = []
      },
      select(item) {
        if (this.multiple) {
          let list = [...(this.value || this.selectedList)]
          var has = false
          for (var i in list) {
            var t = list[i].guid == item.guid
            if (t) {
              list.splice(i, 1);
              has = true
              break;
            }
          }
          if (!has) {
            list.push(item);
          }
          if (this.value) {
            this.$emit('change', list)
          } else {
            this.selectedList = list
          }
        } else {
          this.submit(item)
        }
      },
      close() {
        this.$refs.FileLib.show = false
      },
      cancel() {
        this.close()
      },
      formatBytes(bit) {
        return formatBytes(bit, 0)
      },
      submit(item) {
        var r = this.multiple ? this.value || this.selectedList : item
        if (this.returnFunc) {
          this.returnFunc(r)
        } else {
          this.$emit('submit', r)
        }
        this.close()
      },
      changeType(type) {
        //$('#btn_' + type).tab('show')
        this.query.type = type;
        this.loadData();
      },
      getPanos(request) {
        if (this.loading) {
          return
        }
        this.loading = true
        console.log(request)
        this.$axios.get(this.libUrl, request)
          .then((res) => {

            this.list = res.data.data;
            this.pager.pageIndex = res.data.pageIndex
            this.pager.pageSize = res.data.pageSize
            this.pager.totalPages = res.data.totalPages
            this.pager.totalRecords = res.data.totalRecords

            this.loading = false
          })
          .catch((res) => {
            this.loading = false
          })
      },
      loadData() {
        var config = {
          params: this.query
        }
        if (this.withType) {
          config.params.type = this.withType
        }
        this.getPanos(config)
      },
      loadTags() {
        this.$axios.get(this.tagsUrl)
          .then((response) => {
            //console.log(response)
            this.tags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      search() {
        this.query.page = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.page = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      sortingChanged(ctx) {
        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.query.sort = ctx.sortBy;
        this.query.order = ctx.sortDesc ? "desc" : "asc";
        this.loadData();
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'contain',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'height': '150px'
        }
      },
      showUploadDialog() {
        this.$refs.uploadModal.show();
      },
      hideUploadDialog() {
        this.$refs.uploadModal.hide()
        this.fileList = []
      },
      changeName(item, res) {
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.name = item.title;
          console.log(data)
          this.$axios.put(`${this.libUrl}/${data.guid}`, data).then((res) => {
            if (res.status == 200) {
              this.search()
            }
          })
        }
      },
      changePanoName(item, res) {
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.title = item.title;
          console.log(data)
          this.$axios.put(`${this.panoUrl}/${data.guid}`, data).then((res) => {
            if (res.status == 200) {
              this.search()
            }
          })
        }
      },
      isSelected(item) {
        var _this = this;
        var t = this.selectedList.filter(i => i.guid == item.guid).length > 0
        if (t) {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      params(value) {
        this.query = {
          ...this.newQuery(), ...value
        }
      },
      withType(value) {
        this.query = {
          ...this.newQuery(), ...this.params
        }
      },
    },
    computed: {
      //list() {
      //  this.pager.pageIndex = this.$store.state.panos.main.pageIndex
      //  this.pager.pageSize = this.$store.state.panos.main.pageSize
      //  this.pager.totalPages = this.$store.state.panos.main.totalPages
      //  this.pager.totalRecords = this.$store.state.panos.main.totalRecords
      //  return this.$store.state.panos.main
      //}
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
  .FileMultipleLib .Modal {
    z-index: 1050;
  }

  .FileMultipleLib .active .boxselected {
    display: block;
  }

  .FileMultipleLib .boxselected {
    position: absolute;
    right: 6px;
    bottom: 3px;
    display: none;
  }

  .FileMultipleLib .small-box > .small-box-footer {
    background: rgba(0, 0, 0, 0.5);
  }

  .FileMultipleLib .active .small-box > .small-box-footer {
    background: rgba(0, 0, 255, 0.50);
  }

  .FileMultipleLib .small-box .inner h4 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1rem;
  }

  .FileMultipleLib .small-box .inner h5 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 0.8rem;
  }

  .FileMultipleLib .small-box .inner h6 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 0.7rem;
  }

  .FileMultipleLib .small-box .small-box-footer {
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-shadow: 1px 1px 1px #000;
  }
</style>
