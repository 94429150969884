import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './actions'
import * as getters from './getters'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        accessToken: null,
        roles: [],
        settings: null,
        loading: false
    },
    mutations: {
        setAccessToken(state, value) {
            state.accessToken = value
        },
        setRoles(state, value) {
            state.roles = value
        },
        setSettings(state, value) {
            state.settings = value
        },
        showLoading(state) {
            state.loading = true
        },
        hideLoading(state) {
            state.loading = false
        }
    },
    actions,
    getters,
    modules,
    strict: process.env.NODE_ENV !== 'production'
})
