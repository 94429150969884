import * as types from './mutation-types'
import { services } from './api'

export const fetchProduct = ({ commit }) => {
  // API request
  return services.products.getAll()
    .then((response) => {
      console.log(response)
      commit(types.FETCH_PRODUCT, response.data)
    })
    .catch((error) => {
      console.error(error)
    })
}

export const getUser = ({ commit }) => {
  // API request
  return services.user.getAll()
    .then((response) => {
      console.log(response)
      commit(types.FETCH_USER, response.data)
    })
    .catch((error) => {
      console.error(error)
    })
}

export const getSetting = ({ commit }) => {
    // API request
    return services.setting.getAll()
        .then((response) => {
            console.log(response)
            commit(types.FETCH_SETTING, response.data)
            commit('setSettings', response.data)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const getMessages = ({ commit }) => {
    // API request
    return services.messages.getUnread()
        .then((response) => {
            console.log(response)
            commit(types.FETCH_MESSAGE, response.data)
        })
        .catch((error) => {
            console.error(error)
        })
}
export const getMessagesCount = ({ commit }) => {
    // API request
    return services.messages.getUnreadCount()
        .then((response) => {
            console.log(response)
            commit(types.FETCH_MESSAGE_COUNT, response.data)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const getNotices = ({ commit }) => {
    // API request
    return services.notices.getUnread()
        .then((response) => {
            console.log(response)
            commit(types.FETCH_NOTICE, response.data)
        })
        .catch((error) => {
            console.error(error)
        })
}
export const getNoticesCount = ({ commit }) => {
    // API request
    return services.notices.getUnreadCount()
        .then((response) => {
            console.log(response)
            commit(types.FETCH_NOTICE_COUNT, response.data)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const setPageInfo = ({ commit }, title) => {
  commit(types.FETCH_PAGEINFO, title)
  document.title = title
}
