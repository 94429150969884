<template>
  <div class="card card-widget widget-user-2 LibFileCard">
    <!-- Add the bg color to the header using any of the bg-* classes -->
    <div class="widget-user-header bg-gray">
      <!-- /.widget-user-image -->
      <h4 class="text-nowrap text-truncate">{{item.name}}</h4>
      <h5 class="">{{item.addTime}}</h5>
      <slot name="addon" />
      <h6 class="widget-info">
        <span class="d-inline-block">
          <i class="fas fa-hdd"></i>
          <span>{{formatBytes(item.size)}}</span>
        </span>
      </h6>
    </div>
    <div class="card-footer p-0">
      <slot>

      </slot>
    </div>
  </div>
</template>

<script>
  import Animation from './Animation'
  import formatBytes from "../libs/formatBytes.js"
  export default {
    name: 'LibFileCard',
    props: {
      item: {
        type: Object,
        default: {},
      },
    },
    data: function () {
      return {

      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      formatBytes
    },
    computed: {
    },
    watch: {

    },

    components: {
      Animation
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .LibFileCard .widget-user-header {
    height: 115px;
  }

    .LibFileCard .widget-user-header h4 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: 0.8rem;
    }

    .LibFileCard .widget-user-header h5 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: 0.8rem;
    }

    .LibFileCard .widget-user-header h6 {
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      font-size: .7rem;
    }
</style>
