<template lang="html">
  <div class="audioLib">
    <Modal ref="audioLib" :onhide="audioLibonhide">
      <template slot="header">
        <h3 class="card-title">选择音频</h3>
      </template>
      <div class="mailbox-controls">
        <!-- /.btn-group -->
        <!-- 音频播放 -->
        <div class="row mb-1">
          <div class="col">
            <button type="button" class="btn btn-default btn-sm" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
          </div>
          <div class="col">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
              <div class="input-group-append">
                <button type="button" class="btn btn-primary" v-on:click="search()">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" placeholder="无音乐" readonly :value="(nowAudio||{}).name">
              <div class="input-group-append">
                <button type="button" class="btn btn-default" @click="nowAudio=null;$refs.audioPlayer.load()">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="input-group input-group-sm ">
              <div class="input-group-prepend">
                <span class="input-group-text" style="font-weight:700">标签</span>
              </div>
              <select class="form-control" v-model:value="query.tag" v-on:change="search()" placeholder="标签">
                <option value="">所有</option>
                <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <audio :src="nowAudio?nowAudio.url:null" controls="controls" autoplay="autoplay" class="audioPlay  mt-1 mt-sm-0" controlslist="nodownload" ref="audioPlayer"></audio>
        </div>
        <ul class="nav nav-tabs nav-nowrap my-1">
          <li class="nav-item">
            <a href="javascript:" class="nav-link" :class="{'active':query.private==false}" @click="query.private=false;search()">
              公共
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:" class="nav-link" :class="{'active':query.private==true}" @click="query.private=true;search()">
              私有
            </a>
          </li>
        </ul>

      </div>
      <div style="position:relative">
        <vue-bootstrap4-table :rows="list"
                              :columns="columns"
                              :config="config"
                              :classes="classes"
                              @on-change-query="onChangeQuery">
          <template slot="sort-asc-icon">
            <i class="fas fa-sort-up"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="fas fa-sort-down"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="fas fa-sort"></i>
          </template>
          <template slot="empty-results">
            没有数据
          </template>
          <template slot="name" slot-scope="props">
            <div @click="rowSelected(props.row)">
              {{props.row.name}}
            </div>
          </template>
          <template slot="duration" slot-scope="props">
            <div @click="rowSelected(props.row)">
              {{props.row.duration.substr(0,12)}}
            </div>
          </template>
        </vue-bootstrap4-table>
        <div v-if="loading" style="position:absolute;top:0;left:0;bottom:0;right:0;background-color:#fff9;">
          <div style="display:flex;text-align:center;justify-content:center;align-items: center;height: 100%;">
            <div>
              <i class="fas fa-spinner fa-pulse"></i>
              <p>
                载入中…
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <paging :page-index="pager.page"
                :total="pager.totalRecords"
                :page-size="pager.pageSize"
                :per-pages="3"
                :show-go="true"
                :prePageSize="pager.prePageSize"
                :onPagerChange="pageChange">
        </paging>
      </div>
      <template slot="footer">
        <button type="button" class="btn btn-default" @click="cancelSelect">取消</button>
        <button type="button" class="btn btn-primary" @click="selectMaterial(nowAudio)">确定</button>
      </template>

    </Modal>
  </div>
</template>

<script>
  import Modal from './Modal'
  import VueBootstrap4Table from 'vue-bootstrap4-table'

  export default {
    components: {
      Modal,
      VueBootstrap4Table
    },
    props: {
      libUrl: String,
      tagUrl: String,
      onSelected: Function,
      userName: String,
      params: Object,
    },
    data() {
      return {
        list: [],
        tags: [],
        item: {
          userId: null,
          id: null,
          userName: null,
          password: null,
          email: null,
          phoneNumber: null,
          nickName: null,
          closed: false,
          roles: [],
        },
        columns: [
          { name: 'name', label: '名称', sort: true, initial_sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'duration', label: '时长', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_du", row_text_alignment: "text-left" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          page: 1,
          pageSize: 10,
          sort: "addTime",
          order: "asc",
          fields: 'Name,FileName',
          key: null,
          private: false,
          tag: ""
        },
        nowAudio: null,
        loading: false,
        tagloading: false,
      }
    },
    created() {
      this.loadTags()
      this.loadData()
    },
    computed: {
    },
    methods: {
      loadData() {
        this.loading = true
        this.list = []
        var config = {
          params: {
            ...this.query,
            ...this.params
          }
        };
        if (this.query.private) {
          config.params.userName = this.userName
        }
        this.$axios.get(this.libUrl, config).then((res) => {
          //console.log(res.data)
          this.loading = false
          this.list = res.data.data
          this.pager.pageIndex = res.data.pageIndex
          this.pager.pageSize = res.data.pageSize
          this.pager.totalPages = res.data.totalPages
          this.pager.totalRecords = res.data.totalRecords
        })

      },
      search() {
        this.query.page = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.page = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.query.sort = sort.name
        this.query.order = sort.order
        this.loadData()
      },
      onSelectRows(payload) {
        console.log(payload);
      },
      loadTags() {
        this.tagloading = true
        let _this = this
        if (this.tagUrl) {
          this.$axios.get(this.tagUrl)
            .then(function (response) {
              //console.log(response)
              _this.tags = response.data
              _this.tagloading = false
            })
            .catch(function (error) {
              _this.tagloading = false
              console.log(error)
            })
        }
      },
      loadAudio(id) {
        let _this = this
        this.$axios.get(this.libUrl + id)
          .then(function (response) {
            //console.log(response)
            _this.item = response.data
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      reset() {
        let _this = this
        _this.item.guid = null
        _this.item.userId = null
        _this.item.userName = null
        _this.item.nickName = null
        _this.item.password = null
        _this.item.email = null
        _this.item.phoneNumber = null
        _this.item.closed = false
        _this.item.roles = []
      },
      //play(row) {
      //    this.nowAudioIndex = row.index;
      //},
      showDialog() {
        this.$refs.audioLib.show()
        this.loadData()
      },
      selectMaterial(item) {
        console.log(this.onSelected)
        if (this.onSelected) {
          this.onSelected(item)
        }
        this.$refs.audioLib.hide()
      },
      cancelSelect() {
        this.$refs.audioLib.hide()
      },
      audioLibonhide() {
        this.nowAudio = null;
        this.$refs.audioPlayer.load()
      },
      rowSelected(item) {
        this.nowAudio = item
      }

    },

  }
</script>

<style lang="css">
  .audioLib .audioPlay {
    height: 30px;
    /*vertical-align: bottom;*/
    /*margin: 5px 0 0;*/
    max-width: 100%;
  }

  .audioLib .c_du {
    width: 100px;
  }
</style>
