<template>
  <SimpleModal ref="selectMapPoint" @Show="showMap=true" @Hide="showMap=false" :zIndex="1040">
    <div style="height:calc(100vh - 45px);width:calc(100vw - 50px);overflow:hidden;position:relative;">
      <template v-if="showMap">
        <MapSelectedPoint :mapConfig="mapConfig" @submit="submit" :markerPoint="markerPoint" :citycode="citycode">
        </MapSelectedPoint>
      </template>
      <div class="text-shadow" style="position:absolute;top:0;right:0;padding:5px;cursor:pointer;" @click="$refs.selectMapPoint.show=false">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </SimpleModal>
</template>
<script>
  import MapSelectedPoint from './MapSelectedPoint'
  import SimpleModal from '../SimpleModal'
  export default {
    components: {
      SimpleModal,
      MapSelectedPoint,
    },
    props: {
      mapConfig: {},
      markerPoint: Object,
      citycode: Number,
    },
    data: () => {
      return {
        showMap: false,
      }
    },
    methods: {
      submit(e) {
        this.$emit('submit', e)
        this.$refs.selectMapPoint.show = false
      },
      showModal() {
        this.$refs.selectMapPoint.show = true
      }
    }
  }
</script>
<style lang="css" scoped>
</style>
