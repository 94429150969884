import * as types from '../mutation-types'

const state = {
    count: 0,
    main: []
}

const mutations = {
    [types.FETCH_MESSAGE](state, messages) {
        state.main = messages
    },
    [types.FETCH_MESSAGE_COUNT](state, count) {
        state.count = count
    }
}

export default {
    state,
    mutations
}
