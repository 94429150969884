<template lang="html">
  <!-- Modal -->
  <div class="input-group  has-validation">
    <select class="custom-select input-group-prepend" v-model:value="$v.areas.province.$model" @change="changeProvince(areas.province)" :class="{ 'is-invalid': $v.areas.province.$error&&level>=1 }">
      <optgroup v-if="areas.provincesLoading" label="加载中..."></optgroup>
      <template v-else>
        <option :value="null">--请选择--</option>
        <option v-for="i in areas.provinces" :value="i.id">{{i.fName}}</option>
      </template>
    </select>
    <select class="custom-select input-group-append" v-model:value="$v.areas.city.$model" @change="changeCity(areas.city)" :class="{ 'is-invalid': $v.areas.city.$error&&level>=2 }">
      <optgroup v-if="areas.citysLoading" label="加载中..."></optgroup>
      <template v-else>
        <option :value="null">--请选择--</option>
        <option v-for="i in areas.citys" :value="i.id">{{i.fName}}</option>
      </template>
    </select>
    <select class="custom-select" v-model:value="$v.areas.district.$model" @change="changeDistrict(areas.district)" :class="{ 'is-invalid': $v.areas.district.$error&&level>=3 }">
      <optgroup v-if="areas.districtsLoading" label="加载中..."></optgroup>
      <template v-else>
        <option :value="null">--请选择--</option>
        <option v-for="i in areas.districts" :value="i.id">{{i.fName}}</option>
      </template>
    </select>
    <div class="invalid-feedback">
      请选择<span v-if="$v.areas.province.$error&&level>=1">省(直辖市)</span><span v-else-if="$v.areas.city.$error&&level>=2">市</span><span v-else-if="$v.areas.district.$error&&level>=3">县/区</span><span v-else>地区</span>
    </div>
  </div>
</template>

<script>
  import { required, minLength, maxLength, decimal, helpers } from 'vuelidate/lib/validators'
  export default {
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        required: true
      },
      level: {
        default: 0
      }
    },
    validations: {
      areas: {
        province: {
          required,
        },
        city: {
          required,
        },
        district: {
          required,
        },
      },
    },
    mounted() {
      let areaint = this.value
      console.log('AreasCode', areaint)
      if (areaint > 100000 && areaint < 900000) {
        var province = Math.floor(areaint / 10000) * 10000;
        console.log(province);
        this.getProvince(province);
        var city = Math.floor(areaint / 100) * 100;
        console.log(city);
        this.getCity(province, city);
        this.getDistrict(city, areaint);
      } else {
        this.getProvince();
      }
    },
    data() {
      return {
        areas: {
          province: null,
          provincesLoading: false,
          provinces: null,
          city: null,
          citysLoading: false,
          citys: null,
          district: null,
          districtsLoading: false,
          districts: null
        },
      }
    },
    computed: {
    },
    methods: {
      changeProvince(province) {
        this.$v.areas.province.$touch()
        this.$v.areas.city.$touch()
        this.$v.areas.district.$touch()
        if (this.level <= 1) {
          this.$emit('change', province);
        } else {
          this.$emit('change', null);
        }
        this.getCity(province)
      },
      changeCity(city) {
        this.$v.areas.city.$touch()
        this.$v.areas.district.$touch()
        if (this.level <= 2) {
          this.$emit('change', city);
        } else {
          this.$emit('change', null);
        }
        this.getDistrict(city)
      },
      changeDistrict(district) {
        this.$v.areas.district.$touch()
        if (this.level <= 3) {
          if (district) {
            this.$emit('change', district);
          }
        } else {
          this.$emit('change', null);
        }
      },
      getProvince(id) {
        this.areas.provincesLoading = true
        this.$axios.get('/Api/Areas')
          .then((response) => {
            this.areas.provinces = response.data;
            if (id != null) {
              this.areas.province = id;
            } else {
              this.areas.province = null;
              this.getCity(this.areas.province);
            }
            this.areas.provincesLoading = false
          })
          .catch((error) => {
            console.error(error)
            this.areas.provincesLoading = false
          })
      },
      getCity(pid, id) {
        if (pid != null) {
          this.areas.citysLoading = true
          this.$axios.get('/Api/Areas?ParentId=' + pid)
            .then((response) => {
              this.areas.citys = response.data;
              if (id != null) {
                this.areas.city = id;
              } else {
                this.areas.city = null;
                this.getDistrict(this.areas.city);
              }
              this.areas.citysLoading = false
            })
            .catch((error) => {
              console.error(error)
              this.areas.citysLoading = false
            })
        }
        else {
          this.areas.city = null;
          this.areas.citys = []
          this.getDistrict(this.areas.city);
        }
      },
      getDistrict(pid, id) {
        if (pid != null) {
          this.areas.districtsLoading = true
          this.$axios.get('/Api/Areas?ParentId=' + pid)
            .then((response) => {
              this.areas.districts = response.data;
              if (id != null) {
                this.areas.district = id;
              } else {
                this.areas.district = null;
              }
              this.areas.districtsLoading = false
            })
            .catch((error) => {
              console.error(error)
              this.areas.districtsLoading = false
            })
        }
        else {
          this.areas.district = null;
          this.areas.districts = []
        }
      },

    }
  }
</script>

<style lang="css" scoped>
</style>
