<template>
  <div class="badge">
    <div class="count">
      {{ count }}
    </div>
    <slot />
  </div>
</template>
<script>
  export default {
    props: ['count'],
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>
<style scoped>
  .badge {
    display: inline-block;
    position: relative;
  }

    .badge .count {
      position: absolute;
      right: -0.4rem;
      top: -0.4rem;
      min-width: 1rem;
      padding: 0 0.3rem;
      border-radius: 0.5rem 0.5rem;
      color: #fff;
      background-color: #f00;
      line-height: 1rem;
      font-size: 0.8rem;
    }
</style>
