<template>
  <div class="bingmap">
    <div :id="'bingMap_'+randid" class="map" />
  </div>
</template>
<script>
  import gpsCorrect from '../../libs/gpsCorrect.js'
  export default {
    props: {
      mapConfig: {
        type: Object,
        default: () => {
          return {
            //center: [117.000923, 36.675807],
            //zoom: 11,
          }
        }
      },
      ToolBars: {
        type: Boolean,
        default: true
      },
      onMapReady: Function,
    },
    data: function () {
      return {
        randid: Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36),
        bingMap: null,
        funcNameList: [],
        markers: {},
        layers: {},
        infoWindows: {},
        markerClusterer: null,
        //AMap_Driving: null,
        BingMapApiKey: process.env.BingMapApiKey || window.global.BINGMAP_API_KEY,
      }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
      // 已载入高德地图API，则直接初始化地图
      if ((window.Microsoft || {}).Maps) {
        this.initMap()
        // 未载入高德地图API，则先载入API再初始化
      } else {
        this.$remoteLoad(`https://www.bing.com/api/maps/mapcontrol?callback=${this.getFuncName(this.initMap)}`).then(() => {
          //setTimeout(this.initMap, 300)
        })
      }
    },
    destroyed() {
      for (var i in this.funcNameList) {
        window[this.funcNameList[i]] = null
      }
      this.bingMap && this.bingMap.dispose()
    },
    methods: {
      initMap() {
        if (process.server) {
          return
        }
        console.log('bingMap_' + this.randid, Microsoft.Maps, document.getElementById('bingMap_' + this.randid, Microsoft.Maps))
        if (this.randid && document.getElementById('bingMap_' + this.randid, Microsoft.Maps) == null) {
          this.randid = Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36)
          setTimeout(this.initMap, 500)
          return
        }
        var navigationBarMode = Microsoft.Maps.NavigationBarMode
        const config = {
          credentials: this.BingMapApiKey,
          //center: new Microsoft.Maps.Location(36.064708, 103.835403),
          mapTypeId: Microsoft.Maps.MapTypeId.road,
          //zoom: 10,
          navigationBarMode: navigationBarMode.square,
          supportedMapTypes: [Microsoft.Maps.MapTypeId.road, Microsoft.Maps.MapTypeId.aerial],
          disableStreetside: true,
          ...this.mapConfig
        }
        const map = new Microsoft.Maps.Map('#bingMap_' + this.randid, config)
        this.bingMap = map
        if (this.onMapReady) {
          this.onMapReady(map)
        }
        this.$emit('MapReady', map)
      },
      setCenterLngLat(lng, lat, config) {
        console.log(`设置地图中心 lat:${lat} lng:${lng}`)
        var p = new Microsoft.Maps.Location(lat, lng)
        console.log(p)
        this.bingMap.setView({
          center: p,
          ...config
        })
        console.log(this.bingMap.getCenter())
      },
      addMark(name, markconfig) {
        var markcname
        if (name) {
          markcname = name
        } else {
          markcname = this.randid + Math.random().toString()
        }
        var marker = this.newMarker(markconfig)
        if (this.markers[markcname]) {
          this.bingMap.entities.remove(this.markers[markcname])
        }
        this.markers[markcname] = marker
        this.bingMap.entities.push(marker)
      },
      newMarker(markconfig) {
        console.log(markconfig)
        var p = markconfig.position
        var point = new Microsoft.Maps.Location(p.lat, p.lng)
        var pin = new Microsoft.Maps.Pushpin(point, {
          //title: 'Microsoft',
          //subTitle: 'City Center',
          //text: '1',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#6792f3"><path d="M113,18.83333c-36.40483,0 -65.91667,29.51183 -65.91667,65.91667c0,47.08333 65.91667,122.41667 65.91667,122.41667c0,0 65.91667,-75.33333 65.91667,-122.41667c0,-36.40483 -29.51183,-65.91667 -65.91667,-65.91667zM113,108.29167c-13.00442,0 -23.54167,-10.53725 -23.54167,-23.54167c0,-13.00442 10.53725,-23.54167 23.54167,-23.54167c13.00442,0 23.54167,10.53725 23.54167,23.54167c0,13.00442 -10.53725,23.54167 -23.54167,23.54167z"></path></g></g></svg>`,
          anchor: new Microsoft.Maps.Point(12, 39),
          ...markconfig
        })
        pin.extData = markconfig.extData
        for (var i in markconfig.on) {
          Microsoft.Maps.Events.addHandler(pin, i, markconfig.on[i])
        }
        return pin
      },
      addMarkerClusterer(markConfigs, config, name) {
        var layerName
        if (name) {
          layerName = name
        } else {
          layerName = this.randid + Math.random().toString()
        }
        this.NewMarkerClusterer(markConfigs, config, layerName).then((layer) => {
          if (this.layers[layerName]) {
            this.layers[layerName].clear()
            this.bingMap.layers.remove(this.layers[layerName])
            console.log('remove', layerName)
          }
          this.layers[layerName] = layer
          this.bingMap.layers.insert(layer)
        })
      },
      NewMarkerClusterer(markConfigs, config, name) {
        var markers = []
        for (var i in markConfigs) {
          var item = markConfigs[i]
          var marker = this.newMarker(item)
          markers.push(marker)
        }
        return new Promise((resolve, reject) => {
          Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
            //Create a ClusterLayer and add it to the map.
            var clusterLayer = new Microsoft.Maps.ClusterLayer(markers, {
              gridSize: 80,
              clusteredPinCallback: this.createCustomClusteredPin,
              ...config,
            })
            for (var i in config.on) {
              Microsoft.Maps.Events.addHandler(clusterLayer, i, config.on[i])
            }
            resolve(clusterLayer)
          })
        })
      },
      createCustomClusteredPin(cluster) {
        //Define variables for minimum cluster radius, and how wide the outline area of the circle should be.
        var minRadius = 12
        var outlineWidth = 7

        //Get the number of pushpins in the cluster
        var clusterSize = cluster.containedPushpins.length

        //Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
        var radius = Math.log(clusterSize) / Math.log(10) * 5 + minRadius

        //Default cluster color is red.
        var fillColor = 'rgba(255, 40, 40, 0.5)'

        if (clusterSize < 10) {
          //Make the cluster green if there are less than 10 pushpins in it.
          fillColor = 'rgba(20, 180, 20, 0.5)'
        } else if (clusterSize < 100) {
          //Make the cluster yellow if there are 10 to 99 pushpins in it.
          fillColor = 'rgba(255, 210, 40, 0.5)'
        }

        //Create an SVG string of two circles, one on top of the other, with the specified radius and color.
        var svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="', (radius * 2), '" height="', (radius * 2), '">',
          '<circle cx="', radius, '" cy="', radius, '" r="', radius, '" fill="', fillColor, '"/>',
          '<circle cx="', radius, '" cy="', radius, '" r="', radius - outlineWidth, '" fill="', fillColor, '"/>',
          '</svg>']

        //Customize the clustered pushpin using the generated SVG and anchor on its center.
        cluster.setOptions({
          icon: svg.join(''),
          anchor: new Microsoft.Maps.Point(radius, radius),
          textOffset: new Microsoft.Maps.Point(0, radius - 8) //Subtract 8 to compensate for height of text.
        })
      },

      customizeClusteredPin(cluster) {
        //Add click event to clustered pushpin
        Microsoft.Maps.Events.addHandler(cluster, 'click', this.clusterClicked)
      },
      clusterClicked(e) {
        console.log(e)
      },
      addInfoWindow(name, InfoWindowconfig) {
        var Infoname
        if (name) {
          Infoname = name
        } else {
          Infoname = this.randid + Math.random().toString()
        }
        var config = {
          //title: 'Map Center',
          //description: 'Seattle',
          ...InfoWindowconfig
        }
        var p = InfoWindowconfig.position
        var point = new Microsoft.Maps.Location(p.lat, p.lng)
        var infobox = new Microsoft.Maps.Infobox(point, config)
        if (this.infoWindows[Infoname]) {
          this.infoWindows[Infoname].setMap(null)
        }
        this.infoWindows[Infoname] = infobox
        console.log(infobox)
        infobox.setMap(this.bingMap)
      },
      closeInfoWindow(name) {
        if (this.infoWindows[name]) {
          this.infoWindows[name].close()
        }
      },
      transformLoc(lat, lng) {
        var p
        switch (this.bingMap.getMapTypeId()) {
          case Microsoft.Maps.MapTypeId.road:
            p = gpsCorrect.transform(lat, lng)
            break
          default:
            p = { lng: lng, lat: lat }
            break
        }
        return p
      },
      getlocation(config) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition((position) => {
            console.log(position)
            var p = this.transformLoc(position.coords.latitude, position.coords.longitude)
            this.addMark('here', {
              position: p,
              icon: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#5e93ec"><path d="M113,9.41667c-41.53692,0 -75.33333,33.79642 -75.33333,75.33333c0,30.43467 38.27404,84.31683 61.09533,113.72508c3.44179,4.43054 8.63038,6.97304 14.238,6.97304c5.60762,0 10.79621,-2.5425 14.24271,-6.97304c22.81658,-29.40825 61.09063,-83.29042 61.09063,-113.72508c0,-41.53692 -33.79642,-75.33333 -75.33333,-75.33333zM113,51.79167c10.40071,0 18.83333,8.43263 18.83333,18.83333c0,10.40071 -8.43262,18.83333 -18.83333,18.83333c-10.40071,0 -18.83333,-8.43263 -18.83333,-18.83333c0,-10.40071 8.43263,-18.83333 18.83333,-18.83333zM145.95833,111.83233c0,10.46663 -13.35754,20.001 -32.95833,20.001c-19.60079,0 -32.95833,-9.53437 -32.95833,-20.001v-6.13967c0,-3.76667 3.051,-6.81767 6.81767,-6.81767h52.28133c3.76667,0 6.81767,3.051 6.81767,6.81767z"></path></g></g></svg>`
            })
            //var loc = new Microsoft.Maps.Location(
            //  position.coords.latitude,
            //  position.coords.longitude)
            ////Add a pushpin at the user's location.
            //var pin = new Microsoft.Maps.Pushpin(loc);
            //map.entities.push(pin);

            //Center the map on the user's location.
            //this.bingMap.setView({ center: loc })
            resolve(p)
          }, reject)
        })
      },
      getFuncName(func, name) {
        var funcname = null
        if (name) {
          funcname = this.randid + Math.random().toString().substr(3, 8) + name
        } else {
          funcname = this.randid + Math.random().toString().substr(3, 8)
        }
        window[funcname] = func
        this.funcNameList.push(funcname)
        return funcname
      }
    },
  }
</script>
<style scoped>
  .bingmap {
    height: 100%;
    width: 100%;
  }

    .bingmap .map {
      height: 100%;
      width: 100%;
      line-height: normal;
    }
</style>
<style lang="css">
  /*.bingmap .bm_bottomLeftOverlay {
    display: none !important;
  }*/

  .bingmap .CopyrightContainer {
    display: none !important;
  }
</style>
