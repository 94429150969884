<template>
  <div class="MapSelectedPoint">
    <aMap ref="map" @MapReady="onMapReady" :mapConfig="mapConfig"></aMap>
    <div style="position:absolute;bottom:10px;right:10px;z-index:200">
      <button v-if="location&&!(location.lat == 0 && location.lng == 0)" class="btn btn-primary btn-sm mr-1" @click="backToSelected">回到选择点</button>
      <button class="btn btn-sm btn-primary mr-1" @click="resetLocation">重置选择点</button>
      <button class="btn btn-sm btn-primary" @click="submit">确认地点</button>
      <slot name="buttons"></slot>
    </div>
    <div id="`searchResult_${randid}`"></div>
  </div>
</template>
<script>
  import aMap from './AMap'
  import gpsCorrect from '../../libs/gpsCorrect.js'
  export default {
    scrollToTop: true,
    components: {
      aMap,
    },
    props: {
      mapConfig: {
        default: () => {
          return {
            zoom: 1,
          }
        }
      },
      markerPoint: Object,
      citycode: Number,
    },
    data: () => {
      return {
        randid: (Math.random() * 10000).toFixed(0),
        location: null,
        aMap: null,
        notchangemap: null,
      }
    },
    computed: {

    },
    created() {
      //if (!(this.markerPoint.lat == 0 && this.markerPoint.lng == 0)) {
      //    this.notchangemap = true
      //} else {
      //    this.notchangemap = !!this.citycode
      //}
    },
    mounted() {
    },
    watch: {
    },
    methods: {
      submit() {
        this.$emit('submit', this.location)
      },
      resetLocation() {
        if (this.markerPoint && !(this.markerPoint.lat == 0 && this.markerPoint.lng == 0)) {
          this.location = this.markerPoint
        } else {
          this.location = null
        }
        if (this.location) {
          var p = gpsCorrect.transform(this.location.lat, this.location.lng)
          this.$refs.map.addMark('markpoint', {
            position: [p.lng, p.lat],
          })
          this.$refs.map.setCenterLngLat(p.lng, p.lat)
        } else {
          this.$refs.map.removeMark('markpoint')
          this.mapSetCity(this.citycode)
        }
      },
      getCurrentPosition() {
        this.$refs.map.getlocation({ panToLocation: false }, (data) => {
          if (this.notchangemap) {
            this.notchangemap = false;
            return
          }
          var p = gpsCorrect.transform(data.lat, data.lng)
          this.$refs.map.setCenterLngLat(p.lng, p.lat)
        }).then((data) => {
          //var p = gpsCorrect.transform(data.lat, data.lng)
          //this.$refs.map.setCenterLngLat(p.lng, p.lat)
          //this.setMark()
          //console.log(data)
        })
      },
      onMapReady(aMap) {
        this.getCurrentPosition()
        aMap.on('touchstart', (event) => {
          this.touchstart = { event: event, time: Date.now() }
        })
        aMap.on('touchend', (event) => {
          if (this.touchstart.time - Date.now() < 300) {
            var x1 = this.touchstart.event.pixel.x
            var y1 = this.touchstart.event.pixel.y
            var x2 = event.pixel.x
            var y2 = event.pixel.y
            if (((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2)) < 100) {
              this.onClick(event)
            }
          }
        })
        aMap.on('click', (event) => {
          this.onClick(event)
        })
        this.aMap = aMap
        this.resetLocation()
      },
      onClick(event) {
        var p = gpsCorrect.restore(event.lnglat.getLat(), event.lnglat.getLng())
        this.location = p
        this.setMark()
      },
      setMark() {
        console.log('setMark')
        if (this.location) {
          var p = gpsCorrect.transform(this.location.lat, this.location.lng)
          this.$refs.map.addMark('markpoint', {
            position: [p.lng, p.lat],
          })
          this.$refs.map.setCenterLngLat(p.lng, p.lat)
        }
      },
      backToSelected() {
        var p = gpsCorrect.transform(this.location.lat, this.location.lng)
        this.$refs.map.setCenterLngLat(p.lng, p.lat)
      },
      search(txt) {
        AMap.service(["AMap.PlaceSearch"], function () {
          //构造地点查询类
          var placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            //city: "010", // 兴趣点城市
            citylimit: true,  //是否强制限制在设置的城市内搜索
            map: map, // 展现结果的地图实例
            panel: `searchResult_${this.randid}`, // 结果列表将在此容器中进行展示。
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });
          //关键字查询
          placeSearch.search(txt);
        });
      },
      mapSetCity(cityCode) {
        console.log('mapSetCity')
        var pcode = Math.floor(cityCode / 10000)
        switch (pcode) {
          case 11:
          case 12:
          case 31:
          case 50:
            break;
          default:
            if ((cityCode % 100) == 1) {
              cityCode--;
            }
            break;
        }
        //console.log('地图' + cityCode);
        if (this.aMap && cityCode) {
          this.aMap.setCity(cityCode);
          console.log('地图定位到' + cityCode);
        }
      },
    }
  }
</script>
<style lang="css" scoped>
  .MapSelectedPoint {
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
