<template>
  <div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    components: {
    },
    props: {
      config: {
        default: () => {
          return {
            type: 'driving'
          }
        }
      }
    },
    data: () => {
      return {
        AMapWebApiKey: process.env.AMapWebApiKey || window.global.AMAP_WEB_API_KEY,
        AMapApiUrls: {
          driving: 'https://restapi.amap.com/v3/direction/driving',
          walking: 'https://restapi.amap.com/v3/direction/walking',
          bicycling: 'https://restapi.amap.com/v3/direction/bicycling',
          transit: 'https://restapi.amap.com/v3/direction/transit/integrated',
          location: 'https://restapi.amap.com/v3/ip',
          urlNavigation: 'https://uri.amap.com/navigation'
        }
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      getGeoLocation(config) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (e) => {
              //console.log('手机定位', e)
              resolve({ lat: e.coords.latitude, lng: e.coords.longitude })
            },
            (e) => {
              this.ipLocation().then((res) => {
                var r = res.data.rectangle
                var ps = r.split(';')
                var p = [...ps[0].split(','), ...ps[1].split(',')]
                console.log(p)
                resolve({ lat: (Number(p[1]) + Number(p[3])) / 2, lng: (Number(p[0]) + Number(p[2])) / 2 })
              }).catch((err) => {
                reject(err)
              })
            },
            { timeout: 2000, ...this.getDatas(this.config, ['enableHighAccuracy', 'timeout', 'maximumAge',]) }
          )
        })
      },
      ipLocation() {
        var config = {
          params: {
            key: this.AMapWebApiKey,
            ...this.getDatas(this.config, ['ip', 'sig', 'output',])
          }
        }
        return axios.get(this.AMapApiUrls['location'], config)
      },
      navigation(start, end, ...ways) {
        switch (this.config.type) {
          case 'driving':
            return this.driving(start, end, ...ways)
            break
          default:
        }
      },
      driving(start, end, ...ways) {
        return new Promise((resolve, reject) => {
          console.log('walking', start, end)
          var config = {
            params: {
              key: this.AMapWebApiKey,
              origin: `${start.position.lng.toFixed(6)},${start.position.lat.toFixed(6)}`,
              destination: `${end.position.lng.toFixed(6)},${end.position.lat.toFixed(6)}`,
              waypoints: this.getWayPoints(ways),
              extensions: 'all',
              output: 'json',
              ...this.getDatas(this.config, ['originid', 'destinationid', 'origintype', 'destinationtype', 'strategy', 'strategy', 'avoidpolygons', 'avoidroad', 'province', 'number', 'cartype', 'ferry', 'roadaggregation', 'nosteps', 'sig', 'output', 'callback', 'extensions',])
            }
          }
          axios.get(this.AMapApiUrls['driving'], config).then((res) => {
            console.log(res)
            var r
            r = res
            resolve(r)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      walking(start, end, ...ways) {
        return new Promise((resolve, reject) => {
          console.log('walking', start, end)
          var config = {
            params: {
              key: this.AMapWebApiKey,
              origin: `${start.position.lng.toFixed(6)},${start.position.lat.toFixed(6)}`,
              destination: `${end.position.lng.toFixed(6)},${end.position.lat.toFixed(6)}`,
            }
          }
          axios.get(this.AMapApiUrls['walking'], config).then((res) => {
            console.log(res)
            var r
            r = res
            resolve(r)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      urlNavigation(start, end, ...ways) {
        var params = {
          from: `${start.position.lng.toFixed(6)},${start.position.lat.toFixed(6)},${start.title}`,
          to: `${end.position.lng.toFixed(6)},${end.position.lat.toFixed(6)},${end.title}`,
          mode: this.getMode(),
          coordinate: 'wgs84',
          ...this.getDatas(this.config, ['via', 'policy', 'src', 'callnative',])
        }
        location.href = this.AMapApiUrls['urlNavigation'] + this.makeParameters(params)
      },
      makeParameters(parameters) {
        var s = '?'
        for (var i in parameters) {
          s += `${i}=${parameters[i]}&&`
        }
        return s.substring(0, s.length - 2)
      },
      getMode() {
        var m = {
          walking: 'walk',
          driving: 'car',
          transit: 'bus',
          bicycling: 'ride',
        }
        return m[this.config.type] || 'car'
      },
      getWayPoints(ways) {
        var s = ''
        for (var i in ways) {
          s += `${ways[i].position.lng.toFixed(6)},${ways[i].position.lat.toFixed(6)};`
        }
        return s
      },
      getDatas(data, names) {
        var d = {}
        for (var i in data) {
          if (names.some((v) => v == i)) {
            d[i] = data[i]
          }
        }
        return d
      }
    }
  }
</script>
<style lang="css" scoped>
</style>
