export default function template(strings, ...keys) {
  return (item) => {
    if (!item) {
      item = {}
    }
    var str = ''
    for (var i in keys) {
      var key = item[keys[i]]
      if (!key) {
        key = keys[i]
      }
      str += strings[i] + key
    }
    return str + strings[strings.length - 1]
  }
}
