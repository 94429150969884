<template>
  <img v-if="(user||{}).avatarUrl&&!err" :style="getStyle(cssStyle)" :src="getUrl()" @error="err=true">
  <img v-else :style="getStyle(cssStyle)" src="/images/avatar-default.jpg">
</template>
<script>
  export default {
    name: 'Avatar',
    props: {
      user: {
        type: Object,
        default: () => {
          return {
          }
        }
      },
      size: {},
      cssStyle: {
      },
    },
    data() {
      return {
        err: false
      }
    },
    mounted() {
    },
    methods: {
      getUrl() {
        if (this.size <= 25) {
          return this.user.avatarUrl.replace('/0/0/0/0/', '/50/50/1/0/')
        } else if (this.size <= 50) {
          return this.user.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')
        } else if (this.size <= 100) {
          return this.user.avatarUrl.replace('/0/0/0/0/', '/200/200/1/0/')
        } else {
          return this.user.avatarUrl
        }
      },
      getStyle(style) {
        var r = { ...style }
        var nSize = Number(this.size)
        if (nSize != NaN) {
          r.width = nSize + 'px'
          r.height = nSize + 'px'
        } else {
          r.width = this.size
          r.height = this.size
        }
        return r
      }
    }
  }
</script>
<style scoped>
  img {
    border-radius: 50%;
  }
</style>
