<template>
  <div class="PanoLib">
    <SimpleModal ref="PanoLib" :onHide="clearList" backdrop="static">
      <div style="display:flex;flex-direction:column;position:relative;max-width:450px;height:calc(100vh - 22px);">
        <div style="flex:none;display:flex;justify-content:space-between;padding-bottom:5px">
          <h4 class="modal-title">选择目标</h4>
          <div style="display:flex" @click="$refs.PanoLib.show=false">
            <div style="padding:5px;cursor:pointer;">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
        <div style="flex:auto;display:flex;flex-direction:column;overflow-y: auto;max-height: 100%;overflow-x: hidden;">
          <div style="display:flex">
            <div class="input-group input-group-sm mb-1" style="flex:auto">
              <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
              <div class="input-group-append">
                <button type="button" class="btn btn-primary" v-on:click="search()">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
            <div style="flex:none">
              <div class="dropdown ml-1">
                <button ref="dropdown" class="btn btn-sm btn-default dropdown-toggle" type="button" :id="'dropdownMenuButton'+randid" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  筛选
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <!--<form v-if="!withType" class="dropdown-item p-1">
                      <div class="input-group input-group-sm ">
                          <div class="input-group-prepend">
                              <span class="input-group-text" style="font-weight:700">类型</span>
                          </div>
                          <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                              <option :value="null">所有</option>
                              <option v-for="i in types" v-bind:value="i.value">{{i.name}}</option>
                          </select>
                      </div>
                  </form>-->
                  <form class="dropdown-item p-1" v-if="categorysUrl">
                    <div class="input-group input-group-sm ">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="font-weight:700">分类</span>
                      </div>
                      <select class="form-control form-control-sm" v-model:value="query.category" v-on:change="search()" placeholder="分类">
                        <template v-if="categorys">
                          <option v-for="i in categorys" v-bind:value="i.text">{{i.text}}</option>
                        </template>
                        <optgroup v-else label="加载中…">
                        </optgroup>
                      </select>
                    </div>
                  </form>
                  <form class="dropdown-item p-1">
                    <div class="input-group input-group-sm ">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="font-weight:700">标签</span>
                      </div>
                      <select class="form-control form-control-sm" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                        <option :value="null">所有</option>
                        <optgroup v-if="privateTags" label="私有">
                          <option v-for="i in privateTags" v-bind:value="i.name">{{i.name}}</option>
                        </optgroup>
                        <optgroup v-if="publicTags" label="公共">
                          <option v-for="i in publicTags" v-bind:value="i.name">{{i.name}}</option>
                        </optgroup>
                        <optgroup v-else label="加载中…">
                        </optgroup>
                      </select>
                    </div>
                  </form>
                  <form class="dropdown-item p-1">
                    <div class="input-group input-group-sm" @dblclick="query.beginTime=null;search()">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="font-weight:700">起始</span>
                      </div>
                      <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                    </div>
                  </form>
                  <form class="dropdown-item p-1">
                    <div class="input-group input-group-sm" @dblclick="query.endTime=null;search()">
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="font-weight:700">结束</span>
                      </div>
                      <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                    </div>
                  </form>
                  <form class="form-control-sm text-nowrap p-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioHasGps1'+randid" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="true" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioHasGps1'+randid">有GPS</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioHasGps2'+randid" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="false" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioHasGps2'+randid">无GPS</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioHasGps3'+randid" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="null" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioHasGps3'+randid">不限</label>
                    </div>
                  </form>
                  <form class="form-control-sm text-nowrap p-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioPrivate1'+randid" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="true" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioPrivate1'+randid">私有</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioPrivate2'+randid" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="false" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioPrivate2'+randid">公开</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioPrivate3'+randid" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="null" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioPrivate3'+randid">不限</label>
                    </div>
                  </form>
                  <form class="form-control-sm text-nowrap p-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioFine1'+randid" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="true" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioFine1'+randid">精华</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioFine2'+randid" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="false" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioFine2'+randid">普通</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioFine3'+randid" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="null" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioFine3'+randid">不限</label>
                    </div>
                  </form>
                  <form class="form-control-sm text-nowrap p-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioFeatured1'+randid" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="true" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioFeatured1'+randid">精选</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioFeatured2'+randid" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="false" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioFeatured2'+randid">默认</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="'customRadioFeatured3'+randid" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="null" v-on:change="search()">
                      <label class="custom-control-label" :for="'customRadioFeatured3'+randid">不限</label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading" style="flex:auto;display:flex;text-align:center;justify-content:center">
            <div>
              <i class="fas fa-spinner fa-pulse"></i>
              <p>
                载入中…
              </p>
            </div>
          </div>
          <div v-else-if="list.length==0" style="flex:auto;display:flex;text-align:center;justify-content:center">
            <div>
              没有找到匹配项目
            </div>
          </div>
          <div v-else class="card-body p-0">
            <div class="row">
              <div class="col-12 col-sm-6" :class="{active:isSelected(i)}" v-for="i in list">
                <div class="small-box bg-gray" style="height: 150px;cursor:pointer;" v-bind:style="resizeimg(i.firstImage?i.firstImage.url:'')" @click="select(i)">
                  <div class="inner">
                    <h4 class="text-truncate">{{i.title}}</h4>

                    <h5>{{i.user.nickName}}</h5>
                  </div>
                  <div class="small-box-footer">
                    <span class="mx-1" title="观看"><i class="fas fa-eye"></i> {{i.viewCount}}</span>
                    <span class="mx-1" title="点赞"><i class="fas fa-thumbs-up"></i> {{i.praiseCount}}</span>
                    <span class="mx-1"><i class="fas fa-map-marker-alt" title="地图坐标" v-if="i.gpsLat!=0 || i.gpsLng!=0"></i></span>
                    <span class="mx-1" v-if="i.state<=1">{{i.status}}</span>
                    <span class="mx-1" v-if="i.private">私有</span>
                    <span class="mx-1" v-if="i.fine">精</span>
                    <span class="mx-1" v-if="i.featured"><i class="fas fa-star"></i></span>
                    <span class="mx-1">{{i.checkNote}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="flex:none;background-color:#fff;z-index:10;display:flex;align-items:flex-end">
          <paging :page-index="pager.pageIndex"
                  :total="pager.totalRecords"
                  :page-size="pager.pageSize"
                  :per-pages="3"
                  :show-go="true"
                  :prePageSize="pager.prePageSize"
                  :onPagerChange="pageChange">
            <button v-if="multiple" class="btn btn-primary btn-sm mb-1" @click="submit" style="white-space:nowrap">
              确定
            </button>
          </paging>
        </div>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
  import SimpleModal from './SimpleModal'
  import Qs from 'qs'
  export default {
    name: 'PanoLib',
    components: {
      SimpleModal
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      categorysUrl: String,
      libUrl: String,
      tagsUrl: String,
      params: Object,
      multiple: {
        type: Boolean,
        default: false,
      },
      value: {},
      //onSubmit: Function,
    },
    data: function () {
      return {
        randid: Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36),
        list: [],
        selectedList: [],
        publicTags: [],
        privateTags: [],
        categorys: [],
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          ...this.newQuery(), ...this.params
        },
        loading: false,
      }
    },
    computed: {
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      },
    },
    created() {
    },
    methods: {
      newQuery() {
        return {
          page: 1,
          pageSize: 12,
          tags: null,
          hasGps: null,
          private: null,
          fine: null,
          featured: null,
          beginTime: null,
          endTime: null,
          category:'默认分类',
          //sort: "Id",
          //order: "desc",
          //fields: 'Title',
          //key: null,
          states: [2, 3, 4]
        }
      },
      getPanos(request) {
        if (this.loading) {
          return
        }
        this.loading = true
        console.log(request)
        this.$axios.get(this.libUrl, request)
          .then((res) => {

            this.list = res.data.data;
            this.pager.pageIndex = res.data.pageIndex
            this.pager.pageSize = res.data.pageSize
            this.pager.totalPages = res.data.totalPages
            this.pager.totalRecords = res.data.totalRecords

            this.loading = false
          })
          .catch((res) => {
            this.loading = false
          })
      },
      loadData() {
        this.getPanos({
          params: this.query,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
      },
      loadCategorys() {
        if (this.categorysUrl) {
          this.$axios.get(this.categorysUrl, {})
            .then((response) => {
              //console.log(response)
              this.categorys = response.data
            })
            .catch((error) => {
              console.log(error)
            })
        }
      },
      loadTags() {
        var tagtype = '全景';
        if (this.tagsUrl) {
          this.$axios.get(this.tagsUrl, { params: { type: tagtype } })
            .then((response) => {
              //console.log(response)
              this.privateTags = response.data
            })
            .catch((error) => {
              console.log(error)
            })
        }
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      search() {
        this.query.page = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.page = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      sortingChanged(ctx) {
        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.query.sort = ctx.sortBy;
        this.query.order = ctx.sortDesc ? "desc" : "asc";
        this.loadData();
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
        }
      },
      showDialog() {
        this.$refs.PanoLib.show = true
        this.loadData()
        this.loadTags()
        this.loadCategorys()
        this.$nextTick(() => { $(this.$refs.dropdown).dropdown('hide') })
      },
      clearList() {
        this.selectedList = []
      },
      select(item) {
        if (this.multiple) {
          let list = [...(this.value || this.selectedList)]
          var has = false
          for (var i in list) {
            var t = list[i].guid == item.guid
            if (t) {
              list.splice(i, 1);
              has = true
              break;
            }
          }
          if (!has) {
            list.push(item);
          }
          if (this.value) {
            this.$emit('change', list)
          } else {
            this.selectedList = list
          }
        } else {
          this.submit(item)
        }
      },
      close() {
        this.$refs.PanoLib.show = false
      },
      cancel() {
        this.close()
      },
      isSelected(item) {
        var t = (this.value || this.selectedList).filter(i => i.guid == item.guid).length > 0
        if (t) {
          return true;
        } else {
          return false;
        }
      },
      submit(item) {
        if (this.multiple) {
          this.$emit('submit', this.value || this.selectedList)
        } else {
          this.$emit('submit', item)
        }
        this.close()
      },
    },
    watch: {
      params(value) {
        this.query = {
          ...this.newQuery(), ...value
        }
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
  .PanoLib .Modal {
    z-index: 1050;
  }

/*  .PanoLib .active .small-box > .small-box-footer {
    background: rgba(0, 0, 255, 0.50);
  }*/
  .PanoLib .active .small-box {
    border: solid 4px orange;
  }

  .PanoLib .small-box .inner h4 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1.2rem;
    text-align: left;
  }

  .PanoLib .small-box .inner h5 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1.1rem;
    text-align: left;
  }

  .PanoLib .small-box .inner h6 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1rem;
    text-align: left;
  }

  .PanoLib .small-box .small-box-footer {
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
</style>
