<template>
  <CollapedBox class="bedSelect" collapseIcon expandCard>
    <template slot="header">
      床选择
    </template>
    <div>
      <table class="table table-hover">
        <tbody>
          <tr v-for="item in selected">
            <td>
              {{item.name}}:
            </td>
            <td class="text-center">
              {{selected[findInedxInList(selected,item)].quantity}} 张
            </td>
            <td class="text-right">
              <button class="btn btn-default btn-sm mx-1" @click="reduceone(item)"><i class="fas fa-minus-square"></i></button>
              <button class="btn btn-default btn-sm mx-1" @click="addone(item)"><i class="fas fa-plus-square"></i></button>
              <button class="btn btn-default btn-sm mx-1" @click="remove(item)"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bedAllList">
      <ul class="list-unstyled">
        <li class="d-inline-block" v-for="item in checkList">
          <button class="btn btn-sm mx-1" :class="{'btn-default':!isSelected(selected,item) ,'btn-primary':isSelected(selected,item)}" @click="addone(item)">
            {{item.name}}
            <span v-if="isSelected(selected,item)">
              &nbsp;x {{selected[findInedxInList(selected,item)].quantity}}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </CollapedBox>
</template>

<script>
  import CollapedBox from './CollapedBox'

  export default {
    name: 'bedSelect',
    components: {
      CollapedBox,
    },
    props: {
      checkprop: String,
      checkList: Array,
      selected: Array,
      onChange: Function,
    },
    data: function () {
      return {
        slist: [],
      };
    },
    methods: {
      //changeable(list) {
      //  var r = list.filter(item => item.checked).map(item => item.value)
      //  this.onChange(r)
      //},
      isSelected(slist, item) {
        var _this = this;
        if (!(slist && slist.filter))
          return false;
        var t = _this.checkprop ? slist.filter(i => i[_this.checkprop] == item[_this.checkprop]).length > 0 : slist.filter(i => i == item).length > 0
        if (t) {
          return true;
        } else {
          return false;
        }
      },
      //changeselect(item) {
      //  if (this.onChange) {
      //    let list = [...this.selected]
      //    var has = false
      //    for (var i in list) {
      //      var t = this.checkprop ? list[i][this.checkprop] == item[this.checkprop] : list[i] == item
      //      if (t) {
      //        list.splice(i, 1);
      //        has = true
      //        break;
      //      }
      //    }
      //    if (!has) {
      //      list.push(item);
      //    }
      //    this.onChange(list)
      //  }
      //},
      reduceone(item) {
        var selected = [...this.selected]
        var index = this.findInedxInList(selected, item)
        if (index == null) {
          return
        } else {
          selected[index].quantity--;
          if (selected[index].quantity <= 0) {
            selected.splice(index, 1);
          }
          this.onChange(selected)
        }
      },
      remove(item) {
        var selected = [...this.selected]
        var index = this.findInedxInList(selected, item)
        if (index == null) {
          return
        } else {
          selected.splice(index, 1);
          this.onChange(selected)
        }
      },
      addone(item) {
        var selected = [...this.selected]
        var index = this.findInedxInList(selected, item)
        if (index == null) {

          var i = selected.push(item) - 1
          console.log(i, selected);
          selected[i].quantity = 1;
        } else {
          selected[index].quantity++;
        }
        this.onChange(selected)
      },
      findInedxInList(list, item) {
        for (var i in list) {
          if (this.checkprop ? list[i][this.checkprop] == item[this.checkprop] : list[i] == item) {
            return i;
          }
        }
        return null;
      }
    },
    created() {
      var _this = this;
    },
    watch: {
      checkList: function (val, oldval) {
      },
      selected: function (val, oldval) {
        //console.log(val);
        if (val)
          this.slist = [...val]
      },

    },

  }
</script>

<style>
</style>
