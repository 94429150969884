<template lang="html">
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
                next: null,
                remove: null,
            }
        },
        created() {

        },
        mounted() {
            window.onbeforeunload = function (e) {
                var e = e || window.event;
                if (e)
                    e.returnValue = "确定离开当前页面吗？";
                return "确定离开当前页面吗？";
            }
            this.remove = this.$router.beforeResolve((to, from, next) => {
                const answer = window.confirm('确定离开当前页面吗？')
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            })
        },
        destroyed() {
            this.remove()
            window.onbeforeunload = null
        },
        methods: {
            hide() {
                this.next(false)
                this.$refs.stopleave.hide();
            },
            close() {
                this.next()
            },
        },
        components: {
        },
        //beforeRouteLeave(to, from, next) {
        //    console.log('tryleave')
        //    this.next = next;
        //    this.$refs.stopleave.show();
        //}
    }
</script>

<style lang="css">
</style>
