<template>
  <div class="skin" :class="{hideTool:hideTool}">
    <Scale ref="Scale" :zoom="1" :mobile-only="true">
      <template v-if="pano">
        <div class="userA" style="pointer-events:none">
          <div v-if="pano.isPro&& pano.logo">
            <div class="text-center">
              <img style="max-width:100%" :src="pano.logo.url">
            </div>
          </div>
          <div v-else-if="!(pano.isPro&&pano.hideAvatar)" class="avatar" style="pointer-events:auto;max-width: 85px;">
            <div class="text-center">
              <nuxt-link v-if="pano&&pano.user" :to="{name:'UserHome',params:{userName:pano.user.userName}}">
                <Avatar :user="pano.user" :size="50"></Avatar>
                <!--<img v-if="pano.user.avatarUrl" :src="pano.user.avatarUrl.replace('/0/0/0/0/', '/200/200/1/0/')">
                <img v-else src="/images/avatar-default.jpg">-->
              </nuxt-link>
            </div>
            <div id="nickName" class="text-center text-truncate text-shadow">
              <nuxt-link v-if="pano&&pano.user" style="color:white" :to="{name:'UserHome',params:{userName:pano.user.userName}}">
                {{ pano.user.nickName }}
              </nuxt-link>
            </div>
          </div>
          <div v-if="!(pano.isPro&&pano.hideCount)" class="">
            <div style="margin-top:10px;line-height:2rem;font-size:1rem">
              <span class="text-shadow" title="观看"><i class="fas fa-eye" /> {{ pano.viewCount }}</span><br>
              <span class="text-shadow" title="点赞" style="pointer-events:auto" @click="PraiseCount"><i class="fas fa-thumbs-up" /> {{ pano.praiseCount }}</span>
            </div>
          </div>
        </div>
        <div v-if="pano.fine&&!pano.isPro" class="fine" style="pointer-events:none">
          精
        </div>
        <div id="toolbar" class="toolbar text-shadow" :style="toolbarStyle()">
          <slot name="Comments"></slot>
          <ul id="links" class="links">
            <template v-if="pano.isPro">
              <li v-for="i in pano.links">
                <a v-if="i.type=='Url'" :title="i.title" :href="i.link" target="_blank">
                  <div>
                    <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
                      <div style="display:flex;pointer-events:none;">
                        <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M43 16V42H5V16V16" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M5 4H43V16H5V4Z" fill="none" stroke="#333" stroke-width="2" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11 12C12.1046 12 13 11.1046 13 10C13 8.89543 12.1046 8 11 8C9.89543 8 9 8.89543 9 10C9 11.1046 9.89543 12 11 12Z" fill="#333" /><path fill-rule="evenodd" clip-rule="evenodd" d="M17 12C18.1046 12 19 11.1046 19 10C19 8.89543 18.1046 8 17 8C15.8954 8 15 8.89543 15 10C15 11.1046 15.8954 12 17 12Z" fill="#333" /><path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C24.1046 12 25 11.1046 25 10C25 8.89543 24.1046 8 23 8C21.8954 8 21 8.89543 21 10C21 11.1046 21.8954 12 23 12Z" fill="#333" /></svg>
                      </div>
                    </div>
                  </div>
                  {{i.title}}
                </a>
                <a v-else-if="i.type=='Email'" :title="i.title" :href="'mailto:'+i.link">
                  <div>
                    <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
                      <div style="display:flex;pointer-events:none;">
                        <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M4 39H44V24V9H24H4V24V39Z" fill="none" stroke="#000" stroke-width="1" stroke-linejoin="bevel" /><path d="M4 9L24 24L44 9" stroke="#000" stroke-width="1" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M24 9H4V24" stroke="#000" stroke-width="1" stroke-linecap="round" stroke-linejoin="bevel" /><path d="M44 24V9H24" stroke="#000" stroke-width="1" stroke-linecap="round" stroke-linejoin="bevel" /></svg>
                      </div>
                    </div>
                  </div>
                  {{i.title}}
                </a>
                <a v-else-if="i.type=='Tel'" :title="i.title" :href="'tel:'+i.link">
                  <div>
                    <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
                      <div style="display:flex;pointer-events:none;">
                        <svg width="16" height="16" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" transform="translate(3 1)" fill="white" fill-opacity="0.01" /><rect x="3" y="1" width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M44.7796 21.6066C45.0324 19.9108 44.9495 18.1747 44.5309 16.5054C43.978 14.3002 42.8392 12.2118 41.1147 10.4873C39.3902 8.76281 37.3018 7.62409 35.0967 7.07115C33.4274 6.65257 31.6912 6.56967 29.9954 6.82245" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M37.1897 20.8035C37.4605 18.9869 36.8967 17.0699 35.4983 15.6715C34.0998 14.2731 32.1829 13.7092 30.3663 13.98" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M17.3757 9.79423C18.1022 9.79423 18.7716 10.1883 19.1243 10.8235L21.5707 15.2303C21.891 15.8073 21.9061 16.5052 21.6109 17.0955L19.2541 21.8091C19.2541 21.8091 19.9371 25.3206 22.7955 28.179C25.654 31.0374 29.1536 31.7086 29.1536 31.7086L33.8665 29.3522C34.4572 29.0568 35.1556 29.0721 35.7328 29.393L40.1521 31.85C40.7868 32.2028 41.1803 32.8719 41.1803 33.598L41.1803 38.6715C41.1803 41.2552 38.7804 43.1213 36.3323 42.2952C31.3044 40.5987 23.4997 37.3685 18.5529 32.4216C13.606 27.4748 10.3758 19.6701 8.67929 14.6422C7.85326 12.1941 9.71935 9.79423 12.303 9.79423L17.3757 9.79423Z" fill="none" stroke="#333" stroke-width="2" stroke-linejoin="round" /></svg>
                      </div>
                    </div>
                  </div>
                  {{i.title}}
                </a>
                <a v-else-if="i.type=='Location'&&(i.gpsLat!=0||i.gpsLng!=0)" :title="i.title" @click="linkShowView(i)">
                  <div>
                    <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
                      <div style="display:flex;pointer-events:none;">
                        <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01" /><path d="M24 20C28.4183 20 32 16.4183 32 12C32 7.58172 28.4183 4 24 4C19.5817 4 16 7.58172 16 12C16 16.4183 19.5817 20 24 20Z" fill="none" stroke="#333" stroke-width="2" stroke-linejoin="round" /><path d="M24 20V38" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M16 32H12L4 44H44L36 32H32" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
                      </div>
                    </div>
                  </div>
                  {{i.title}}
                </a>
                <a v-else-if="i.type=='Image'" :title="i.title" @click="linkShowView(i)">
                  <div>
                    <div style="background-color:#fff;border-radius:50px;display:inline-block;padding:3px">
                      <div style="display:flex;pointer-events:none;">
                        <svg width="16" height="16" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><rect width="48" height="48" fill="white" fill-opacity="0.01" stroke-linejoin="round" stroke-width="2" stroke="none" fill-rule="evenodd" /><g transform="translate(6.000000, 6.000000)"><rect x="0" y="0" width="14" height="11" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /><rect x="22" y="25" width="14" height="11" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /><rect x="22" y="0" width="14" height="17" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /><rect x="0" y="19" width="14" height="17" stroke-linejoin="round" stroke-width="2" stroke="#333" fill="none" fill-rule="nonzero" /></g></g></svg>
                      </div>
                    </div>
                  </div>
                  {{i.title}}
                </a>
              </li>
            </template>
          </ul>
          <div style="height:5px"></div>
          <slot name="Sences"></slot>
        </div>
        <div class="rightToolbar text-shadow text-center" :style="rightToolbarStyle()">
          <template v-if="showMoreIcon">
            <slot name="hideIcons"></slot>
            <div title="WebVR" @click="enterVR">
              <i class="fas fa-vr-cardboard" />
            </div>
            <div title="陀螺仪" @click="changePanoInfo({gyrpState:!panoInfo.gyrpState})">
              <i class="icon icon-gyroscope drop-shadow" :class="{disabledState:!panoInfo.gyrpState}" />
            </div>
            <!--<div title="清空画面" @click="changePanoInfo({cleanState:!panoInfo.cleanState})">
              <i class="fas fa-magic" :class="{disabledState:!panoInfo.cleanState}" />
            </div>-->
            <!--<a title="联系作者" class="text-shadow" :href="chatUrl">
              <i class="fas fa-comments" />
            </a>-->
            <div title="截图分享" @click="showScreenshot">
              <i class="fas fa-camera" />
            </div>
          </template>
          <template v-else>
            <div title="显示更多按钮" @click="showMoreIcon=true">
              <i class="fas fa-ellipsis-v" />
            </div>
          </template>
          <slot name="icons"></slot>
          <template v-if="pano&&pano.audio">
            <div v-if="!panoInfo.audioPlayed" title="播放" @click="changePanoInfo({audioPlayed:true})">
              <i class="fas" :class="volumeON" />
            </div>
            <div v-else title="暂停" @click="changePanoInfo({audioPlayed:false})">
              <i class="fas" :class="volumeOFF"></i>
            </div>
          </template>
          <!--<div title="分享" @click="ShareQrcode">
            <i class="fas fa-share-alt" />
          </div>-->
          <div title="点赞" @click="PraiseCount">
            <i class="fas fa-thumbs-up" :class="{disabled:!!Praiseed}" />
          </div>
          <!--<div title="评论" >
            <i class="fas fa-comment-alt"/>
          </div>-->
          <div v-if="(pano.exif&&!pano.isPro)||pano.text" title="信息" @click="panoComponent.$refs.panoinfo.show = true">
            <i class="fas fa-info-circle" />
          </div>
          <div v-if="this.pano.exif && (this.pano.exif.gpsLat != 0 || this.pano.exif.gpsLng != 0)" title="地图" @click="panoComponent.$refs.mapModal.show = true">
            <i class="fas fa-map-marked-alt" />
          </div>
        </div>
        <!--<div class="panoTitle">
          <h1 class="text-center text-truncate" style='line-height:1.5;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient:vertical;'>
            {{ pano.title }}
          </h1>
        </div>-->
        <transition name="panoTitle" @after-enter="panoTitleafterEnter">
          <div v-if="showTitle" class="text-shadow" style="position:absolute;top:30%;left:0;right:0;text-align:center;pointer-events:none">{{ pano.title }}</div>
        </transition>
      </template>
      <slot name="Scaled"></slot>
    </Scale>
    <div id="logo" class="logo">
      <nuxt-link v-if="!(pano.isPro&&pano.hideLogo)" to="/">
        <img src="/images/logo.png">
      </nuxt-link>
    </div>
    <div style="pointer-events:auto">
      <SimpleModal ref="linkView" :boxClass="{mapModal:(link||{}).type=='Location',infoBox:(link||{}).type=='Image'}" :zIndex="1040">
        <div style="position:relative">
          <template v-if="link">
            <template v-if="link.type=='Location'">
              <div style="width:90vw;height:90vh;position:relative">
                <!--<MapView :mark="{lat:link.gpsLat,lng:link.gpsLng}" :dragable="true" height="100%"></MapView>-->
                <!--<PanoLeafletMap ref="map" :currentItem="link" :currentLoc="{lat:link.gpsLat,lng:link.gpsLng}" :currentItemConfig="linkItemConfig" :zoom="16" :autoZoom="false" :mapCenter="{lat:link.gpsLat,lng:link.gpsLng}"></PanoLeafletMap>-->
                <PanoLeafletMap></PanoLeafletMap>
              </div>
            </template>
            <template v-if="link.type=='Image'">
              <div>
                <img :src="link.image.url" />
              </div>
            </template>
          </template>
          <i style="position:absolute;top:3px;right:3px;padding:5px;z-index: 1000;" class="fas fa-times text-shadow" @click="$refs.linkView.show=false" title="关闭" />
        </div>
      </SimpleModal>
      <SimpleModal ref="share" boxClass="infoBox">
        <div v-show="$refs.share&&$refs.share.show" class="share">
          <img :src="qrcodeurl" />
          <div class="text-center">
            手机扫描分享<br />
            微信分享请点击右上角的“…”
          </div>
        </div>
      </SimpleModal>
    </div>
  </div>
</template>
<script>
  import Scale from './Scale'
  import SimpleModal from './SimpleModal'
  import MapView from './Map/MapView'
  import PanoLeafletMap from './Map/PanoLeafletMap'
  import MapPopupInfoEmpty from './Map/MapPopupInfo-Empty'
  import Avatar from './Avatar'
  export default {
    scrollToTop: true,
    components: {
      Scale,
      SimpleModal,
      MapView,
      PanoLeafletMap,
      Avatar,
    },
    model: {
      prop: 'panoInfo',
      event: 'change'
    },
    props: {
      chatUrl: {
        type: String,
        default: () => {
          return ''
        }
      },
      pano: {
        type: Object,
        default: () => {
          return {}
        }
      },
      panoInfo: {
        type: Object,
        default: () => {
          return {
            gyrpState: false,
            cleanState: false,
          }
        }
      },
      volumeON: { //临时控制背景音乐图标
        type: String,
        default: () => {
          return 'fas fa-volume-down'
        }
      },
      volumeOFF: {
        type: String,
        default: () => {
          return 'fas fa-volume-mute'
        }
      },
      praise: {},
      makeViewCount: {},
      //panoComponent: {
      //  required: true
      //}
    },
    inject: ['panoComponent'],
    data() {
      return {
        sceneVars: {
          'view.keep': true,
          keep: true,
          //'view.fovmin': 70,
          //'view.fovmax': 150,
          //'view.maxpixelzoom': 1.0,
          //'view.fovtype': 'MFOV',
        },
        hideTool: true,
        linkItemConfig: {
          key: 'id',
          popup: MapPopupInfoEmpty,
          popupConfig: {
            on: {
              导航: (e) => {
                window.navigation({ lat: e.lat, lng: e.lng, title: e.title })
              }
            },
            width: '200px'
          },
        },
        firstHideTool: true,
        hideToolTimer: null,
        audioPlayed: false,
        Praiseed: 0,
        qrcodeurl: null,
        showMoreIcon: true,
        showTitle: false,
        link: null,
        //gyrpState: false,
        //cleanState: false,
      }
    },
    computed: {
    },
    watch: {
      pano(val, oldval) {
        setTimeout(this.ViewCount, 10)
        this.Praiseed = 0
        this.showTitle = true
      },
    },
    created() {
    },
    mounted() {
      this.ViewCount()
      this.showTitle = true
    },
    destroyed() {
    },
    methods: {
      linkShowView(item) {
        this.link = item
        this.$refs.linkView.show = true
      },
      panoTitleafterEnter() {
        setTimeout(() => {
          this.showTitle = false
        }, 2000)
      },
      rightToolbarStyle() {
        var bottom = 45
        bottom = (document.getElementById('logo') || {}).offsetHeight + (document.getElementById('toolbar') || {}).offsetHeight - (document.getElementById('links') || {}).offsetHeight + 20
        return { bottom: `${bottom}px` }
      },
      toolbarStyle() {
        var bottom = 45
        if (this.hideTool) {
          bottom = -(document.getElementById('toolbar') || {}).offsetHeight - 20
        } else {
          bottom = (document.getElementById('logo') || {}).offsetHeight + 10
          if (bottom < 30) {
            bottom = 30
          }
        }
        return { bottom: `${bottom}px` }
      },
      showScreenshot() {
        this.panoComponent.showScreenshot()
      },
      changePanoInfo(obj) {
        this.$emit('change', { ...this.panoInfo, ...obj })
      },
      ShareQrcode() {
        this.$refs.share.show = true
        this.qrcodeurl = `${process.env.baseUrl}/Api/QRCode/?url=${location.protocol}//${location.host}${location.pathname}`
      },
      PraiseCount() {
        if (this.Praiseed != 0) {
          return
        }
        this.Praiseed = 1
        this.$emit('PraiseCount')
        if (this.praise) {
          this.praise().then(() => {
            this.Praiseed = 2
          }).catch((err) => {
            this.Praiseed = 0
          })
          return
        }
        this.$axios.post('/Api/Panos/PraiseCount/' + this.pano.guid).then((res) => {
          this.pano.praiseCount = res.data.praiseCount
          this.pano.viewCount = res.data.viewCount
          this.pano.shareCount = res.data.shareCount
          this.Praiseed = 2
          this.$axios.post('/Api/Comments/Praise/' + this.pano.guid).then((res) => {
          }).catch((err) => {
          })
        }).catch((err) => {
          this.Praiseed = 0
        })
      },
      ViewCount() {
        if (this.makeViewCount) {
          this.makeViewCount()
          return
        }
        this.$axios.post('/Api/Panos/ViewCount/' + this.pano.guid).then((res) => {
          this.pano.praiseCount = res.data.praiseCount
          this.pano.viewCount = res.data.viewCount
          this.pano.shareCount = res.data.shareCount
        })
      },
      ShareCount() {
        this.$axios.post('/Api/Panos/ShareCount/' + this.pano.guid).then((res) => {
          this.pano.praiseCount = res.data.praiseCount
          this.pano.viewCount = res.data.viewCount
          this.pano.shareCount = res.data.shareCount
        })
      },
      onXmlComplete() {
        //this.unsethotspots()
        if (this.pano.littlePlanetIntro) {
          this.littlePlanetIntro()
        } else {
          this.krpano.set('events[lp_events].onloadcomplete', `js(${this.panoComponent.getFuncName(() => {
            this.onPanoIntoComplete()
          })}())`)
        }
      },

      enterVR() {
        this.setHideTool()
        this.panoComponent.enterVR(() => {
          this.hideTool = false
        })
      },
      setHideTool() {
        this.hideTool = true
      },
      setShowTool() {
        this.firstHideTool = false
        if (this.hideToolTimer) {
          clearTimeout(this.hideToolTimer)
          this.hideToolTimer = null
        }
        this.hideTool = !this.hideTool
      },
      loadCompleted() {
        if (this.firstHideTool) {
          this.firstHideTool = false
          this.hideTool = false
          if (this.pano.text) {
            this.panoComponent.$refs.panoinfo.show = true
          }
          //this.hideToolTimer = setTimeout(() => {
          //  this.hideTool = true
          //  this.hideToolTimer = null
          //}, 4000)
        }
      },
      hotspotClick(item) {
        console.log(item)
        switch (item.linkType) {
          case 'Pano':
            var query = {}
            //query.senceId = item.linkItemGUID
            if (item.linkFOV) {
              query['view.fov'] = item.linkFOV
            }
            if (item.linkFOV) {
              query['view.hlookat'] = item.linkHLookAt
            }
            if (item.linkFOV) {
              query['view.vlookat'] = item.linkVLookAt
            }
            if (item.linkItem) {
              this.$router.push({ params: { panoId: item.linkItem.key }, query })
            }
            break
          case 'File':
          case 'Text':
          case 'Link':
            this.panoComponent.hotspotItem_show(item)
            break
          default:
        }
      },
    },
    //beforeRouteUpdate(to, from, next) {
    //  this.$axios.get('/Api/Panos/' + to.params.panoId).then(({ data }) => {
    //    this.pano = data
    //    this.sceneVars = {
    //      'view.fovmin': 70,
    //      'view.fovmax': 140,
    //      'view.maxpixelzoom': 2.0,
    //      'view.fovtype': 'MFOV',
    //      'view.fov': data.fov || 120,
    //      'view.vlookat': data.vLookAt,
    //      'view.hlookat': data.hLookAt,
    //      ...to.query
    //    }
    //    next()
    //  })
    //}
  }
</script>
<style scoped>
  .panoTitle-enter-active {
    animation: panoTitle-in .5s;
  }

  .panoTitle-leave-active {
    animation: panoTitle-in .5s reverse;
  }

  @keyframes panoTitle-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  .skin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    pointer-events: none !important;
  }

    .skin h4 {
      margin-bottom: 1rem;
      font-size: 1.6rem;
      font-weight: bolder;
    }

    .skin .pano td {
      border: none;
    }

    .skin .userA {
      position: absolute;
      top: 1rem;
      left: 1rem;
      max-width: 30vw;
      opacity: 0.7;
    }

    .skin .fine {
      position: absolute;
      top: 1.4rem;
      right: 1.4rem;
      font-size: 1.5rem;
      text-shadow: 0 0 2px red;
      color: orange;
      font-weight: bolder;
    }

    .skin .avatar img {
      width: 50px;
      height: 50px;
      /*border: 3px solid #fff;*/
      border-radius: 50%;
    }

    .skin .toolbar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 45px;
    }

      .skin .toolbar a {
        color: white;
      }

      .skin .toolbar .links {
        list-style: none;
        font-size: 0.8rem;
        margin: 0 15px;
        opacity: .6
      }

        .skin .toolbar .links li {
          display: inline-block;
          margin: 0 5px;
        }

          .skin .toolbar .links li i {
            display: block;
          }

          .skin .toolbar .links li a {
            text-align: center;
          }

    .skin .panoTitle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none !important;
      background-image: linear-gradient(to bottom, #0005,#0002, #0000);
      text-shadow: 1px 1px 2px black;
      color: white
    }

      .skin .panoTitle h1 {
        margin: 10px 0;
        font-size: 1.4rem;
      }

    .skin .icon {
    }

    .skin .logo {
      position: absolute;
      right: 0.5rem;
      bottom: 0;
      pointer-events: auto !important;
    }

      .skin .logo img {
        width: 130px;
      }

    .skin .rightToolbar {
      position: absolute;
      right: 10px;
      bottom: 45px;
      font-size: 1.8rem;
      line-height: 3.2rem;
      opacity: 0.6
    }

      .skin .rightToolbar > * {
        cursor: pointer;
      }

      .skin .rightToolbar i {
        padding: 8px;
      }

      .skin .rightToolbar .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .skin .rightToolbar .disabledState {
        opacity: 0.5;
      }

    .skin.hideTool .userA {
      left: -30vw;
    }

    .skin.hideTool .fine {
      right: -100px;
    }

    .skin.hideTool .rightToolbar {
      right: -40px;
    }

    .skin.hideTool .panoTitle {
      top: -75px;
    }

    .skin.hideTool .toolbar {
      bottom: -180px;
    }

    .skin .userA {
      transition: all 1s;
    }

    .skin .fine {
      transition: all 1s;
    }

    .skin .rightToolbar {
      transition: all 1s;
    }

    .skin .panoTitle {
      transition: all 1s;
    }

    .skin .toolbar {
      transition: all 1s;
    }

    .skin .share {
      max-width: 280px;
    }

    .skin .exif {
      font-size: 1.2rem
    }

      .skin .exif tr:first-child {
        white-space: nowrap
      }

      .skin .exif td {
        padding: 0 0 0 0;
      }

      .skin .exif th {
        padding: 0 0 0 0;
        text-align: right;
        width: 6em;
        vertical-align: top;
      }

    .skin .modalbox {
      background-color: #fffa !important;
    }

    .skin .mapModal {
      padding: 0 !important;
    }

    .skin hr {
      margin: 1rem 0
    }

    .skin h5 {
      margin-bottom: 1rem;
    }
</style>
