<template>
  <div class="UploadSingleBox" @dragenter="onDrag" @dragover="onDrag" @drop="onDrop">
    <div :class="{'hidden':fileList.length>0}">
      <Upload ref="Upload"
              :file-list="fileList"
              :action="action"
              :param="param"
              :multiple="false"
              :autoUpload="true"
              :maxSize="maxSize"
              :maxWidth="maxWidth"
              :maxHeight="maxHeight"
              :compress="compress"
              :isPano="isPano"
              :allowExtensions="allowExtensions"
              :partSize="partSizeMB*1024*1024"
              :surlheight="surlheight"
              :surlwidth="surlwidth"
              :maxUpper="maxUpper"
              :inputAccetp="inputAccetp"
              :on-change="onChange"
              :on-before="onBefore"
              :on-progress="uploadProgress"
              :on-success="uploadFinish"
              :on-failed="uploadFailed"
              :on-finished="onFinished"
              :onCheckingCount="onCheckingCount">
      </Upload>
    </div>
    <div class="data-show">
      <div class="card card-light" v-for="({surl, percent,name,size,status,title,id,resPano}, index) in fileList">
        <div class="card-header">
          <input :placeholder="name" class="card-header-input" type="text" v-model.trim:value="fileList[index].title" @blur="sendtitle(fileList[index])" />

          <div class="card-tools">
            <button type="button" class="btn btn-tool" @click="removeFile(index)">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <div class="info-box">
            <span v-if="previewIcon" class="info-box-icon bg-gray"><i :class="previewIcon" aria-hidden="true"></i></span>
            <span v-else class="info-box-icon" v-bind:style="previewImg(surl)"></span>

            <div class="info-box-content">
              <span class="info-box-text">{{statusToString(status)}} </span>
              <span class="info-box-number">{{formatBytes(size,0)}}<span v-if="status=='updateing'">,{{percent?percent:0}}%</span></span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
          <div class="progress progress-xxs">
            <div class="progress-bar progress-bar-striped" role="progressbar" :class="{ 'bg-danger':status=='Failed'}"
                 aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" :style="{width:percent+'%'}">
              <span class="sr-only">{{percent?percent:0}}% Complete</span>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
      <div class="card card-light" v-if="checkingFileCount>0">
        <div class="card-header">
          <div class="card-title">
            正在读取文件信息
          </div>

          <div class="card-tools">
          </div>
          <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
          <div class="info-box">
            <span class="info-box-icon bg-gray"><i class="fas fa-sync fa-spin"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">加载中…</span>
              <span class="info-box-number"></span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </div>

</template>

<script>
  import formatBytes from "../libs/formatBytes.js"

  import Upload from './Upload'
  export default {
    components: {
      Upload
    },
    name: 'UploadSingleBox',
    props: {
      name: String,
      param: Object,
      action: {
        type: String,
        required: true
      },
      allowExtensions: RegExp,
      inputAccetp: String,
      limit: Number,
      maxSize: Number,
      maxWidth: Number,
      maxHeight: Number,
      compress: Boolean,
      isPano: Boolean,
      partSizeMB: Number,
      surlheight: Number,
      surlwidth: Number,
      maxUpper: Number,
      previewIcon: String,//null,"fa-file-video-o"
      uploadFinished: Function,
      changeName: Function,
    },
    data: function () {
      return {
        fileList: [],
        checkingFileCount: 0
      };
    },
    methods: {
      statusToString(status) {
        var r = {
          success: '上传成功',
          ready: '准备上传',
          updateing: '正在上传',
          wating: '等待上传',
          Failed: '上传失败',
        }
        return r[status]
      },
      onChange(fileList) {
        let fl = [...fileList]
        for (var i in fl) {
          fl[i].title = fl[i].title ? fl[i].title : ''
        }
        this.fileList = fl;
        if (this.fileList.lengh == 1) {
          this.upload()
        }
        console.log(this.fileList);
      },
      onBefore(fileList) {
        return true;
      },
      uploadFinish(uuid, response) {
        for (var index in this.fileList) {
          if (this.fileList[index].id == uuid) {
            break;
          }
        }
        this.fileList[index]['res'] = response
        this.sendtitle(this.fileList[index])
        console.log(index, response);
      },
      upload() {
        this.$refs.Upload.submit();
      },
      removeFile(index) {
        this.$refs.Upload.remove(index);
      },
      uploadProgress(fileId, percent) {
        var index = null;
        const fileList = [...this.fileList];
        for (var fileindex in fileList) {
          if (fileList[fileindex].id == fileId) {
            index = fileindex
          }
        }
        if (index == null) {
          return;
        }
        fileList[index].percent = percent;
        this.fileList = [...fileList];
        console.log(index, fileList[index].percent, status);
      },
      uploadFailed(index, err) {
        console.log(index, err);
      },
      onFinished() {
        if (this.uploadFinished)
          this.uploadFinished(this.fileList[0].res);
      },
      previewImg(url) {
        return {
          'background-image': 'url(' + url + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      onCheckingCount(count) {
        this.checkingFileCount = count
      },
      onDrag: function (e) {
        e.stopPropagation();
        e.preventDefault();
      },
      onDrop: function (e) {
        e.stopPropagation();
        e.preventDefault();
        var dt = e.dataTransfer;
        var fe = {
          target: { files: dt.files }
        }
        this.$refs.Upload.addFile(fe);

      },
      sendtitle(file) {
        if (file.res && file.title)
          if (this.changeName)
            this.changeName({ title: file.title }, file.res)
      },
      formatBytes: formatBytes,

    },
    watch: {
    },
  }
</script>

<style>
</style>
