import * as types from '../mutation-types'

const state = {
  main: {
    title: '主页',
    name: ''
  }
}

const mutations = {
  [types.FETCH_PAGEINFO](state, title) {
    state.main.title = title
  }

}

export default {
  state,
  mutations
}
