import user from './user'
import setting from './setting'
import notices from './notices'
import messages from './messages'
import products from './products'

export default {
    user,
    setting,
    notices,
    messages,
    products
}
