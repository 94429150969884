const state = {
  main: [
    {
      id: 1,
      body: 'Task 1'
    },
    {
      id: 2,
      body: 'Task 2'
    },
    {
      id: 3,
      body: 'Task 3'
    },
    {
      id: 4,
      body: 'Task 4'
    }
  ]
}

const mutations = {

}

export default {
  state,
  mutations
}
