<template>
  <div style="position:relative;pointer-events:none;" :style="style" @resize="$parent.$forceUpdate()">
    <div class="Container" :style="realsize">
      <slot>
        <template v-if="data.name">
          <component :is="getComonent(data.name,data.componentPath)" :config="data.config" @ready="$emit('componentReady',data,$event)" @changePublicData="$emit('changePublicData',$event)">
          </component>
        </template>
      </slot>
      <template v-for="item in data.childs">
        <Container :key="item.key" :test="test" :data="item" :config="item.rect" :hide="hide" @dataChange="dataChange" @componentReady="componentReady" @changePublicData="$emit('changePublicData',$event)"></Container>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Container',
    components: {
    },
    props: {
      test: {
      },
      hide: {
        default() {
          return {}
        }
      },
      data: {
        default() {
          return {}
        }
      },
      config: {
        default() {
          return {}
        }
      },
    },
    inject: {
      getComonent: {},
      //hide: {}
    },
    data() {
      return {
        style: {},
        realsize: { width: '50%' },
      }
    },
    computed: {
      ratio() {
        return this.config.ratio
      }
    },
    created() {
    },
    mounted() {
      this.style = this.getstyle()
      this.realsize = this.getRealsize()
    },
    destroyed() {
    },
    beforeUpdate() {
      //this.style = this.getstyle()
    },
    watch: {
      test(val) {
        console.log('test:', val)
      },
      data(data, olddata) {
        //console.log('data:', data)
        this.$emit('dataChange', { data, olddata })
      },
      hide(val) {
        //console.log('hide:', val)
      },
      config(val) {
        //console.log(val)
        this.style = this.getstyle(val)
        this.realsize = this.getRealsize(val)
      }
    },
    methods: {
      dataChange(...d) {
        this.$emit('dataChange', ...d)
      },
      componentReady(...d) {
        this.$emit('componentReady', ...d)
      },
      getRealsize(val) {
        var r = {}
        var config = val || this.config
        var ratio = config.ratio
        var animation = config.animation || {}
        var flexdirection = config['flex-direction']
        var zindex = config['z-index']
        if (zindex !== undefined) {
          r['z-index'] = zindex
        }
        if (ratio) {
          var width = this.$el.offsetWidth
          var height = this.$el.offsetHeight
          var rr = height / width
          r.position = 'relative'
          if (rr < ratio) {
            r.width = this.data2css(width)
            r.height = this.data2css(width / ratio)
          } else {
            r.width = this.data2css(height * ratio)
            r.height = this.data2css(height)
          }
        } else {
          r.width = '100%'
          r.height = '100%'
        }
        if (animation === true) {
          r.transition = 'all 1s'
        } else if (typeof animation == 'string') {
          r.animation = animation
        }
        var container = config.container
        if (container) {
          switch (container) {
            case 'none':
              r.display = 'none'
            break
            case 'flex':
              r.display = 'flex'
              break
            case 'flex between':
              r.display = 'flex'
              r['justify-content'] = 'space-between'
              break
            case 'flex between end':
              r.display = 'flex'
              r['justify-content'] = 'space-between'
              r['align-items'] = 'flex-end';
              break
            case 'flex center':
              r.display = 'flex'
              r['justify-content'] = 'center'
              r['align-items'] = 'center';
              break
            case 'flex end':
              r.display = 'flex'
              r['justify-content'] = 'flex-end'
              break
            case 'block':
              r.display = 'block'
              break
            default:
          }
        }
        if (flexdirection) {
          r['flex-direction'] = flexdirection
        }
        return r
      },
      getstyle(val) {
        var config = val || this.config
        var r = {}
        var animation = config.animation || {}
        var position = config.position || {}
        var margin = config.margin
        var hide = config.hide
        var opacity = config.opacity
        var ratio = config.ratio
        var flex = config.flex
        var width = config.width
        var height = config.height
        var backgroundColor = config.backgroundColor
        //var container = config.container
        if (backgroundColor) {
          r['background-color'] = backgroundColor
        }
        if (ratio) {
          r.display = 'flex'
          r['flex-flow'] = 'column'
          r['justify-content'] = 'center'
          r['align-items'] = 'center';
        }
        if (flex) {
          r.flex = flex
          r.overflow = 'hidden'
        }
        if (width) {
          r.width = this.data2css(width)
        }
        if (height) {
          r.height = this.data2css(height)
        }
        //if (container) {
        //  switch (container) {
        //    case 'flex center':
        //      r.display = 'flex'
        //      r['justify-content'] = 'center'
        //      r['align-items'] = 'center';
        //    default:
        //  }
        //}
        if (margin) {
          if (typeof margin == 'string') {
            r.margin = margin
          } else {
            if (margin.left) {
              r['margin-left'] = this.data2css(margin.left)
            }
            if (margin.right) {
              r['margin-right'] = this.data2css(margin.right)
            }
            if (margin.top) {
              r['margin-top'] = this.data2css(margin.top)
            }
            if (margin.bottom) {
              r['margin-bottom'] = this.data2css(margin.bottom)
            }
          }
        }
        if (animation === true) {
          r.transition = 'all 1s'
        } else if (typeof animation == 'string') {
          r.animation = animation
        }
        if (
          position.left !== undefined ||
          position.right !== undefined ||
          position.top !== undefined ||
          position.bottom !== undefined
        ) {
          r.position = 'absolute'
          r.left = this.data2css(position.left)
          r.right = this.data2css(position.right)
          r.top = this.data2css(position.top)
          r.bottom = this.data2css(position.bottom)
        } else if (position.flex) {
          switch (position.flex) {
            case 'auto':
              r.flex = 'auto'
              break
            case 'none':
              r.flex = 'none'
              break
            default:
          }
        }
        if (typeof opacity == 'number') {
          r.opacity = opacity
        }
        if (hide) {
          if (hide == 'left') {
            //r.left = -this.getPosion().width + 'px'
            r.transform = `translateX(-${this.getPosion().width + this.getPosion().screenLeft}px)`
          }
          if (hide == 'right') {
            //r.right = -this.getPosion().width + 'px'
            r.transform = `translateX(${this.getPosion().width + this.getPosion().screenRight}px)`
          }
          if (hide == 'top') {
            //r.top = -this.getPosion().height + 'px'
            r.transform = `translateY(-${this.getPosion().height + this.getPosion().screenTop}px)`
          }
          if (hide == 'bottom') {
            //r.bottom = -this.getPosion().height + 'px'
            r.transform = `translateY(${this.getPosion().height + this.getPosion().screenBottom}px)`
          }
        }
        return r
      },
      data2css(data) {
        if (data !== undefined) {
          var d = data
          if (typeof data == 'function') {
            d = data()
          }
          if (typeof d == 'number') {
            return `${d}px`
          }
          return d
        }
        return undefined
      },
      getPosion() {
        return {
          width: this.$el.offsetWidth,
          height: this.$el.offsetHeight,
          top: this.$el.offsetTop,
          bottom: this.$el.offsetParent.offsetHeight - (this.$el.offsetTop + this.$el.offsetHeight),
          left: this.$el.offsetLeft,
          right: this.$el.offsetParent.offsetWidth - (this.$el.offsetLeft + this.$el.offsetWidth),
          screenTop: window.scrollY + this.$el.offsetTop,
          screenBottom: window.scrollY + window.innerHeight - (this.$el.offsetTop + this.$el.offsetHeight),
          screenLeft: window.scrollX + this.$el.offsetLeft,
          screenRight: window.scrollX + window.innerWidth - (this.$el.offsetLeft + this.$el.offsetWidth),
        }
      }
    },
  }
</script>
<style scoped>
  .Container {
    pointer-events: none !important;
  }

    .Container > * {
      pointer-events: auto;
    }
</style>
