<template>
  <div class="editorUpload" @dragenter="onDrag" @dragleave="onDragleave" @dragover="onDrag" @drop="onDrop">
    <template v-for="({surl, percent,name,size,status,title,id,resPano}, index) in fileList">
      <slot name="fileListItem" :index="index" :surl="surl" :percent="percent" :size="size" :status="statusToString(status)" :title="title" :id="id" :resPano="resPano" :file="fileList[index]">
        <div class="card card-light">
          <div class="card-header">
            {{fileList[index].title}}
            <div class="card-tools">
              <button type="button" class="btn btn-tool" @click="removeFile(index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="info-box">
              <span v-if="previewIcon" class="info-box-icon bg-gray"><i :class="previewIcon" aria-hidden="true"></i></span>
              <span v-else class="info-box-icon" v-bind:style="previewImg(surl)"></span>

              <div class="info-box-content">
                <span class="info-box-text">{{statusToString(status)}} </span>
                <span class="info-box-number">{{formatBytes(size,0)}}<span v-if="status=='updateing'">,{{percent?percent:0}}%</span></span>
              </div>
            </div>
            <div class="progress progress-xxs">
              <div class="progress-bar progress-bar-striped" role="progressbar" :class="{ 'bg-danger':status=='Failed'}"
                   aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" :style="{width:percent+'%'}">
                <span class="sr-only">{{percent?percent:0}}% Complete</span>
              </div>
            </div>
          </div>
        </div>
      </slot>

    </template>
    <template v-if="checkingFileCount>0">
      <slot name="readingFile">
        <div class="card card-light">
          <div class="card-header">
            <div class="card-title">
              正在读取文件信息
            </div>

            <div class="card-tools">
            </div>
            <!-- /.card-tools -->
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <div class="info-box">
              <span class="info-box-icon bg-gray"><i class="fas fa-sync fa-spin"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">加载中…</span>
                <span class="info-box-number"></span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>
          <!-- /.card-body -->
        </div>
      </slot>
    </template>
    <Upload ref="Upload"
            pclass="upLoadItem"
            :file-list="fileList"
            :action="action"
            :param="param"
            :multiple="multiple"
            :maxSize="maxSize"
            :maxWidth="maxWidth"
            :maxHeight="maxHeight"
            :compress="compress"
            :isPano="isPano"
            :autoUpload="true"
            :allowExtensions="allowExtensions"
            :partSize="partSizeMB*1024*1024"
            :surlheight="surlheight"
            :surlwidth="surlwidth"
            :maxUpper="maxUpper"
            :inputAccetp="inputAccetp"
            :on-change="onChange"
            :on-before="onBefore"
            :on-progress="uploadProgress"
            :on-success="uploadFinish"
            :on-failed="uploadFailed"
            :on-finished="onFinished"
            :onCheckingCount="onCheckingCount">
      <slot name="UploadBtn" :dragover="dragover">
        点击或拖动到此处上传
      </slot>
    </Upload>
  </div>

</template>

<script>
  import formatBytes from "../libs/formatBytes.js"

  import Upload from './Upload'
  export default {
    components: {
      Upload
    },
    name: 'editorUpload',
    props: {
      name: String,
      param: Object,
      action: {
        type: String,
        required: true
      },
      allowExtensions: RegExp,
      inputAccetp: String,
      multiple: Boolean,
      limit: Number,
      maxSize: Number,
      maxWidth: Number,
      maxHeight: Number,
      compress: Boolean,
      isPano: Boolean,
      partSizeMB: Number,
      surlheight: Number,
      surlwidth: Number,
      maxUpper: Number,
      previewIcon: String,//null,"fa-file-video-o"
      uploadFinished: Function,
      onUploadFinish: Function,
    },
    data: function () {
      return {
        fileList: [],
        checkingFileCount: 0,
        dragover: false,
        showdrag: false,
      };
    },
    mounted() {
      window.addEventListener('dragenter', this.showdragarea)
      window.addEventListener('dragover', this.showdragarea)
      window.addEventListener('dragleave', this.hidedragarea)
      window.addEventListener('drop', this.stopevent)
    },
    destroyed() {
      window.removeEventListener('dragenter', this.showdragarea)
      window.removeEventListener('dragover', this.showdragarea)
      window.removeEventListener('dragleave', this.hidedragarea)
      window.removeEventListener('drop', this.stopevent)
    },
    methods: {
      showdragarea(e) {
        this.showdrag = true;
        //e.stopPropagation();
        e.preventDefault();
      },
      hidedragarea(e) {
        this.showdrag = false;
        //e.stopPropagation();
        e.preventDefault();
      },
      stopevent(e) {
        //e.stopPropagation();
        e.preventDefault();
      },
      statusToString(status) {
        var r = {
          success: '上传成功',
          ready: '准备上传',
          updateing: '正在上传',
          wating: '等待上传',
          Failed: '上传失败',
        }
        return r[status]
      },
      onChange(fileList) {
        let fl = [...fileList]
        for (var i in fl) {
          fl[i].title = fl[i].title ? fl[i].title : ''
        }
        this.fileList = fl;
        console.log(this.fileList);
      },
      onBefore(fileList) {
        return true;
      },
      uploadFinish(uuid, response) {
        var index = -1;
        for (var i in this.fileList) {
          if (this.fileList[i].id == uuid) {
            index = i;
            break;
          }
        }
        if (index != -1) {
          this.fileList[index]['res'] = response
          if (this.onUploadFinish) {
            this.onUploadFinish(response)
          }
          this.removeFile(index)
          console.log(index, response);
        }
      },
      upload() {
        this.$refs.Upload.submit();
      },
      removeFile(index) {
        this.$refs.Upload.remove(index);
      },
      uploadProgress(fileId, percent) {
        var index = null;
        const fileList = [...this.fileList];
        for (var fileindex in fileList) {
          if (fileList[fileindex].id == fileId) {
            index = fileindex
          }
        }
        if (index == null) {
          return;
        }
        fileList[index].percent = percent;
        this.fileList = [...fileList];
        console.log(index, fileList[index].percent, status);
      },
      uploadFailed(uuid, err) {
        console.log(uuid, err);
      },
      onFinished(result) {//result: { success, failed }
        //console.log(result);
        if (this.uploadFinished)
          this.uploadFinished(result);
      },
      previewImg(url) {
        return {
          'background-image': 'url(' + url + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      onCheckingCount(count) {
        this.checkingFileCount = count
      },
      onDrag: function (e) {
        this.dragover = true
        e.stopPropagation();
        e.preventDefault();
      },
      onDragleave(e) {
        this.dragover = false
      },
      onDrop: function (e) {
        console.log(e)
        this.dragover = false
        e.stopPropagation();
        e.preventDefault();
        var dt = e.dataTransfer;
        var fe = {
          target: { files: dt.files }
        }
        this.$refs.Upload.addFile(fe);

      },
      formatBytes: formatBytes,
    },
    watch: {
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .editorUpload {
    display: inline;
    line-height: 1;
  }

  .upLoadItem {
    vertical-align: top;
  }
</style>
