<template>
  <div class="PanoAMap">
    <aMap ref="map" :onMapReady="onMapReady" :mapConfig="mapConfig"></aMap>
    <div class="drive">
      <slot name="buttons"></slot>
      <button v-if="tomap" @click="tomap" style="line-height: 2rem;height: 2.5rem;">打开高德地图开始导航</button>
    </div>
  </div>
</template>
<script>
  import aMap from './AMap'
  import gpsCorrect from '../../libs/gpsCorrect.js'
  export default {
    scrollToTop: true,
    components: {
      aMap,
    },
    props: {
      currentItem: Object,
      mapCenter: Object,
      zoom: [String, Number],
      autoZoom: Boolean,
      url: {
        type: String,
        default: '/Api/Panos/PanoLocations'
      },
      params: Object,
    },
    data: () => {
      return {
        mapConfig: {
          zoom: 1,
        },
        mapdata: null,
        panoPoints: null,
        tomap: null,
        AMap_Driving: null,
      }
    },
    computed: {

    },
    created() {
    },
    mounted() {
    },
    methods: {
      mapBackToPano() {
        if (this.currentItem) {
          this.$refs.map.setCenterLngLat(this.currentItem.gpsLng, this.currentItem.gpsLat, 'gps')
          this.$refs.map.setZoom(this.$refs.map.aMap.getZooms()[1])
        }
      },
      autoZoomOut() {
        if (!this.panoPoints) {
          setTimeout(this.autoZoomOut, 200)
          return
        }
        if (this.$refs.map.aMap.getZoom() == this.$refs.map.aMap.getZooms()[0]) {
          return
        }
        for (var i in this.panoPoints) {
          var item = this.panoPoints[i]
          var { lng, lat } = gpsCorrect.transform(item.lat, item.lng)
          if (this.isinContainer(lng, lat)) {
            return
          }
        }
        console.log('zoomOut')
        this.$refs.map.aMap.zoomOut()
        setTimeout(this.autoZoomOut, 200)
      },
      isinContainer(lng, lat) {
        var p = this.$refs.map.aMap.lngLatToContainer([lng, lat])
        var size = this.$refs.map.aMap.getSize()
        return (p.getX() >= 0 && p.getX() <= size.getWidth() && p.getY() >= 0 && p.getY() <= size.getHeight())
      },
      resetZoom() {
        if (typeof this.zoom == 'string') {
          if (this.zoom == 'min') {
            this.$refs.map.setZoom(this.$refs.map.aMap.getZooms()[0])
          }
          if (this.zoom == 'max') {
            this.$refs.map.setZoom(this.$refs.map.aMap.getZooms()[1])
          }
        } else if (typeof this.zoom == 'number') {
          this.$refs.map.setZoom(this.zoom)
        }
        if (this.autoZoom) {
          this.autoZoomOut()
        }
      },
      getCurrentPosition() {
        this.$refs.map.getlocation().then((data) => {
          if (this.mapCenter) {
            var p = gpsCorrect.transform(this.mapCenter.lat, this.mapCenter.lng)
            this.$refs.map.setCenterLngLat(p.lng, p.lat)
            this.resetZoom()
          } else {
            var p = gpsCorrect.transform(data.lat, data.lng)
            this.$refs.map.setCenterLngLat(p.lng, p.lat)
            this.resetZoom()
          }
        })
      },
      onMapReady() {
        var startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(19, 31),
          // 图标的取图地址
          image: 'https://webapi.aMap.com/theme/v1.3/markers/b/mark_rs.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(19, 31),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(0, 0)
        })
        if (this.currentItem) {
          this.$refs.map.addMark('currentItem', {
            gpsLng: this.currentItem.gpsLng,
            gpsLat: this.currentItem.gpsLat,
            icon: startIcon,
            on: {
              click: () => {
                var p2 = gpsCorrect.transform(this.currentItem.gpsLat, this.currentItem.gpsLng)
                this.$refs.map.addInfoWindow('infoWindow', {
                  position: [p2.lng, p2.lat],
                  content: this.panoInfowindow(this.currentItem, new AMap.LngLat(p2.lng, p2.lat)),
                  closeWhenClickMap: true,
                  anchor: 'top-center',
                })
              }
            },
          })
        }
        this.getCurrentPosition()
        if (this.mapCenter) {
          var p = gpsCorrect.transform(this.mapCenter.lat, this.mapCenter.lng)
          this.$refs.map.setCenterLngLat(p.lng, p.lat)
          this.resetZoom()
        }
        this.$axios.get(this.url, {
          params: {
            lng: 0,
            lat: 0,
            radius: 100000,
            max: 50000,
            ...this.params,
          }
        }).then((res) => {
          this.panoPoints = res.data
          var markconfigs = []
          for (var i in this.panoPoints) {
            var item = { ...this.panoPoints[i] }
            if (this.currentItem && this.currentItem.key == item.key) {
              continue
            }
            var p = gpsCorrect.transform(item.lat, item.lng)
            item.position = [p.lng, p.lat]
            item.on = {
              click: (event) => {
                this.$refs.map.addInfoWindow('infoWindow', {
                  position: event.lnglat,
                  content: `加载中…`,
                  closeWhenClickMap: true,
                  anchor: 'top-center',
                })
                var panoUrl = process.env.EnableElasticSearch ? `/Api/ES/Panos/${event.target.getExtData()}` : `/Api/Panos/${event.target.getExtData()}`
                this.$axios.get(panoUrl).then((res) => {
                  var p2 = gpsCorrect.transform(res.data.gpsLat, res.data.gpsLng)
                  console.log(res.data, p2)
                  this.$refs.map.addInfoWindow('infoWindow', {
                    position: [p2.lng, p2.lat],
                    content: this.panoInfowindow(res.data, new AMap.LngLat(p2.lng, p2.lat)),
                    closeWhenClickMap: true,
                    anchor: 'top-center',
                  })
                })
              }
            }
            item.extData = res.data[i].key
            markconfigs.push(item)
          }
          this.$refs.map.addMarkerClusterer(markconfigs, {
            maxZoom: 20,
            on: {
              click: (event) => {
                if (this.$refs.map.aMap.getZoom() == this.$refs.map.aMap.getZooms()[1]) {
                  var list = []
                  for (var i in event.markers) {
                    list.push(event.markers[i].getExtData())
                  }
                  this.$refs.map.addInfoWindow('infoWindow', {
                    position: event.lnglat,
                    content: `加载中…`,
                    closeWhenClickMap: true,
                    anchor: 'top-center',
                  })
                  this.$axios.post('/Api/Panos/GetByKeys', list).then((res) => {
                    this.$refs.map.addInfoWindow('infoWindow', {
                      position: event.lnglat,
                      content: this.panoListInfowindow(res.data, event.lnglat),
                      closeWhenClickMap: true,
                      anchor: 'top-center',
                    })
                  })
                }
              }
            }
          })
        }).catch((err) => {
          console.error(err)
        })
      },
      panoInfowindow(pano, p) {
        //return `<div class='mapInfoWin'><div><div><a href="/P/${pano.key}" target="_blank"><img style="width:200px;height:100px;" src="${pano.firstImage.url.replace('/0/0/0/0/', '/200/0/0/0/')},"></a></div><br><div><a href="/P/${pano.key}" target="_blank"><b>${pano.title}</b></a><br>作者：<a href="/U/${pano.user.userName}" target="_blank">${pano.user.nickName}</a><br></div></div><br><a class="btmtip">导航到这里</a></div>`\
        return this.panoListInfowindow([pano], p)
      },
      panoListInfowindow(list, p) {
        console.log(list)
        var str = ''
        for (var i in list) {
          var pano = list[i]
          str += `<div class="mapInfoWin"><div><div><a href="/P/${pano.key}" target="_blank"><img style="width:200px;height:100px;" src="${pano.firstImage.url.replace('/0/0/0/0/', '/200/0/0/0/')},"></a><div class="mapuserinfo"><a class="text-truncate" href="/P/${pano.key}" target="_blank">${pano.title}</a><div>作者：<a href="/U/${pano.user.userName}" target="_blank">${pano.user.nickName}</a></div></div></div></div></div>`
        }
        var e = document.createElement('div')
        e.innerHTML = `<div class="maplistbox">${str}</div><div class="text-center" style="margin-top:3px;"></div>`
        var b = document.createElement('button')
        b.onclick = () => {
          this.$refs.map.getlocation().then((data) => {
            var from = gpsCorrect.transform(data.lat, data.lng)
            this.DrivingTo(from, p)
          })
        }
        b.classList.add('btmtip')
        b.innerText = '导航到这里'
        e.getElementsByClassName('text-center')[0].appendChild(b)
        return e
      },
      DrivingTo(from, to) {
        console.log(from, to)
        if (AMap) {
          if (!this.AMap_Driving) {
            AMap.plugin('AMap.Driving', () => {
              this.AMap_Driving = new AMap.Driving({
                // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
                policy: AMap.DrivingPolicy.LEAST_TIME,
                // map 指定将路线规划方案绘制到对应的AMap.Map对象上
                map: this.$refs.map.aMap,
                // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
                //panel: 'panel'
              })
              this.runDriving(from, to)
            })
          } else {
            this.runDriving(from, to)
          }
        }
      },
      runDriving(from, to) {
        this.$refs.map.closeInfoWindow('infoWindow')
        this.AMap_Driving.clear()
        //var points = [
        //  from,
        //  to
        //]
        console.log(from)
        console.log(to)
        // 搜索完成后，将自动绘制路线到地图上
        this.AMap_Driving.search(new AMap.LngLat(from.lng, from.lat), new AMap.LngLat(to.lng, to.lat), {}, (status, result) => {
          console.log(status, result)
        })
        this.tomap = () => {
          this.AMap_Driving.searchOnAMAP({
            origin: new AMap.LngLat(from.lng, from.lat), //起点坐标
            originName: '我的位置', //起点名称
            destination: new AMap.LngLat(to.lng, to.lat), //终点坐标
            destinationName: '全景位置' //终点名称
          })
        }
      }
    }
  }
</script>
<style lang="css">
  .PanoAMap {
    width: 100%;
    height: 100%;
    position: relative
  }

    .PanoAMap .drive {
      position: absolute;
      bottom: 35px;
      right: 10px;
    }

    .PanoAMap .maplistbox {
      /*width: 200px;*/
      max-height: 300px;
      overflow-y: auto;
    }

    .PanoAMap .mapInfoWin {
      width: 200px;
      position: relative;
    }

    .PanoAMap .text-truncate {
      display: block;
    }

    .PanoAMap .mapuserinfo {
      position: absolute;
      bottom: 4px;
      left: 2px;
      right: 2px;
      text-shadow: 0 0 5px #000, 0 0 4px #000, 0 0 3px #000, 0 0 2px #000;
      color: #fff;
    }

      .PanoAMap .mapuserinfo a {
        color: #fff
      }

    .PanoAMap .aMap-info-close {
      right: 3px !important;
      text-shadow: 0px 0px 4px #fff;
      color: #000;
    }
</style>
