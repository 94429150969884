<template>
    <div class="Animation" :class="'style_'+id">
    </div>
</template>

<script>
    import uuid from 'uuid'
    export default {
        name: 'Animation',
        props: {
            animationSet: Object,
            //imageUrl: String,
            //width: [Number, String],
            //height: [Number, String],
            //fps: [Number, String],
            //Scale: {
            //    type: Number,
            //    default: 1
            //},
            //renderType: {
            //    type: string,
            //    default: 'auto',
            //}
        },
        data: function () {
            return {
                imageUrl: '',
                width: 0,
                height: 0,
                fps: 30,
                Scale: 1,

                //id: uuid.v1(),
                id: Number(Math.random().toString().substr(3, length) + Date.now()).toString(36),
                render: 0,
                timer: null,
                count: 0,
                imageWidth: 0,
                imageHeight: 0,
                imgloading: false,
                style: null,
            }
        },
        created() {
            //this.startTimer(this.fps)
        },
        mounted() {
            this.loadAnimationSet(this.animationSet)
            this.style = document.createElement('style');
            this.style.type = 'text/css';
            //this.style.innerHTML = "body{ background-color:blue }";
            document.getElementsByTagName('head').item(0).appendChild(this.style);

            this.animationRun()
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            loadAnimationSet(animationSet) {
                if (animationSet.imageUrl)
                    this.imageUrl = animationSet.imageUrl
                else {
                    console.log('imageUrl err', 'Animation')
                }
                if (animationSet.width)
                    this.width = parseFloat(animationSet.width)
                else {
                    console.log('width err', 'Animation')
                }
                if (animationSet.height)
                    this.height = parseFloat(animationSet.height)
                else {
                    console.log('height err', 'Animation')
                }
                this.fps = animationSet.fps || 30
                this.Scale = animationSet.Scale || 1
            },
            animationRun() {
                console.log('animationRun', this.id)
                //this.id = Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
                this.id = uuid()
                console.log(this.id)
                if (this.imageUrl) {
                    this.loadimg(this.imageUrl, () => {
                        var xc = Math.floor(this.imageWidth / this.width);
                        var yc = Math.floor(this.imageHeight / this.height);
                        if (xc > 1 && yc > 1) {
                            this.startTimer(this.fps);
                        } else {
                            this.stopTimer();
                            this.makeAnimation();
                        }
                    })
                }
                var xc = Math.floor(this.imageWidth / this.width);
                var yc = Math.floor(this.imageHeight / this.height);
                if (xc > 1 && yc > 1) {
                    this.startTimer(this.fps);
                } else {
                    this.stopTimer();
                    this.makeAnimation();
                }
            },
            startTimer(fps) {
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
                this.timer = setInterval(this.runTimer, (1000 / (fps || 30)))
            },
            runTimer() {
                this.count++;
            },
            stopTimer() {
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            },
            makeStyle(count) {
                if (this.imageUrl) {
                    var xc = Math.floor(this.imageWidth / this.width);
                    var yc = Math.floor(this.imageHeight / this.height);
                    var x = count % xc
                    var y = Math.floor(count / xc)
                    if (y >= yc) {
                        this.count = 0;
                        x = 0;
                        y = 0;
                    }
                    var sty = {
                        'background-image': 'url(' + this.imageUrl + ')',
                        'background-repeat': ' no-repeat',
                        'background-size': this.imageWidth * this.Scale + 'px ' + this.imageHeight * this.Scale + 'px',
                        'width': this.width * this.Scale + 'px',
                        'height': this.height * this.Scale + 'px',
                        'background-position': -this.width * x * this.Scale + 'px ' + -this.width * y * this.Scale + 'px',
                        'display': 'block',
                    }
                    var str = ''
                    str += '{\n'
                    for (var i in sty) {
                        str += i + ':' + sty[i] + ';\n'
                    }
                    str += '}'
                    if (this.style)
                        this.style.innerHTML = '.style_' + this.id + str;
                } else {
                    var sty = {
                        'background-repeat': ' no-repeat',
                        'width': this.width * this.Scale + 'px',
                        'height': this.height * this.Scale + 'px',
                        'display': 'block',
                    }
                    var str = ''
                    str += '{\n'
                    for (var i in sty) {
                        str += i + ':' + sty[i] + ';\n'
                    }
                    str += '}'
                    if (this.style)
                        this.style.innerHTML = '.style_' + this.id + str;

                }
            },
            makeAnimation() {
                if (this.imageUrl) {
                    var xc = Math.floor(this.imageWidth / this.width);
                    var yc = Math.floor(this.imageHeight / this.height);
                    var sty = {
                        'background-image': 'url(' + this.imageUrl + ')',
                        'background-repeat': ' no-repeat',
                        'background-size': this.imageWidth * this.Scale + 'px ' + this.imageHeight * this.Scale + 'px',
                        'width': this.width * this.Scale + 'px',
                        'height': this.height * this.Scale + 'px',
                        'display': 'block',
                        '-webkit-animation': 'a' + this.id + '_run ' + ((1000 / (this.fps || 30)) * (xc * yc - 1)) + 'ms steps(' + (xc * yc - 1) + ') infinite'
                    }
                    var str = '.style_' + this.id
                    str += '{\n'
                    for (var i in sty) {
                        str += i + ':' + sty[i] + ';\n'
                    }
                    str += '}\n'
                    str += '@-webkit-keyframes a' + this.id + '_run{\n'
                    str += 'from{\n'
                    str += 'background-position: 0 0;\n'
                    str += '}\n'
                    str += 'to{\n'
                    str += 'background-position:' + -this.width * (xc - 1) * this.Scale + 'px ' + -this.width * (yc - 1) * this.Scale + 'px;\n'
                    str += '}\n'
                    if (this.style)
                        this.style.innerHTML = str;
                } else {
                    var sty = {
                        'background-repeat': ' no-repeat',
                        'width': this.width * this.Scale + 'px',
                        'height': this.height * this.Scale + 'px',
                        'display': 'block',
                    }
                    var str = ''
                    str += '{\n'
                    for (var i in sty) {
                        str += i + ':' + sty[i] + ';\n'
                    }
                    str += '}'
                    if (this.style)
                        this.style.innerHTML = str;

                }

            },
            loadimg(url, fun) {
                if (url) {
                    var _this = this
                    this.imgloading = true;
                    var image = new Image();
                    image.onload = function () {
                        _this.imageWidth = image.width;
                        _this.imageHeight = image.height;
                        this.imgloading = false;
                        if (fun)
                            fun()
                    }
                    image.src = url;
                }
            }
        },
        computed: {

        },
        watch: {
            //imageUrl(val, oldval) {
            //    this.loadimg(val);
            //},
            //fps(val, oldval) {
            //    this.startTimer(val)
            //},
            count(val, oldval) {
                this.makeStyle(val)
            },
            animationSet(val, oldval) {
                console.log('popschange')
                this.loadAnimationSet(this.animationSet)
                setTimeout(this.animationRun, 100)
            }
        },

        components: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
