<template>
  <div style="position:relative;">
    <img :src="url" style="width:100%" @mousedown="mousedown" @mouseup="mouseup" @mousemove="mousemove" @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend" @touchcancel="touchcancel" draggable="false" />
    <div style="position:absolute" :style="viewStyle">
      <div style="width: 91px; height: 91px; border-right: 1px solid #0005; border-top: 1px solid #0005;position:relative ">
        <div v-for="m in previewData">
          <div style="line-height:0">
            <div v-for="m2 in m" style="width:10px;height:10px;border-left:1px solid #0005;border-bottom:1px solid #0005;display:inline-block" :style="{'background-color':m2}"></div>
          </div>
        </div>
        <div style="width:13px;height:13px;border:1px solid #fff;position:absolute;left:39px;top:38px;"></div>
      </div>
      <div :style="{'background-color':color}">
        {{color}}
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    props: {
      url: {
      }
    },
    data() {
      return {
        image: null,
        canvas: null,
        context: null,
        color: null,
        state: null,
        previewData: null,
        viewStyle: {},
      }
    },
    computed: {
    },
    watch: {
      url(val, oldval) {
        if (val) {
          this.renewImage(val)
        }
      }
    },
    mounted() {
      if (this.url) {
        this.renewImage(this.url)
      }
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      touchstart(e) {
        e.preventDefault()
        var touch = e.changedTouches[0]
        this.pickStart(touch.clientX, touch.clientY, e.target)

      },
      touchmove(e) {
        var touch = e.changedTouches[0]
        this.pickMove(touch.clientX, touch.clientY, e.target)
      },
      touchend(e) {
        var touch = e.changedTouches[0]
        this.pickEnd(touch.clientX, touch.clientY, e.target)
      },
      touchcancel(e) {
        var touch = e.changedTouches[0]
      },
      mousedown(e) {
        this.pickStart(e.offsetX, e.offsetY, e.target)
      },
      mouseup(e) {
        this.pickEnd(e.offsetX, e.offsetY, e.target)
      },
      mousemove(e) {
        this.pickMove(e.offsetX, e.offsetY, e.target)
      },
      pickStart(x, y, img) {
        this.state = 'down'
        var ix = x * (this.image.width / img.width)
        var iy = y * (this.image.height / img.height)
        this.color = this.getColor(ix, iy)
        this.getPreviewData(ix, iy)
        this.updateViewStyle(x, y, img)
      },
      pickMove(x, y, img) {
        if (this.state == 'down') {
          var ix = x * (this.image.width / img.width)
          var iy = y * (this.image.height / img.height)
          this.color = this.getColor(ix, iy)
          this.getPreviewData(ix, iy)
          this.updateViewStyle(x, y, img)
        }
      },
      pickEnd(x, y, img) {
        if (this.state == 'down') {
          var ix = x * (this.image.width / img.width)
          var iy = y * (this.image.height / img.height)
          this.color = this.getColor(ix, iy)
          this.getPreviewData(ix, iy)
          this.$emit('change', this.color)
        }
      },
      updateViewStyle(x, y, img) {
        var vs = {}
        if (x < img.width / 2) {
          vs.left = (x + 10) + 'px'
        } else {
          vs.left = (x - 100) + 'px'
        }
        if (y < img.height / 2) {
          vs.top = (y + 10) + 'px'
        } else {
          vs.top = (y - 120) + 'px'
        }
        this.viewStyle = vs
      },
      renewImage(url) {
        this.image = null
        this.canvas = null
        this.context = null

        var image = new Image()
        image.src = url
        image.onload = () => {
          this.image = image
          var canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          var context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)

          this.canvas = canvas
          this.context = context
        }
        image.onerror = (e) => {
          console.error('读取错误')
        }
      },
      getColor(x, y) {
        var pix = this.context.getImageData(x, y, 1, 1).data
        return this.pixToColor(pix)
      },
      getPreviewData(x, y) {
        var pixs = this.context.getImageData(x - 4, y - 4, 9, 9).data
        var m = []
        for (var px = 0; px < 9; px++) {
          var m2 = []
          for (var py = 0; py < 9; py++) {
            var i = (px * 9 + py) * 4
            var pix = [pixs[i], pixs[i + 1], pixs[i + 2], pixs[i + 3]]
            m2.push(this.pixToColor(pix))
          }
          m.push(m2)
        }
        this.previewData = m
      },
      pixToColor(pix) {
        var color = (pix[0] << 16) | (pix[1] << 8) | pix[2];
        var s = color.toString(16)
        while (s.length < 6) {
          s = '0' + s
        }
        return `#${s}`
      }
    }
  }
</script>
<style scoped>
</style>
