import * as types from '../mutation-types'

const state = {
    main: null
}

const mutations = {
    [types.FETCH_SETTING](state, setting) {
        state.main = setting
    }
}

export default {
    state,
    mutations
}
