<template>
  <div style="height:120px;margin-bottom:15px;position:relative" :style="{height:height||'120px'}">
    <LeafletMap ref="map" :ToolBars="false" :onMapReady="onMapReady" :mapConfig="getMapConfig()" :baseMaps="baseMaps"></LeafletMap>
    <div v-if="!dragable" style="position:absolute;top:0;left:0;right:0;bottom:0;z-index:1100;" :style="overStyle" @click="$emit('click',$event)"></div>
  </div>
</template>
<script>
  import LeafletMap from './LeafletMap'
  export default {
    name: 'MapView',
    components: {
      LeafletMap,
    },
    props: {
      mark: {},
      geojsonUrl: {
        type: String
      },
      mapConfig: {
        default: () => {
          return { zoom: 15 }
        }
      },
      height: {
        type: String,
        default: '120px'
      },
      dragable: {
        type: Boolean,
        default: false
      },
      overStyle: {
        default: () => {
          return {}
        }
      }
    },
    data: () => {
      return {
        mapReady: false,
        baseMaps: [
          { title: '天地图卫星', name: 'tianDiTuSatelliteMap' },
          { title: '高德地图', name: 'gaoDeMap' },
          { title: '高德卫星', name: 'gaoDeSatelliteMap' },
          { title: '天地图', name: 'tianDiTuMap' },
          { title: '混合', name: 'hybridMap' },
          { title: '卫星', name: 'satelliteMap' },
          { title: '地图', name: 'normalMap' },
          { title: '地形', name: 'streettitleMap' },
          { title: 'OSM', name: 'osmMap' },
          { title: '百度地图', name: 'baiduMap' },
          { title: '百度卫星', name: 'baiduSatelliteMap' },
          { title: '智图', name: 'geoqMap' },
          { title: '天地图地形', name: 'tianDiTuTerrainMap' },
        ]
      }
    },
    watch: {
      mark(val) {
        if (!this.mapReady) {
          return
        }
        if (!this.dragable) {
          if (val.lat && val.lng && (val.lat != 0 || val.lng != 0))
            this.$refs.map.setCenterLatLng(val.lat, val.lng, null, true)
        }
        this.$refs.map.addMark('mark', {
          position: {
            lng: val.lng,
            lat: val.lat,
          }
        })
      }
    },
    methods: {
      getMapConfig() {
        return {
          layer: '高德地图',
          ...this.mapConfig
        }
      },
      onMapReady(aMap) {
        console.log('onMapReady', this.mark)
        if (this.mark && this.mark.lat && this.mark.lng && (this.mark.lat != 0 || this.mark.lng != 0)) {
          this.loadMark()
        }
        if (this.geojsonUrl) {
          this.loadGeoJson()
        }
        this.mapReady = true
      },
      loadMark() {
        this.$refs.map.setCenterLatLng(this.mark.lat, this.mark.lng, null, true)
        this.$refs.map.addMark('mark', {
          position: {
            lng: this.mark.lng,
            lat: this.mark.lat,
          }
        })
      },
      loadGeoJson() {
        console.log(this.geojsonUrl)
        this.$axios.get(this.geojsonUrl).then((res) => {
          this.$refs.map.newGeoJSON(res.data, 'geojson')
          this.$refs.map.lookAtLayer('geojson', true)
          console.log(res)
        })
      }
    }
  }
</script>
<style lang="css" scoped>
</style>
