<template>
  <div class="HotspotImageView" :style="{'background-color':bgcolor,'height':height+'px','width':width+'px'}">
    <div>
      <div class="imgicon" :style="{'background-image':'url(' + imageUrl + ')','height':height+'px','width':width+'px'}">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HotspotImageView',
    props: {
      imageUrl: String,
      bgcolor: { type: String, default: 'transparent' },
      width: {
        type: Number,
        default: 50
      },
      height: {
        type: Number,
        default: 50
      },
    },
    data: function () {
      return {
      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {

    },
    computed: {
    },
    watch: {

    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .HotspotImageView {
    display: inline-block;
    position: relative;
  }

    .HotspotImageView .imgicon {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
</style>
