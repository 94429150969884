import Vue from 'vue'
import { createOidcAuth, SignInType, LogLevel } from 'vue-oidc-client'

const loco = window.location
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`

var mainOidc = createOidcAuth(
    'main',
    SignInType.Window,
    appRootUrl,
    {
        authority: window.global.AUTHORITY,
        client_id: window.global.CLIENT_ID, // 'implicit.shortlived',
        redirect_uri: `${appRootUrl}signin-oidc`,
        post_logout_redirect_uri: appRootUrl,
        silent_redirect_uri: `${appRootUrl}silent-refresh`,
        response_type: 'id_token token',
        scope: 'openid profile roles email api1 offline_access',
        accessTokenExpiringNotificationTime: 60,
        automaticSilentRenew: true,
        filterProtocolClaims: true,
        loadUserInfo: true,
        // test use
        //prompt: 'login',
        //login_hint: 'bob'
    },
    console,
    LogLevel.Debug
)
Vue.prototype.$oidc = mainOidc
export default mainOidc
