<template>
  <div class="card card-widget widget-user-2 LibImageCard">
    <!-- Add the bg color to the header using any of the bg-* classes -->
    <div class="widget-user-header bg-gray" style="height:0;padding-bottom:50%" v-bind:style="resizeimg(item.url)" @click="show">
      <!-- /.widget-user-image -->
      <h4 class="">{{item.name}}</h4>
      <h5 class="">{{item.addTime}}</h5>
      <h6 class="widget-info">
        <span class="d-inline-block" v-if="!(item.width==0||item.height==0)">
          <i class="fas fa-ruler-combined"></i>
          <span>{{item.width}}x{{item.height}}</span>
        </span>
        <span class="d-inline-block">
          <i class="fas fa-hdd"></i>
          <span>{{formatBytes(item.size)}}</span>
        </span>

      </h6>
      <slot name="addon" />
    </div>
    <div class="car>d-footer p-0">
      <slot>

      </slot>
    </div>
    <viewer :options="options" :images="[item.url]"
            @inited="inited"
            class="viewer d-none" ref="viewer">
      <template slot-scope="scope">
        <img v-for="src in scope.images" v-lazy="src" :key="src" :alt="item.name">
      </template>
    </viewer>
  </div>
</template>

<script>
  import Animation from './Animation'
  import formatBytes from "../libs/formatBytes.js"

  export default {
    name: 'LibImageCard',
    props: {
      item: {
        type: Object,
        default: {},
      },
    },
    data: function () {
      return {
        options: {
          inline: false,
          button: true,
          navbar: false,
          title: true,
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 1,
            reset: 1,
            prev: false,
            play: false,
            next: false,
            rotateLeft: 1,
            rotateRight: 1,
            flipHorizontal: 1,
            flipVertical: 1,
          },
          tooltip: true,
          movable: true,
          zoomable: true,
          rotatable: true,
          scalable: true,
          transition: true,
          fullscreen: true,
          keyboard: true,
          url: 'data-src'
        },
      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
      inited(viewer) {
        this.$viewer = viewer
      },
      show() {
        this.$viewer.show()
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'contain',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
        }
      },
      formatBytes,
    },
    computed: {
    },
    watch: {

    },

    components: {
      Animation,
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .LibImageCard .widget-user-header h4 {
    color: #fff;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
    font-size: 0.8rem;
  }

  .LibImageCard .widget-user-header h5 {
    color: #fff;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
    font-size: 0.8rem;
  }

  .LibImageCard .widget-user-header h6 {
    color: #fff;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
    font-size: .7rem;
  }

  .LibImageCard .widget-user-header {
    position: relative;
  }

    .LibImageCard .widget-user-header .widget-info {
      position: absolute;
      bottom: 0;
    }

      .LibImageCard .widget-user-header .widget-info span {
        padding: 0 0.2rem 0 0;
      }
</style>
