<template>
  <div class="card card-widget widget-user-2 LibVideoCard">
    <!-- Add the bg color to the header using any of the bg-* classes -->
    <div class="widget-user-header bg-gray" style="height:0;padding-bottom:50%" v-bind:style="resizeimg(item.url)" @click="preView">
      <!-- /.widget-user-image -->
      <h4 class="">{{item.name}}</h4>
      <h5 class="">{{item.addTime}}</h5>
      <h6 class="widget-info">
        <span class="d-inline-block" v-if="!(item.width==0||item.height==0)">
          <i class="fas fa-ruler-combined"></i>
          <span>{{item.width}}x{{item.height}}</span>
        </span>
        <span class="d-inline-block" v-if="item.duration&&item.duration!='00:00:00'">
          <i class="fas fa-clock"></i>
          <span>{{item.duration.substr(0,11)}}</span>
        </span>
        <span class="d-inline-block">
          <i class="fas fa-hdd"></i>
          <span>{{formatBytes(item.size)}}</span>
        </span>
      </h6>
      <slot name="addon" />
    </div>
    <div class="card-footer p-0">
      <slot>

      </slot>
    </div>
    <SimpleModal ref="videoModal" :zIndex="1060" boxClass="p-0" :boxStyle="{'font-size': 0}">
      <div style="position:relative">
        <video v-if="$refs.videoModal&&$refs.videoModal.show" style="width: 100%;" :src="item.url" :poster="((item||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" autoplay controls></video>
        <div style="cursor:pointer;font-size:16px;position:absolute;top:0;right:5px;" @click="$refs.videoModal.show=false">
          <div class="text-shadow" style="padding:5px">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </SimpleModal>
  </div>
</template>

<script>

  import formatBytes from "../libs/formatBytes.js"
  import SimpleModal from './SimpleModal'
  export default {
    name: 'LibVideoCard',
    components: {
      SimpleModal
    },
    props: {
      item: {
        type: Object,
        default: {},
      },
    },
    data: function () {
      return {

      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      preView() {
        this.$refs.videoModal.show = true
        this.$forceUpdate()
      },
      formatBytes
    },
    computed: {
    },
    watch: {

    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .LibVideoCard .widget-user-header h4 {
    color: #fff;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
    font-size: .8rem;
  }

  .LibVideoCard .widget-user-header h5 {
    color: #fff;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
    font-size: .8rem;
  }

  .LibVideoCard .widget-user-header h6 {
    color: #fff;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
    font-size: .7rem;
  }

  .LibVideoCard .widget-user-header {
    position: relative;
  }

    .LibVideoCard .widget-user-header .widget-info {
      position: absolute;
      bottom: 0;
    }

      .LibVideoCard .widget-user-header .widget-info span {
        padding: 0 0.2rem 0 0;
      }
</style>
