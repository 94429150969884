<template>
  <div class="EditorFileList">
    <AutoFloat ref="AutoFloat" :followEl="followEl" :bottom="bottom" :enable="enable">
      <ul class="nav nav-tabs bg-white" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="images-tab" data-toggle="tab" href="#images" role="tab" aria-controls="images" aria-selected="true" @click="focus">图片</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="attachments-tab" data-toggle="tab" href="#attachments" role="tab" aria-controls="attachments" aria-selected="false" @click="focus">附件</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="images" role="tabpanel" aria-labelledby="images-tab">
          <div v-if="($refs.AutoFloat||{}).float" style="white-space:nowrap;overflow-x:auto;line-height:0;background-color:#fff">
            <div v-for="(i,index) in images" class="imgitem" @click="editor.imageInsert(i.url.replace('/0/0/0/0/', '/800/0/0/0/'))" :style="imageGetImageStyle(i,index)">
              <div class="text-truncate text-shadow top" :title="i.name">
                {{i.name}}
              </div>
              <div class="text-truncate text-shadow bottom">
                {{formatBytes(i.size,0)}}<br />
                {{i.width}}x{{i.height}}
              </div>
            </div>
          </div>
          <div v-else style="line-height:0;">
            <draggable v-model="images"
                       v-bind="dragOptions"
                       @start="drag = true"
                       @end="drag = false">
              <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div v-for="(i,index) in images" :key="i.guid" class="imgitem" @click="editor.imageInsert(i.url.replace('/0/0/0/0/', '/800/0/0/0/'))" style="cursor:move;" :style="imageGetImageStyle(i,index)">
                  <div class="text-truncate text-shadow top" :title="i.name">
                    {{i.name}}
                  </div>
                  <div class="text-truncate text-shadow bottom">
                    {{formatBytes(i.size,0)}}<br />
                    {{i.width}}x{{i.height}}
                  </div>
                  <div style="position:absolute;bottom:0;right:0;">
                    <div class="text-shadow-alt" @click="show(index);stopPropagation($event)" style="display:inline-block;padding:2px;cursor:pointer" title="预览">
                      <i class="fas fa-search-plus"></i>
                    </div>
                    <div class="text-shadow-alt" @click="delFile(images,i);stopPropagation($event)" style="display:inline-block; padding:2px;cursor:pointer" title="删除">
                      <i class="fas fa-trash-alt"></i>
                    </div>
                  </div>
                </div>
              </transition-group>
              <template slot="footer">
                <EditorUpload ref="EditorImgUpload"
                              action="/Api/Admin/Files/Upload"
                              :multiple="true"
                              :maxSize="2"
                              :maxWidth="1200"
                              :maxHeight="2800"
                              :compress="true"
                              :isPano="false"
                              :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                              :partSizeMB="10"
                              :surlheight="100"
                              :surlwidth="200"
                              :maxUpper="3"
                              :onUploadFinish="imagesUploadFinish">
                  <template v-slot:UploadBtn="UploadBtn">
                    <div class="imgUpLoadItem" :class="{onover:UploadBtn.dragover}">
                      点击或拖动到此处上传
                    </div>
                  </template>
                  <template v-slot:fileListItem="item">
                    <div class="imgitem" :style="urlGetImageStyle(item.surl)">
                      <div class="text-truncate text-shadow bottom">
                        {{formatBytes(item.size,0)}}<br />
                        {{item.status}} {{item.status=='正在上传'?(item.percent||0)+'%':''}}
                      </div>
                      <div class="text-truncate text-shadow top">
                        {{item.file.name}}
                      </div>
                      <div style="position:absolute;top:0;bottom:0;right:0;left:0%;background-color:#0006" :style="{left:(item.percent||0)+'%'}"></div>
                      <div style="position:absolute;bottom:0;right:0;">
                        <div class="text-shadow-alt" @click="$refs.EditorImgUpload.removeFile(item.index)" style="padding:2px;cursor:pointer" title="删除">
                          <i class="fas fa-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:readingFile>
                    <div class="imgitem" style="padding: 34px;">
                      <i class="fas fa-sync fa-spin fa-2x"></i>
                    </div>
                  </template>
                </EditorUpload>
              </template>
            </draggable>
          </div>
        </div>
        <div class="tab-pane fade" id="attachments" role="tabpanel" aria-labelledby="attachments-tab">
          <div v-if="($refs.AutoFloat||{}).float" style="white-space:nowrap;overflow-x:auto;line-height:0;background-color:#fff">
            <div v-for="(i,index) in attachments" class="fileitem" @click="editor.linkInsert(i.url.replace('/0/0/0/0/', '/0/0/0/1/'))">
              <div class="text-truncate text-shadow top" :title="i.name">
                {{i.name}}
              </div>
              <div class="text-truncate text-shadow bottom">
                {{formatBytes(i.size,0)}}
              </div>
            </div>
          </div>
          <div v-else style="line-height:0;">
            <draggable v-model="attachments"
                       v-bind="dragOptions"
                       @start="drag = true"
                       @end="drag = false">
              <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div v-for="(i,index) in attachments" :key="i.guid" class="fileitem" @click="editor.linkInsert(i.url.replace('/0/0/0/0/', '/0/0/0/1/'),i.name)" style="cursor:move;">
                  <div class="text-truncate text-shadow top" :title="i.name">
                    {{i.name}}
                  </div>
                  <div class="text-truncate text-shadow bottom">
                    {{formatBytes(i.size,0)}}
                  </div>
                  <div style="position:absolute;bottom:0;right:0;">
                    <a class="text-shadow-alt" :href="i.url" target="_blank" @click="stopPropagation($event)" style="padding:2px;cursor:pointer" title="预览">
                      <i class="fas fa-download"></i>
                    </a>
                    <div class="text-shadow-alt" @click="delFile(attachments,i);stopPropagation($event)" style="display:inline-block; padding:2px;cursor:pointer" title="删除">
                      <i class="fas fa-trash-alt"></i>
                    </div>
                  </div>
                </div>
              </transition-group>
              <template slot="footer">
                <EditorUpload ref="EditorFileUpload"
                              action="/Api/Admin/Files/Upload"
                              :multiple="true"
                              :maxSize="10"
                              :isPano="false"
                              :allowExtensions="/(.zip|.7z|.rar|.doc|.docx|.xls|.xlsx|.ppt|.pptx)$/i"
                              :partSizeMB="10"
                              :maxUpper="3"
                              :onUploadFinish="attachmentsUploadFinish">
                  <template v-slot:UploadBtn="UploadBtn">
                    <div class="fileUpLoadItem" :class="{onover:UploadBtn.dragover}">
                      点击或拖动到此处上传
                    </div>
                  </template>
                  <template v-slot:fileListItem="item">
                    <div class="fileitem">
                      <div class="text-truncate text-shadow bottom">
                        {{formatBytes(item.size,0)}}<br />
                        {{item.status}} {{item.status=='正在上传'?(item.percent||0)+'%':''}}
                      </div>
                      <div class="text-truncate text-shadow top">
                        {{item.file.name}}
                      </div>
                      <div style="position:absolute;top:0;bottom:0;right:0;left:0%;background-color:#0006" :style="{left:(item.percent||0)+'%'}"></div>
                      <div style="position:absolute;bottom:0;right:0;">
                        <div class="text-shadow-alt" @click="$refs.EditorFileUpload.removeFile(item.index)" style="padding:2px;cursor:pointer" title="删除">
                          <i class="fas fa-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:readingFile>
                    <div class="fileitem" style="padding: 8px 83px;">
                      <i class="fas fa-sync fa-spin fa-2x"></i>
                    </div>
                  </template>
                </EditorUpload>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </AutoFloat>
    <viewer :options="options" :images="imgViewList"
            @inited="inited"
            class="viewer d-none" ref="viewer">
      <template slot-scope="scope">
        <img v-for="(src,index) in scope.images" :src="src" :key="src">
      </template>
    </viewer>

  </div>
</template>

<script>
  import formatBytes from "../libs/formatBytes.js"
  import AutoFloat from './AutoFloat'
  import EditorUpload from './EditorUpload'
  import draggable from 'vuedraggable'
  export default {
    components: {
      AutoFloat,
      EditorUpload,
      draggable,
    },
    name: 'EditorFileList',
    props: {
      editor: Object,
      followEl: [String, Object],
      images: Array,
      attachments: Array,
      bottom: {
        type: Number,
        default: 0
      },
      //onImagesChange: Function,
    },
    data() {
      return {
        drag: false,
        updateing: false,
        item: null,
        options: {
          inline: false,
          button: true,
          navbar: true,
          title: true,
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 1,
            reset: 1,
            prev: true,
            play: false,
            next: true,
            rotateLeft: 1,
            rotateRight: 1,
            flipHorizontal: 1,
            flipVertical: 1,
          },
          tooltip: true,
          movable: true,
          zoomable: true,
          rotatable: true,
          scalable: true,
          transition: true,
          fullscreen: true,
          keyboard: true,
          url: 'data-src'
        },
        enable: false,
      }
    },
    methods: {
      focus() {
        setTimeout(this.editor.setFocus, 1)
      },
      inited(viewer) {
        this.$viewer = viewer
      },
      show(index) {
        this.$viewer.show()
        this.$viewer.view(index)
      },
      stopPropagation(e) {
        console.log('s')
        e.stopPropagation()
      },
      imagesUploadFinish(result) {
        this.images.splice(this.images.length, 0, result)
        console.log(result)
      },
      attachmentsUploadFinish(result) {
        this.attachments.splice(this.attachments.length, 0, result)
        console.log(result)
      },
      imageGetImageStyle(item, index) {
        if (this.imageOnDrag === index.toString()) {
          return {
            'background-image': `url(${item.url.replace('/0/0/0/0/', '/200/0/0/0/')})`,
            width: 0 + 'px',
          }
        }
        return { 'background-image': `url(${item.url.replace('/0/0/0/0/', '/200/0/0/0/')})` }
      },
      urlGetImageStyle(url) {
        return { 'background-image': `url(${url.replace('/0/0/0/0/', '/200/0/0/0/')})` }
      },
      formatBytes: formatBytes,
      GetIndexByGuid(array, guid) {
        for (var i in array) {
          var item = array[i]
          if (item.guid == guid) {
            return i
          }
        }
      },
      delFile(array, item) {
        var index = this.GetIndexByGuid(array, item.guid)
        array.splice(index, 1)
      },
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      },
      imgViewList() {
        var l = []
        for (var i in this.images) {
          l.push(this.images[i].url)
        }
        return l
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .EditorFileList {
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .imgitem {
    display: inline-block;
    width: 100px;
    height: 100px;
    vertical-align: top;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    border: 1px dashed #9a9a9a;
    margin: 1px;
    cursor: pointer;
  }

    .imgitem .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px 2px;
      line-height: 1.5;
      font-size: 12px;
    }

    .imgitem .top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 5px 2px;
      line-height: 1.5;
      font-size: 12px;
    }

  .imgUpLoadItem {
    width: 100px;
    height: 100px;
    border: dashed 1px #9a9a9a;
    vertical-align: top;
    padding: 25px 9px;
    line-height: 1.5;
    margin: 1px;
  }

    .imgUpLoadItem.onover {
      background-color: lawngreen;
    }

  .fileitem {
    display: inline-block;
    width: 200px;
    height: 50px;
    vertical-align: top;
    position: relative;
    border: 1px dashed #9a9a9a;
    margin: 1px;
    cursor: pointer;
  }

    .fileitem .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px 2px;
      line-height: 1.5;
      font-size: 12px;
    }

    .fileitem .top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 5px 2px;
      line-height: 1.5;
      font-size: 12px;
    }

  .fileUpLoadItem {
    width: 200px;
    height: 50px;
    border: dashed 1px #9a9a9a;
    vertical-align: top;
    padding: 12px 18px;
    line-height: 1.5;
    margin: 1px;
  }

    .fileUpLoadItem.onover {
      background-color: lawngreen;
    }
</style>
