<template>
    <div class="AutoFloat" :style="style">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'AutoFloat',
        props: {
            enable: {
                type: Boolean,
                default: true,
            },
            type: {
                type: String,
                default: 'bottom'
            },
            bottom: {
                type: Number,
                default: 0
            },
            followEl: [String, Object],
        },
        data: function () {
            return {
                float: null,
                style: {},
                width: null,
            }
        },
        created() {
        },
        mounted() {
            this.onscroll()
            window.addEventListener('scroll', this.onscroll)
        },
        destroyed() {
            window.removeEventListener('scroll', this.onscroll)
        },
        methods: {
            getel() {
                if (typeof this.followEl == "object") {
                    if (this.followEl.$el) {
                        return this.followEl.$el
                    }
                    return this.followEl
                } else if (typeof this.followEl == "string") {
                    return document.getElementById(this.followEl)
                } else {
                    return null;
                }
            },
            getstyle() {
                if (this.enable && this.float && this.type == 'bottom') {
                    //this.style = { transform: `translateY(${-this.float}px)` }
                    this.style = {
                        position: 'fixed',
                        bottom: this.bottom + 'px',
                        width: this.width + 'px'
                    }
                    return this.style
                }
                this.style = {}
                return this.style
            },
            onscroll() {
                //setTimeout(() => {
                if (this.type == 'bottom') {
                    var el = this.getel()
                    if (!el) {
                        return;
                    }
                    var thisbottom = this.$el.offsetTop + this.$el.offsetHeight + 60
                    var elbottom = el.offsetTop + el.offsetHeight + 60
                    var screenBottom = window.scrollY + window.innerHeight - this.bottom
                    //var elBottom = el.offsetTop + el.clientHeight
                    if (screenBottom <= elbottom) {
                        this.float = thisbottom - screenBottom
                        //console.log({ el })
                        this.width = el.offsetWidth
                    } else {
                        this.float = null
                        this.width = null
                    }
                    this.getstyle()
                }
                //}, 10)
            },
        },
        computed: {
        },
        watch: {
            enable(val, oldval) {
                this.onscroll()
            }
        },
        components: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .AutoFloat {
    }
</style>
