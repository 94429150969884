<template>
  <div>
    <CollapedBox ref="mapbox" maximizeIcon>
      <template slot="header">
        地图选点
      </template>
      <template v-if="showMap">
        <MapSelectedPoint :citycode="citycode" @submit="changeMarker" :markerPoint="{lat: position[1],lng:position[0]}" :mapConfig="{ zoom: 16,}">
        </MapSelectedPoint>
      </template>
      <template slot="footer">
      </template>
    </CollapedBox>
  </div>
</template>

<script>
  import CollapedBox from '../CollapedBox'
  import MapSelectedPoint from './MapSelectedPoint'

  export default {
    components: {
      CollapedBox,
      MapSelectedPoint
    },
    name: 'mapbox',
    props: {
      position: Array,
      onChange: Function,
      citycode: Number,
    },
    data: function () {
      return {
        showMap: false,
      }
    },
    created() {
      var _this = this
      //$('#mapbox').on('expanded.boxwidget', function () {
      //  this.loadAmap().then(function () {
      //    _this.initMap(0, 0)
      //  })

      //});
      //$('#mapbox').on('collapsed.boxwidget', function () {
      //  console.log('close')
      //})

    },
    mounted() {
      $(this.$refs.mapbox.$el).on('maximized.lte.cardwidget', () => {
        this.showMap = true
      })
      $(this.$refs.mapbox.$el).on('minimized.lte.cardwidget', () => {
        this.showMap = false
      })

    },
    methods: {
      changeMarker(p) {
        if (this.onChange) this.onChange(p.lng, p.lat)
        console.log('changeMarker')
        this.$refs.mapbox.toggleMaximize()
      },
    },
    computed: {
    },
    watch: {

    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #mapbox {
    position: relative;
    height: calc(100% - 50px);
  }

    #mapbox #mapcon {
      /*min-height: 300px;*/
      height: 100%;
    }
</style>
